import { useContext } from "react";
import { StatsigContext } from "../app-initializer";

const useStatsigInitialization = () => {
  const context = useContext(StatsigContext);
  if (context === undefined) {
    throw new Error(
      "useStastigInitialization must be used within StatsigContext.Provider"
    );
  }
  return context;
};

export default useStatsigInitialization;
