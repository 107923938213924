import { Trans } from "react-i18next";
import { translate as t } from "../../../helpers/i18n";
import { FEATURE_GATES, useFeatureGate } from "../../../hooks/use-feature-gate";

const AfBAContent = () => {
  const isABADModeEnabled = useFeatureGate(FEATURE_GATES.ENABLE_ABAD_UPDATE);

  const contentTitleStyle = `typeface-${
    isABADModeEnabled ? "h4" : "h3"
  } text-typography-surface-high-emphasis`;

  return (
    <div className="d-flex flex-column gap-3 gap-md-4">
      <div className="afba-content">
        <div className="d-flex flex-column gap-3 bg-primary-01 p-4 me-md-4">
          <div className={contentTitleStyle}>{t("afba.content.title")}</div>
          <div className="container p-0 m-0">
            <div className="row">
              <div className="col-2 col-xl-1">{t("afba.content.to")}</div>
              <div className="col">{t("afba.content.allCustomers")}</div>
            </div>
            <div className="row">
              <div className="col-2 col-xl-1">{t("afba.content.date")}</div>
              <div className="col">{t("afba.content.asOf")}</div>
            </div>
            <div className="row">
              <div className="col-2 col-xl-1">{t("afba.content.from")}</div>
              <div className="col">{t("afba.content.sender")}</div>
            </div>
          </div>
          <div>
            <Trans
              i18nKey="afba.content.notice"
              components={{
                b: <b />,
                br: <br />,
              }}
            />
          </div>
          {/**Mobile Table */}
          <div className="d-flex flex-column gap-4 d-md-none">
            <div className="d-flex flex-column border tw-border-primary-200 border-1 border-bottom-0 p-0">
              <div className="d-flex flex-column gap-3 border-bottom tw-border-primary-200 border-1 m-0 p-3">
                <div className="typeface-body-small text-uppercase fw-bold">
                  {t("afba.content.providerServiceTitle")}
                </div>
                <div>{t("afba.content.insuranceProvider")}</div>
              </div>
              <div className="d-flex flex-column gap-3 border-bottom tw-border-primary-200 border-1 m-0 p-3">
                <div className="typeface-body-small text-uppercase fw-bold">
                  {t("afba.content.chargeRangeTitle")}
                </div>
                <div>{t("afba.content.insuranceCharge")}</div>
              </div>
            </div>
            <div className="d-flex flex-column border tw-border-primary-200 border-1 border-bottom-0 p-0">
              <div className="d-flex flex-column gap-3 border-bottom tw-border-primary-200 border-1 m-0 p-3">
                <div className="typeface-body-small text-uppercase fw-bold">
                  {t("afba.content.providerServiceTitle")}
                </div>
                <div>{t("afba.content.realtyProvider")} </div>
              </div>
              <div className="d-flex flex-column gap-3 border-bottom tw-border-primary-200 border-1 m-0 p-3">
                <div className="typeface-body-small text-uppercase fw-bold">
                  {t("afba.content.chargeRangeTitle")}
                </div>
                <div>{t("afba.content.realtyCharge")}</div>
              </div>
            </div>
          </div>
          {/**Desktop Table */}
          <div className="d-none d-md-flex flex-column border tw-border-primary-200 border-1 border-bottom-0 p-0">
            <div className="row border-bottom tw-border-primary-200 border-1 m-0">
              <div className="col typeface-body-small text-uppercase fw-bold border-end tw-border-primary-200 border-1 p-3">
                {t("afba.content.providerServiceTitle")}
              </div>
              <div className="col typeface-body-small text-uppercase fw-bold p-3">
                {t("afba.content.chargeRangeTitle")}
              </div>
            </div>
            <div className="row border-bottom tw-border-primary-200 border-1 m-0">
              <div className="col border-end tw-border-primary-200 border-1 p-3">
                {t("afba.content.insuranceProvider")}
              </div>
              <div className="col p-3">{t("afba.content.insuranceCharge")}</div>
            </div>
            <div className="row border-bottom tw-border-primary-200 border-1 m-0">
              <div className="col border-end tw-border-primary-200 border-1 p-3">
                {t("afba.content.realtyProvider")}{" "}
              </div>
              <div className="col p-3">{t("afba.content.realtyCharge")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfBAContent;
