// "Mortgage" is an indicator to know if the customer has a mortgage or not.
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Include our external dependencies.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import { AsyncStatus } from "../types";

// Shortcut(s) to our API functions
const getMortgage = dashApi.path("/mortgages").method("get").create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Mortgage Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|

export interface MortgageParams {
  email: string;
  zip_code: string;
  servicer_short_name?: string;
  servicer_loan_number?: string;
  ssn?: number;
}

interface MortgageState {
  mortgage: "FOUND" | "NOT_FOUND" | undefined;
  status: AsyncStatus;
}

const initialState: MortgageState = {
  mortgage: undefined,
  status: "idle",
};

// Load Mortgage from the API
export const loadMortgage = createAsyncThunk(
  "mortgage/loadMortgage",
  async function (mortgageParams: MortgageParams, { rejectWithValue }) {
    const response = await getMortgage(mortgageParams);

    // Reject the action if the mortgage call succeeded but a loan was not found
    const result_code = response?.data?.result_code;
    if (!result_code || result_code === "NOT_FOUND") {
      return rejectWithValue(result_code);
    }

    return result_code;
  }
);

export const mortgageSlice = createSlice({
  name: "mortgage",
  initialState,
  reducers: {
    clearMortgage(state) {
      state.mortgage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder

      // loadAccounts
      .addCase(loadMortgage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadMortgage.fulfilled, (state, action) => {
        state.status = "loaded";
        state.mortgage = action.payload;
      })
      .addCase(loadMortgage.rejected, (state) => {
        state.status = "failed";
        state.mortgage = undefined;
      });
  },
});
