import Spinner, { SpinnerProps } from "react-bootstrap/Spinner";
import { translate as t } from "../../helpers/i18n";

const SpinnerLoader = ({ ...props }: SpinnerProps) => {
  return (
    <Spinner
      className="spinner-loader"
      animation="border"
      role="status"
      {...props}
    >
      <span className="visually-hidden">
        {t("global.accessibility.loading")}
      </span>
    </Spinner>
  );
};

export default SpinnerLoader;
