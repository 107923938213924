import { createContext, ReactNode, useContext, useEffect } from "react";
import { useIsUserAuthenticated } from "../hooks/use-is-user-authenticated";
import { pages } from "../helpers/pages";
import { FEATURE_GATES, useFeatureGate } from "../hooks/use-feature-gate";
import { useLocation } from "react-router-dom";

export enum Theme {
  PURPLE_RAIN = "purple-rain",
  LAKEVIEW_BLUE = "lakeview-blue",
}

type ThemeContextType = {
  theme: Theme;
};

export type ThemeType = (typeof Theme)[keyof typeof Theme];

const ThemeContext = createContext<ThemeContextType>({
  theme: Theme.PURPLE_RAIN,
});

interface Props {
  children: ReactNode;
}

const LAKEVIEW_BLUE_PAGES = [
  pages.frequentlyAskedQuestions,
  pages.termsConditions,
  pages.termsConditionsDashboard,
  pages.termsConditionsDigital,
  pages.resourceCenter,
  pages.adaAccessibility,
];

const SESSION_STORAGE_GATE_ENABLED =
  sessionStorage.getItem(FEATURE_GATES.ENABLE_LAKEVIEW_UNAUTH_UI_REFRESH) ===
  "true";

const ThemeProvider = ({ children }: Props) => {
  const isUserAuthenticated = useIsUserAuthenticated();
  const isLakeviewUnauthEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_LAKEVIEW_UNAUTH_UI_REFRESH
  );
  const location = useLocation();
  const currentPath = location.pathname;
  const isIndexPage = currentPath === pages.index;

  const isLakeviewBluePage =
    isIndexPage ||
    LAKEVIEW_BLUE_PAGES.some((route) => currentPath.startsWith(route));

  const isThemeFeatureGateEnabled =
    SESSION_STORAGE_GATE_ENABLED || isLakeviewUnauthEnabled;

  const shouldUseLakeviewBlueTheme =
    isThemeFeatureGateEnabled && isLakeviewBluePage && !isUserAuthenticated;

  const theme = shouldUseLakeviewBlueTheme
    ? Theme.LAKEVIEW_BLUE
    : Theme.PURPLE_RAIN;

  useEffect(() => {
    if (isLakeviewUnauthEnabled) {
      sessionStorage.setItem(
        FEATURE_GATES.ENABLE_LAKEVIEW_UNAUTH_UI_REFRESH,
        "true"
      );
    }
  }, [isLakeviewUnauthEnabled]);

  useEffect(() => {
    document.body.setAttribute("data-bs-theme", theme);

    switch (theme) {
      case Theme.LAKEVIEW_BLUE: {
        document.body.classList.add("tw-lakeview-blue");
        document.body.classList.remove("tw-purple-rain");
        break;
      }
      case Theme.PURPLE_RAIN: {
        document.body.classList.add("tw-purple-rain");
        document.body.classList.remove("tw-lakeview-blue");
        break;
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
export const useThemeContext = () => {
  const context = useContext(ThemeContext);

  const isPurpleRainThemeActive = context.theme === Theme.PURPLE_RAIN;
  const isLakeviewBlueThemeActive = context.theme === Theme.LAKEVIEW_BLUE;

  return {
    ...context,
    isPurpleRainThemeActive,
    isLakeviewBlueThemeActive,
  };
};
