import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { Statsig } from "statsig-react";
import useStatsigInitialization from "./use-statsig-initialization";
import { HELOAN_INELIGIBLE_STATES } from "../helpers/constants";
import { useIsUserAuthenticated } from "./use-is-user-authenticated";

/**
 * List of feature gates used in the project
 */
export const FEATURE_GATES = {
  TAX_SEASON_NOTIFICATION: "1098-tax-season-notification", // permanent
  ENABLE_NEXT_LHIS_CTA: "enable_next_lhis_cta",
  CASH_OUT_REFINANCE_LOAN_QUOTE: "cash_out_refi_loan_quote",
  ENABLE_MULTIPLE_LOAN_MOCKS: "enable_multiple_loan_mocks",
  ENABLE_MULTIPLE_LOANS: "enable_multiple_loans",
  ENABLE_NEXT_STEPS_MODULE: "enable_next_steps_module",
  BOLT_INSURANCE_AD: "bolt-insurance-ad",
  ENABLE_DIGITAL_ACCOUNT_CREATION: "enable_digital_account_creation",
  ENABLE_TAVANT_CRUISE_NEXT_STEPS: "enable_tavant_cruise_next_steps",
  ENABLE_SUBSERVICER_REDIRECT_TARGET_PARAMS:
    "enable_subservicer_redirect_target_params",
  ENABLE_KUKUN_COST_CALCULATOR: "enable_kukun_cost_calculator",
  ENABLE_TAVANT_DIGITAL_HELOAN: "enable_tavant_digital_heloan",
  ENABLE_OFFERS: "enable_offers",
  ENABLE_LAKEVIEW_UNAUTH_UI_REFRESH: "enable_lakeview_unauth_ui_refresh",
  ENABLE_FLOATING_BUTTON_MORTGAGE_PAY: "enable_floating_button_mortgage_pay",
  ENABLE_RATE_TERM_OFFERS: "enable_rate_term_offers",
  ENABLE_OFFER_RIBBON: "enable_offer_ribbon",
  ENABLE_OFFER_REDESIGN: "enable_offer_redesign",
  ENABLE_LAKEVIEW_HELOAN_LEAD_LINKS: "enable_lakeview_heloan_lead_links",
  ENABLE_OFFER_POPUP: "enable_offer_popup",
  ENABLE_ABAD_UPDATE: "enable_abad_update",
  ENABLE_GOALS_MODULE_REDESIGN: "enable_goals_module_redesign",
  ENABLE_GOALS_POPUP: "enable_goals_popup",
  ENABLE_OFFER_LAKEVIEW_LOGO: "enable_offer_lakeview_logo",
  ENABLE_VERIFY_EMAIL_UPDATES: "enable_verify_email_updates",
};

export type FeatureGateConfig = Partial<{
  [key in keyof typeof FEATURE_GATES]: boolean;
}>;

type GateConditions = {
  [key: string]: {
    requiresLoadedStatus?: boolean;
  };
};
const GATE_CONDITIONS: GateConditions = {
  [FEATURE_GATES.ENABLE_TAVANT_DIGITAL_HELOAN]: {
    requiresLoadedStatus: true,
  },
};

const isStateIneligible = (state: string | undefined | null) =>
  state ? HELOAN_INELIGIBLE_STATES.includes(state) : false;

const useGateCriteria = (gateName: string) => {
  const { user, status } = useSelector((state: RootState) => state.user);
  const state = user?.address?.state;
  const isUserAuthenticated = useIsUserAuthenticated();

  const gateConditions = GATE_CONDITIONS[gateName];
  if (
    gateConditions?.requiresLoadedStatus &&
    (status === "loading" || status === "failed")
  ) {
    return false;
  }

  const gates = Object.keys(FEATURE_GATES).reduce<FeatureGateConfig>(
    (acc, key) => ({
      ...acc,
      [FEATURE_GATES[key as keyof typeof FEATURE_GATES]]: true,
    }),
    {} as FeatureGateConfig
  );

  const gateCriteria = {
    ...gates,
    [FEATURE_GATES.ENABLE_TAVANT_DIGITAL_HELOAN]: isUserAuthenticated
      ? !isStateIneligible(state)
      : true,
  };

  return gateCriteria[gateName] ?? true;
};

/**
 * Wrapper around the useGate functionality
 * Won't return the value of the feature gate until the SDK is completely initialized
 */
export const useFeatureGate = (gateName: string): boolean => {
  const { isInitialized } = useStatsigInitialization();
  const doesMeetGateCriteria = useGateCriteria(gateName);

  if (!isInitialized || !doesMeetGateCriteria) return false;

  const value = Statsig.checkGate(gateName);

  return value;
};
