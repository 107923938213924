// Include our external dependencies.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import { Account, AsyncStatus } from "../types";

// Shortcut(s) to our API functions
const getAccounts = dashApi.path("/account").method("get").create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Account Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
interface AccountsState {
  accounts: Array<Account> | undefined;
  status: AsyncStatus;
  initialized: boolean;
}
const initialState: AccountsState = {
  accounts: undefined,
  status: "idle",
  initialized: false,
};

// Load Accounts from the API
export const loadAccounts = createAsyncThunk(
  "accounts/loadAccounts",
  async function () {
    const response = await getAccounts({});
    return response.data.data;
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    clearAccount(state) {
      state.accounts = undefined;
    },
  },
  extraReducers: (builder) => {
    builder

      // loadAccounts
      .addCase(loadAccounts.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(loadAccounts.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        state.accounts = action.payload;
      })
      .addCase(loadAccounts.rejected, (state) => {
        // if already initialized and the endpoint fails, keep using the stored data
        if (!state.initialized) {
          state.status = "failed";
          state.accounts = undefined;
        }
      });
  },
});
