import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { useFeatureGate, FEATURE_GATES } from "./use-feature-gate";
import MOCKED_ACCOUNTS from "../modules/home-value/mortgage/mocked-accounts";
import { Account } from "../types";

/**
 * This custom hook conditionally returns loan accounts based on the
 * 'ENABLE_MULTIPLE_LOAN_MOCKS' feature gate. When the feature is enabled,
 * it returns an object containing the first account and an additional
 * array of mocked loan accounts. Otherwise, it only returns the first account.
 *
 * This hook can be removed in the future if the feature is permanently
 * enabled and the loan accounts are directly retrieved from the state.
 */
export const useMultipleLoanAccounts = () => {
  const {
    account: { accounts },
  } = useSelector((state: RootState) => state);
  const isMultiLoanMocksEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_MULTIPLE_LOAN_MOCKS
  );
  const validAccounts = accounts || [];

  if (isMultiLoanMocksEnabled) {
    return [...validAccounts, ...MOCKED_ACCOUNTS] as Account[];
  }

  return validAccounts;
};
