import { SubservicerKey, User } from "../types";
import { translate as t } from "./i18n";

interface SubservicerDescription {
  name: string;
  shortName: string;
  mortgageAssistance: string;
}

const SUBSERVICER_MAPPING: Record<SubservicerKey, SubservicerDescription> = {
  FLAGSTAR: {
    name: "Flagstar Bank",
    shortName: "Flagstar",
    mortgageAssistance:
      "https://www.flagstar.com/personal/mortgage-information-center/facing-financial-hardships.html",
  },
  MR_COOPER: {
    name: "Mr. Cooper",
    shortName: "MRC",
    mortgageAssistance:
      "https://www.mylakeviewloan.com/mortgage_assistance/overview",
  },
  LOANCARE: {
    name: "LoanCare",
    shortName: "LoanCare",
    mortgageAssistance:
      "https://lakeviewloanservicing.myloancare.com/mortgage-assistance/financial-assistance",
  },
  MT: {
    name: "M&T Bank",
    shortName: "M&T",
    mortgageAssistance:
      "https://www3.mtb.com/personal/mortgages-and-loans/mortgage/manage/paying/assistance",
  },
} as const;

export const SUBSERVICERS = Object.values(SUBSERVICER_MAPPING);

/**
 * Searches for the subservicer details given the key from the user's subservicer
 *
 * @param user
 * @returns {SubservicerDescription | undefined}
 */
export const getSubservicer = (user?: User) => {
  const subServicerKey = user?.mortgage?.subservicer;

  return subServicerKey && SUBSERVICER_MAPPING.hasOwnProperty(subServicerKey)
    ? SUBSERVICER_MAPPING[subServicerKey]
    : undefined;
};

/**
 * Returns the subservicer name
 */
export const getSubservicerName = (user?: User) => {
  const subservicer = getSubservicer(user);
  return subservicer?.name || t("home_value.generic_subservicer");
};

export const getSubservicerShortName = (user?: User) => {
  const subservicer = getSubservicer(user);
  return subservicer?.shortName;
};

/** Returns the subservicer link for mortgage assistance */
export const getMortgageAssistanceLink = (user?: User) => {
  const subservicer = getSubservicer(user);
  return subservicer?.mortgageAssistance;
};

export const isLoancare = (user?: User) =>
  user?.mortgage?.subservicer === "LOANCARE";

export const isMrCooper = (user?: User) =>
  user?.mortgage?.subservicer === "MR_COOPER";

export const REDIRECT_TARGET = {
  OVERVIEW: "OVERVIEW",
  PAYMENT: "PAYMENT",
  FORM_1098: "FORM_1098",
  MORTGAGE_ASSISTANCE: "MORTGAGE_ASSISTANCE",
};
