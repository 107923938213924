import Loader from "../../components/loader";
import { useEffect, useState } from "react";
import Layout from "./index";
import { useIsUserAuthenticated } from "../../hooks/use-is-user-authenticated";
import useLoginRedirect from "../../hooks/use-login-redirect";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { pages } from "../../helpers/pages";
import { useLocation } from "react-router-dom";

type Props = {
  loadingState?: boolean;
};

const PrivateLayout = ({ loadingState = false }: Props) => {
  const location = useLocation();
  const isUserAuthenticated = useIsUserAuthenticated();
  const { isFloatingButton } = useSelector((state: RootState) => state.app);

  const [isLoading, setIsLoading] = useState(!!loadingState);

  const [isFloatingButtonVisible, setIsFloatingButtonVisible] = useState(
    !!isFloatingButton && location.pathname === pages.home
  );

  useEffect(() => {
    setIsFloatingButtonVisible(
      !!isFloatingButton && location.pathname === pages.home
    );
  }, [isFloatingButton, location.pathname]);

  useEffect(() => {
    if (isUserAuthenticated) {
      setIsLoading(false);
    }
  }, [isUserAuthenticated]);

  // Auth0 Login Redirect
  useLoginRedirect();

  return isLoading ? (
    <Loader />
  ) : (
    <Layout
      className={isFloatingButtonVisible ? "is-floating-button" : ""}
      displayAuthInfo
    />
  );
};

export default PrivateLayout;
