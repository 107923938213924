import { translate as t } from "../../../helpers/i18n";
import { Trans } from "react-i18next";
import { LogoutLink } from "../../../modules/layout/header/header-auth";
import { SubservicerLink } from "../../../components/subservicer-link";
import GenericBaseModal from "../modal/generic-base-modal";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";
import { Button } from "../../atoms/button";

export interface DeclinedTCConfirmationModalProps {
  show: boolean;
  onGoBack?: () => void;
  isUncompletedProfile?: boolean;
  onExitProfileSetup?: () => void;
}

const DeclinedTCConfirmationModal = ({
  show,
  onGoBack,
  isUncompletedProfile,
  onExitProfileSetup,
}: DeclinedTCConfirmationModalProps) => {
  const title = isUncompletedProfile
    ? t("declined_terms_conditions_modal_sso.title")
    : t("declined_terms_conditions_modal.title");

  const backButtonText = isUncompletedProfile
    ? t("declined_terms_conditions_modal_sso.back_button")
    : t("declined_terms_conditions_modal.back_button");

  const body = isUncompletedProfile
    ? t("declined_terms_conditions_modal_sso.body")
    : t("declined_terms_conditions_modal.body");

  return (
    <GenericBaseModal
      show={show}
      title={title}
      onCloseButtonClick={undefined}
      buttons={
        <ModalButtonsWrapper>
          {isUncompletedProfile ? (
            <Button onClick={onExitProfileSetup}>
              {t("declined_terms_conditions_modal_sso.exit_button")}
            </Button>
          ) : (
            <LogoutLink />
          )}
          <Button onClick={onGoBack}>{backButtonText}</Button>
        </ModalButtonsWrapper>
      }
    >
      <Trans
        i18nKey={body}
        components={{
          bold: <strong />,
          subservicer: (
            <SubservicerLink
              translationKey="declined_terms_conditions_modal.subservicer_name"
              module="accept-terms-conditions"
              isConfirmationModal={false}
            />
          ),
        }}
      />
    </GenericBaseModal>
  );
};

export default DeclinedTCConfirmationModal;
