import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { PAGE_META_DESCRIPTION, PAGE_TITLES } from "../helpers/constants";
import { pages } from "../helpers/pages";

interface UseSEOProps {
  children?: ReactNode;
  title?: string;
  description?: string;
  noIndex?: boolean;
  fullTitle?: string;
}

interface NestedPaths {
  [key: string]: string | NestedPaths;
}

interface FlatPaths {
  [key: string]: string;
}

type PathKeys = keyof typeof PAGE_TITLES;

const flattenPaths = (obj: NestedPaths): FlatPaths => {
  return Object.keys(obj).reduce((acc: FlatPaths, key: string) => {
    const value = obj[key];
    if (typeof value === "object") {
      // if we are in a nested object, we don't want to key the parent's prefix
      Object.assign(acc, flattenPaths(value));
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const SITE_NAME = "Lakeview";

const SEOComponent = ({
  children,
  title,
  description,
  noIndex,
  fullTitle,
}: UseSEOProps = {}) => {
  const { pathname } = useLocation();
  const isIndexPage = pathname === pages.index;

  const flatPaths = flattenPaths(pages);

  const currentPathKey: PathKeys | undefined = Object.keys(flatPaths).find(
    (key) => flatPaths[key] === pathname
  ) as PathKeys | undefined;

  const currentTitle = title ?? PAGE_TITLES[currentPathKey!];
  const currentDescription =
    description ??
    (PAGE_META_DESCRIPTION[
      currentPathKey as keyof typeof PAGE_META_DESCRIPTION
    ] ||
      PAGE_META_DESCRIPTION.default);

  return (
    <Helmet>
      <title>{fullTitle || currentTitle}</title>
      <meta name="description" content={currentDescription} />
      {noIndex && <meta name="robots" content="noindex" />}
      {isIndexPage && (
        <meta property="og:url" content={process.env.REACT_APP_DOMAIN} />
      )}
      {isIndexPage && <meta property="og:site_name" content={SITE_NAME} />}
      {isIndexPage && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "WebSite",
            name: SITE_NAME,
            url: process.env.REACT_APP_DOMAIN,
          })}
        </script>
      )}
      {children}
    </Helmet>
  );
};

export default SEOComponent;
