import SpinnerLoader from "../../../atoms/spinner-loader";
import { Stack } from "react-bootstrap";
import React from "react";

interface Props {
  loadingMessage?: string;
}

const ModalLoadingMessage = ({ loadingMessage }: Props) => {
  return (
    <Stack className="w-100" direction="horizontal" gap={2}>
      <SpinnerLoader variant="primary" size="sm" />
      {loadingMessage && (
        <p className="m-0 p-0 typeface-body-small">{loadingMessage}</p>
      )}
    </Stack>
  );
};

export default ModalLoadingMessage;
