import { useSelector } from "react-redux";
import { RootState } from "../stores";

export const usePropertyValues = () => {
  const { property: propertyState } = useSelector((state: RootState) => state);

  const { property } = propertyState;

  const isLoading = propertyState.status === "loading";
  const isIdle = propertyState.status === "idle";

  const currentPropertyValue = property?.current_value || 0;
  const originalPropertyValue = property?.purchase_value || 0;

  /**
   *  This formula shows the increase or decrease in the value of the property
   * since the time of purchase until now
   */
  const propertyValueDifferenceSincePurchase: number =
    currentPropertyValue > 0 && originalPropertyValue > 0
      ? currentPropertyValue - originalPropertyValue
      : 0;

  return {
    isLoading,
    isIdle,
    propertyValueDifferenceSincePurchase,
    currentValue: currentPropertyValue,
    purchaseValue: originalPropertyValue,
    purchaseDate: property?.purchase_date,
  };
};
