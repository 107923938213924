import LPbF from "../../atoms/lpbf";

const PrivacyPolicy = () => (
  <>
    <p className="mb-s24">
      <b>
        <LPbF /> Digital Account Privacy Policy
      </b>
    </p>
    <p className="mb-s24">
      <b>Overview</b>
    </p>
    <p className="mb-s24">
      This Privacy Policy explains how Lakeview Loan Servicing, LLC
      (“Lakeview”), and its respective affiliates, subsidiaries, and service
      providers, including Fastlane Technology Solutions, LLC (collectively,
      “we,” “us,” and “our”) collect, use, protect, and disclose nonpublic
      personal identifying information about consumers when you use “Lakeview”
      and “Fastlane” brands, including, without limitation, the <LPbF /> Digital
      Account (the “Digital Account”), and the Products or Services provided by
      us through the Lakeview website (the “Website”) and the Digital Account
      (the “Products and Services” as described in the respective Terms of Use).
      This Privacy Policy also explains any rights you may have about these
      practices.
    </p>
    <p className="mb-s24">
      By creating and/or using your <LPbF /> Digital Account, you consent to
      this Policy, which includes your consent to disclose and use information
      about you in the manner detailed in this Policy. Depending upon your
      relationship with us, you may receive other Privacy Notices providing
      additional details about our privacy practices.
    </p>
    <p className="mb-s24">
      We may update this Privacy Policy, and our Federal Privacy Notice, from
      time to time. The effective date of the Policy will be clearly displayed.
      You can always view the most recent version of this Privacy Policy and/or
      Federal Privacy Notice on our Lakeview Website. We will notify you of any
      changes as required by applicable law.
    </p>
    <p className="mb-s24">
      In the event that you need to contact us about this Privacy Policy or our
      privacy practices, please contact us at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      The effective date of this Privacy Policy is September 2024.
    </p>
    <p className="mb-s24">
      <b>Information Collection and Sharing Activities</b>
    </p>
    <p className="mb-s24">
      <b>What are the types of personal information we collect?</b> We do not
      collect personally identifiable information from visitors to our
      Website(s) unless visitors voluntarily and knowingly provide us with that
      information. We may collect personal information when you open a <LPbF />{" "}
      Digital Account or enter data into an application for Products or Services
      while using our Digital Account or Website. The following are examples of
      the types of personal information and other information we may collect
      about you:
    </p>
    <ul className="mb-s24">
      <li>
        Your name and identifying information, such as your date of birth and
        social security number;
      </li>
      <li>
        Your contact information, such as your address, email address, and
        telephone number;
      </li>
      <li>
        Financial and financial-related information, such as your income,
        employer, mortgage history and the amount of your mortgage, credit
        history; and
      </li>
      <li>
        Information about your home or property, such as your address or
        location and property value.
      </li>
    </ul>
    <p className="mb-s24">
      As part of providing a <LPbF /> Digital Account to you, we may obtain
      information from our customers and Website visitors from the following
      sources:
    </p>
    <ul className="mb-s24">
      <li>
        Information we receive from you on applications, emails, or other forms;
      </li>
      <li>Any current Lakeview loan account number(s), as applicable;</li>
      <li>Information about your transactions with Lakeview;</li>
      <li>Information we receive from a consumer-reporting agency; and</li>
      <li>
        Information that is generated electronically when you visit our Lakeview
        website or use our <LPbF /> Digital Account.
      </li>
    </ul>
    <p className="mb-s24">
      Service Providers in relationship to Lakeview Website and <LPbF /> Digital
      Account may collect general information and electronic data on our Website
      visitors and Digital Account users for security and statistical purposes.
      This information may include:
    </p>
    <ul className="mb-s24">
      <li>
        The internet address (referral site) which brought you to our Website;
      </li>
      <li>The date and time you access our Website;</li>
      <li>The name and version of your web browser or device identifier;</li>
      <li>Your geolocation;</li>
      <li>
        Technical information in connection with your use of the Lakeview
        Website or Digital Account used to connect your device to the internet
        address, browser type and version, and operating system;
      </li>
      <li>
        The pages visited on our Lakeview Website, Digital Account and other
        website interactions and/or preferences; and
      </li>
      <li>The duration of your online sessions.</li>
    </ul>
    <p className="mb-s24">
      <b>
        What are the sources from which we collect personal information about
        you?
      </b>{" "}
      We collect personal information and other information about you from a
      number of sources, including:
    </p>
    <ul className="mb-s24">
      <li>Our affiliates and our affiliates’ service providers;</li>
      <li>
        When you give us your personal information in order to receive Products,
        Services, or information, such as when you register for a Digital
        Account, or contact us by telephone, email or other means;
      </li>
      <li>
        If applicable, any personal information already collected as a result of
        a Lakeview loan already in existence;
      </li>
      <li>
        Third parties, such as credit reporting agencies, identity verification
        service providers, and home valuation companies; and
      </li>
      <li>
        Cookies, web beacons, mobile application plug-ins, or other similar
        technologies.
      </li>
    </ul>
    <p className="mb-s24">
      <b>How do we use the information we collect about you?</b> We use the
      information collected about you in a number of ways, including but not
      limited to:
    </p>
    <ul className="mb-s24">
      <li>Provide with, renew, or modify Products and Services;</li>
      <li>Provide information about online services or required notices;</li>
      <li>
        Provide you with the information and documents you request and for other
        customer service purposes;
      </li>
      <li>
        Process your requests for information or another product or service
        offered through the Lakeview Website or <LPbF /> Digital Account;
      </li>
      <li>
        Service Products or Services you obtain through the Lakeview Website or{" "}
        <LPbF /> Digital Account or that you otherwise obtain from us or one of
        our affiliates;
      </li>
      <li>
        Improve services we provide and the usefulness of the Lakeview Website
        and <LPbF /> Digital Account;
      </li>
      <li>Communicate with you;</li>
      <li>To perform research;</li>
      <li>Provide you customer support and respond to your inquiries;</li>
      <li>
        Detect, investigate and prevent potentially prohibited or illegal
        activities, such as fraud;
      </li>
      <li>Engage in risk profiling and other data analytics;</li>
      <li>
        Operate, improve, maintain, and ensure the security of the Lakeview
        Website and <LPbF /> Digital Account;
      </li>
      <li>Comply with laws and regulations;</li>
      <li>
        Exercise or defend our legal rights or the legal rights of others; and
      </li>
      <li>
        Use in connection with any other legitimate business purpose permitted
        by applicable law.
      </li>
    </ul>
    <p className="mb-s24">
      We may also anonymize, combine or aggregate any of the information we
      collect through our Website(s) and/or <LPbF /> Digital Account or
      elsewhere for any of these purposes or for analyzing usage statistics and
      trends.
    </p>
    <p className="mb-s24">
      <b>Who do we share information about you with?</b> We may share the
      personal information and other information we collect about you with:
    </p>
    <ul className="mb-s24">
      <li>Our affiliates;</li>
      <li>Service providers;</li>
      <li>Third parties who provide products and services;</li>
      <li>Credit reporting agencies;</li>
      <li>
        Other companies in connection with a merger, acquisition, or the sale of
        assets;
      </li>
      <li>
        Third parties, including governmental authorities and law enforcement,
        to protect or defend our legal rights or the legal rights of others,
        protect against fraud; and comply with applicable law, subpoena, or
        legal process;
      </li>
      <li>Third parties in an anonymized and aggregated manner; and</li>
      <li>Other third parties as permitted by applicable law.</li>
    </ul>
    <p className="mb-s24">
      <b>Cookies and Do Not Track Signals</b>
    </p>
    <p className="mb-s24">
      <b>Cookies.</b> Cookies are pieces of information stored directly on a
      computer or mobile application you are using. We or our service providers
      may place cookies, or similar files, on your device, including for
      security purposes, to facilitate navigation, and to personalize your
      experience while using the Lakeview Website and <LPbF /> Digital Account.
      Cookies allow us to collect technical and navigational information, such
      as browser type, time spent on a website or mobile application, and pages
      visited. Cookies allow us to select which advertisements or offers are
      most likely to appeal to you and display them to you. We may also use
      cookies in online advertising to track responses and may use cookies or
      other files to track your use of other websites. Cookies and other
      technologies provide Us the capability to monitor the use of the Lakeview
      Website and <LPbF /> Digital Account so we can continually improve the
      design and functionality to better serve you.
    </p>
    <p className="mb-s24">
      If you do not accept these cookies, you may experience some inconvenience
      in your use of some online options. We use cookies to display information
      more effectively to you and to gather data about the usage of the Website
      and <LPbF /> Digital Account.
    </p>
    <p className="mb-s24">
      When you click on advertisements on our Website or advertisements on
      linked third party websites, you may receive another Cookie; however, you
      do not have to accept any Cookies from third party advertisements.
    </p>
    <p className="mb-s24">
      At times, we may also use other technologies to capture customer specific
      data to help understand how customers use the Website and <LPbF /> Digital
      Account and to assist us with resolving customers’ questions regarding the
      use of the Website and <LPbF /> Digital Account.
    </p>
    <p className="mb-s24">
      <b>Do-Not-Track Signals.</b> Many web browsers possess a do-not-track
      feature that lets your browser tell websites you visit that you do not
      want to have your online activities tracked. At this time, the Lakeview
      Website and the Digital Account do not respond to browser do-not-track
      signals.
    </p>
    <p className="mb-s24">
      <b>Security.</b> To protect your personal information from unauthorized
      access, we use security and safeguarding measures that comply with federal
      and state law. These measures include but are not limited to computer and
      electronic safeguards and secured files and buildings. It may also include
      access codes for multi-factor authentication. However, you acknowledge
      that <u>no</u> security measure or modality of data transmission over the
      Internet can be guaranteed to be 100% secure. You should not expect that
      any personal information provided to us through the Lakeview Website or{" "}
      <LPbF /> Digital Account is free from the risk posed by unauthorized
      access.
    </p>
    <p className="mb-s24">
      Secure browsers, such as Google Chrome, Mozilla Firefox, Microsoft Edge,
      or Apple Safari are required to access your information as additional
      protection of your Digital Account information. We utilize Secure Socket
      Layer (SSL) and 256-bit encryption technology to protect your confidential
      information. If you are visiting the Lakeview Website or your Digital
      Account, your Internet session should be encrypted if your
      security-enabled browser is connected using the Secure Hypertext Transport
      Protocol. URL strings beginning with “HTTPS://” instead of the usual
      “HTTP://” indicate that the secure protocol is in effect. Your internet
      browser may also show an icon indicating that the Website is considered
      secure.
    </p>
    <p className="mb-s24">
      You can protect your Digital Account information by signing off your
      device when you have completed your transaction, changing your password
      frequently, and by not sharing any multi-factor authentication access
      codes with anyone. If you forget to sign off, <LPbF /> Digital Account
      will sign off for you after there has been no activity for a certain
      period of time. Please be aware that if you provide your User ID and
      Password to anyone, you are authorizing them to access your Digital
      Account. Any transactions that may take place in your Digital Account as a
      result are considered to be authorized by you.
    </p>
    <p className="mb-s24">
      <b>Third Party Links.</b> Our Lakeview Website or <LPbF /> Digital Account
      may include links to other third-party websites as a courtesy and a
      convenience to our customers. When you visit these sites, you will leave
      our Website and will be redirected to another site.
    </p>
    <p className="mb-s24">
      Lakeview makes no representation concerning and is not responsible for the
      quality, content nature, or reliability of any third party linked site.
      The inclusion of any third-party links does not imply any endorsements,
      investigation, verification or monitoring by Lakeview on any third party
      linked site. In no event shall Lakeview be responsible for your use of a
      third-party site. We suggest that you always verify the information
      obtained from third party websites before acting on this information.
      Also, please be aware that the security and privacy policies on these
      sites may be different from our policies, so please read third party
      privacy and security policies closely.
    </p>
    <p className="mb-s24">
      <b>Employee Access to Information.</b> Only those employees with a
      legitimate need to know have access to our customers’ information.
      Employees are provided with a Code of Ethics when they are hired. We have
      established training programs to educate our employees about the
      importance of customer privacy and to help ensure compliance with that
      Code. Any violation of that Code, including unauthorized access of
      customer information or sharing customer information, will subject the
      employee to disciplinary action, including immediate termination.
    </p>
    <p className="mb-s24">
      <b>Minors.</b> You must be 18 years of age or older to register as a
      Digital Account user and receive Products and Services through the
      Lakeview Website and <LPbF /> Digital Account. The Lakeview Website and{" "}
      <LPbF /> Digital Account are not directed at people under 18 years of age
      and we do not knowingly collect any information about such persons. For
      additional information regarding the Children’s Online Privacy Protection
      Act (“COPPA”), please visit the Federal Trade Commission website.
    </p>
    <p className="mb-s24">
      <b>Changing Your Information.</b> If you would like to review and request
      changes to the personally identifiable information that you have provided
      to us in your interactions with the <LPbF /> Digital Account, please reach
      out to{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      Note we are unable to completely delete <LPbF /> Digital Accounts and the
      data associated with them as there are federal and state regulations that
      may require us to retain certain data, including, but not limited to,
      personally identifiable information, Digital Account information and
      transactional history.
    </p>
    <p className="mb-s24">
      <b>Call Monitoring.</b> You consent to the recording and monitoring of any
      call for quality assurance, training, risk management, collection
      purposes, or any other legitimate purpose, that you place to us (or our
      service providers) or that we (or our service providers) place to you.
      Refer to Lakeview’s Consent to Receive Telephone Communications
      disclosure.
    </p>
    <p className="mb-s24">
      <b>Consent to Receive Email Communications.</b> You consent to receiving
      emails at any email address you provide to us, including marketing-related
      messages. You may opt out of receiving marketing emails by utilizing the
      “unsubscribe” functionality preferences included in those emails.
    </p>
    <p className="mb-s24">
      <b>State Privacy Disclosures.</b> Some states provide certain rights
      concerning your personal information unless an exception applies. These
      exceptions include information subject to federal privacy laws,
      specifically the federal Gramm-Leach-Bliley Act (“GLBA”) and Fair Credit
      Reporting Act (“FCRA”), or entities that are subject to the GLBA. In
      addition to these exceptions, state privacy laws do not consider
      information to be personal information if it cannot be reasonably linked
      to any consumer or household. The information that Lakeview or its
      affiliates collect, uses, and shares may be exempt from state privacy laws
      as it is subject to the GLBA, subject to the FCRA, it is not deemed to be
      personal information, or Lakeview is subject to the GLBA.
    </p>
    <p className="mb-s24">
      <b>Additional notice to California Residents</b>. The California Consumer
      Privacy Act (“CCPA”) is a law which applies to the State of California,
      who are not currently covered under the GLBA. California defines residency
      as: (1) every individual who is in the State for other than a temporary or
      transitory purpose, and (2) every individual who is domiciled in the State
      who is outside the State for a temporary or transitory purpose. All other
      individuals are nonresidents. Examples of domicile include paying taxable
      income, maintaining a permanent home, paying resident tuition, voting in a
      California election, or any other benefit not ordinarily extended to
      nonresidents.
    </p>
    <p className="mb-s24">
      Note that as part of <LPbF /> Digital Account experiences, as well as part
      of servicing consumer requests or consumer accounts, Lakeview may collect
      certain categories of information as noted in the “California Consumer
      Privacy Notice;” however, not all categories of information are likely to
      be collected from or about each individual consumer. The specific
      categories of information collected are dependent on the Lakeview
      service(s) provided.
    </p>
    <p className="mb-s24">
      For further information on the categories of information Lakeview
      collects, please visit:{" "}
      <a href="https://lakeview.com" target="_blank" rel="noreferrer">
        lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      <b>Questions?</b> Please contact Lakeview in writing at:
    </p>
    <p>Lakeview Loan Servicing, LLC,</p>
    <p>Attn: Compliance Department</p>
    <p>4425 Ponce De Leon Blvd., MS. 5-251</p>
    <p className="mb-s24">Coral Gables, FL 33146</p>
    <p className="mb-s24">
      with any questions about the information included in this <LPbF /> Digital
      Account Privacy Policy or questions about our Website’s services.
    </p>
    <p className="mb-s24">
      You may access our full Privacy Notice at:{" "}
      <a
        href="https://lakeviewloanservicing.fastlanefi.com/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
      >
        https://lakeviewloanservicing.fastlanefi.com/terms-and-conditions
      </a>
      .
    </p>
  </>
);
export default PrivacyPolicy;
