import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  returnNull: false,
  debug: false,
  fallbackLng: "en",
  resources: {
    en: {
      translation: en,
    },
  },
});

export const translate = i18n.t;
export const locale = i18n.language;
