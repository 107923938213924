import { VariantProps, cva, cx } from "class-variance-authority";
import { ChangeEventHandler, ReactNode } from "react";
import { Form } from "react-bootstrap";

export interface CheckboxProps {
  label: ReactNode;
  name?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  className?: string;
  isTermsData?: boolean;
}

const checkboxStyles = cva("form-check form-check-inline", {
  variants: {
    alignment: {
      center: "form-check-centered d-flex flex-wrap align-items-center p-0",
    },
    labelSize: {
      small: "label-small",
    },
  },
});

export type CheckboxStyles = VariantProps<typeof checkboxStyles>;

const Checkbox = ({
  label,
  errorMessage,
  alignment,
  className,
  labelSize,
  isTermsData = false,
  ...otherProps
}: CheckboxProps & CheckboxStyles) => {
  const checkboxLabel =
    typeof label === "string" ? label : otherProps.name || crypto.randomUUID();

  return (
    <Form.Check className={cx(checkboxStyles({ alignment }), className)}>
      <Form.Check.Input
        {...otherProps}
        id={`checkbox-${checkboxLabel}`}
        className={cx(
          "form-check-input",
          alignment,
          isTermsData && "terms-conditions"
        )}
      />
      <Form.Check.Label
        className={cx("form-check-label", checkboxStyles({ labelSize }))}
        htmlFor={`checkbox-${checkboxLabel}`}
      >
        {label}
      </Form.Check.Label>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Check>
  );
};

export default Checkbox;
