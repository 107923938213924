// Include our external dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// App Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
interface AppState {
  heroButtonsVisible?: boolean;
  isFloatingButton?: boolean;
  isContentBlockOverlay?: boolean;
}
const initialState: AppState = {
  heroButtonsVisible: true,
  isFloatingButton: false,
  isContentBlockOverlay: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updatePDPButtonsVisible(state, action: PayloadAction<boolean>) {
      state.heroButtonsVisible = action.payload;
    },
    updateUIState(state, action: PayloadAction<AppState>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { updatePDPButtonsVisible, updateUIState } = appSlice.actions;
