import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { translate as t } from "../../../helpers/i18n";

import Checkbox from "../../../atomic/atoms/checkbox";

/**
 * A set of checkboxes for the digital terms & conditions
 * Not passing a change handler; it is expected that
 * this component will be wrapped in a <form> element
 */
const DigitalTermsCheckboxGroup = ({}) => {
  return (
    <div data-testid="digital-terms-checkbox-group">
      <div className="bg-primary-01 border tw-border-mutedBlue-100">
        <Checkbox
          name="terms_and_conditions"
          alignment="center"
          label={t(
            "digital_account_creation.terms_and_conditions.checkboxes.usage"
          )}
          errorMessage={t(
            "digital_account_creation.terms_and_conditions.validations.terms_message"
          )}
          className="m-3"
          isTermsData
          required
        />
        <Checkbox
          name="use_electronic_records"
          alignment="center"
          label={
            <Trans
              i18nKey={
                "digital_account_creation.terms_and_conditions.checkboxes.electronic_signatures"
              }
              components={{
                pdf: (
                  <Link
                    to="/assets/digital-terms-and-conditions/D_Digital_Account_Consent_to_Electronic_Records_Final_September_2024.pdf"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          }
          errorMessage={t(
            "digital_account_creation.terms_and_conditions.validations.terms_message"
          )}
          className="m-3"
          isTermsData
          required
        />
      </div>
      <Checkbox
        name="telephone_communications"
        alignment="center"
        data-testid="check-marketing"
        label={
          <Trans
            i18nKey={
              "digital_account_creation.terms_and_conditions.checkboxes.receive_telephone_communications"
            }
            components={{
              pdf: (
                <Link
                  to="/assets/digital-terms-and-conditions/E_Digital_Account_TCPA_Consent_Final_September_2024.pdf"
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        }
        errorMessage={t(
          "digital_account_creation.terms_and_conditions.validations.terms_message"
        )}
        className="m-3"
        isTermsData
      />
    </div>
  );
};

export default DigitalTermsCheckboxGroup;
