import { Navigate, useSearchParams } from "react-router-dom";
import { pages } from "../helpers/pages";
import { handleLogIn, LoginQueryParams } from "../helpers/auth";
import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { useIsUserAuthenticated } from "./use-is-user-authenticated";

const useLoginRedirect = () => {
  const isUserAuthenticated = useIsUserAuthenticated();
  const { status } = useSelector((state: RootState) => state.user);
  const [searchParams] = useSearchParams();

  /* Accessing a protected page */
  if (!isUserAuthenticated) {
    /** If there was an error loading the user in a protected page, redirect to the error page */
    if (status === "failed") {
      return <Navigate to={pages.error} />;
    } else {
      /** if not logged in and accessing a protected route, redirect to login page */
      const params: LoginQueryParams | undefined =
        (searchParams.get("email-verification") as LoginQueryParams) ??
        undefined;

      handleLogIn(params);
    }
  }
};

export default useLoginRedirect;
