import { Account } from "../../../types";

const MOCKED_ACCOUNTS = [
  {
    id: "b8c6f71d-dea0-4028-8c70-252d8e04f28f",
    type: "mortgage",
    interest_rate: 5350,
    original_principal: 26200000,
    principal_balance: 1500,
    last_updated_at: "2024-03-12T17:32:28Z",
    property_id: "63806baf-af03-4e74-b7d3-0eb92f612d9e",
    is_current: false,
    subservicer: {
      title: "Mr. Cooper",
    },
    subservicer_loan_number: "e5ac78z-4a83-4848-881a-cc7fdc9f7e53",
    next_payment: {
      due_date: "2023-02-01T00:00:00",
      due_amount: 15231,
      breakdown: [
        {
          key: "principal_value",
          amount: 81990,
        },
        {
          key: "escrow_value",
          amount: 51390,
        },
        {
          key: "mortgage_value",
          amount: 28915,
        },
      ],
    },
    is_nsl: false,
    loan_type_code: "CONV",
    hazard_monthly_amount: 40413,
    tax_monthly_amount: 65198,
  },
  {
    id: "b8c627c2-dea0-4028-8c70-252d8e04f28g",
    type: "mortgage",
    interest_rate: 5350,
    original_principal: 2300000,
    principal_balance: 123100000,
    last_updated_at: "2023-08-22T17:32:28Z",
    property_id: "6380ab4faaf03-4e74-b7d3-0eb92f612d9e",
    is_current: true,
    subservicer: {
      title: "Mr. Cooper",
    },
    subservicer_loan_number: "e5acb85-4a83-4848-881a-cc7fdc9f7e53",
    next_payment: {
      due_date: "2023-02-01T00:00:00",
      due_amount: 51132,
      breakdown: [
        {
          key: "principal_value",
          amount: 81990,
        },
        {
          key: "escrow_value",
          amount: 51390,
        },
        {
          key: "mortgage_value",
          amount: 28915,
        },
      ],
    },
    is_nsl: false,
    loan_type_code: "CONV",
    hazard_monthly_amount: 40413,
    tax_monthly_amount: 65198,
  },
  {
    id: "b8c627c2-dea0-4028-8c70-252d8eg04f28h",
    type: "mortgage",
    interest_rate: 5350,
    original_principal: 1300000,
    principal_balance: 123100000,
    last_updated_at: "2022-07-01T17:32:28Z",
    property_id: "6380ab4faaf03-4e74-b7ds3-0eb92f612d9e",
    is_current: true,
    subservicer: {
      title: "LoanCare",
    },
    subservicer_loan_number: "e5acb85-4a8z3-4848-881a-cc7fdc9f7e53",
    next_payment: {
      due_date: "2023-02-01T00:00:00",
      due_amount: 522132,
      breakdown: [
        {
          key: "principal_value",
          amount: 81990,
        },
        {
          key: "escrow_value",
          amount: 51390,
        },
        {
          key: "mortgage_value",
          amount: 28915,
        },
      ],
    },
    is_nsl: false,
    loan_type_code: "CONV",
    hazard_monthly_amount: 40413,
    tax_monthly_amount: 65198,
  },
  {
    id: "b8c627c2-dea0-4028-8c70-252dj8e04f28i",
    type: "mortgage",
    interest_rate: 5350,
    original_principal: 2200000,
    principal_balance: 0,
    last_updated_at: "2019-01-21T17:32:28Z",
    property_id: "6380ab4faaf03-4e74-b7zd3-0eb92f612d9e",
    is_current: true,
    subservicer: {
      title: "Mr. Cooper",
    },
    subservicer_loan_number: "e5acb85-d4a83-4848-881a-cc7fdc9f7e53",
    next_payment: {
      due_date: "2023-02-01T00:00:00",
      due_amount: 89132,
      breakdown: [
        {
          key: "principal_value",
          amount: 81990,
        },
        {
          key: "escrow_value",
          amount: 51390,
        },
        {
          key: "mortgage_value",
          amount: 28915,
        },
      ],
    },
    is_nsl: false,
    loan_type_code: "CONV",
    hazard_monthly_amount: 40413,
    tax_monthly_amount: 65198,
  },
  {
    id: "b8c627c2-dea0-4028-8c70-25h2d8e04f28j",
    type: "mortgage",
    interest_rate: 5350,
    original_principal: 16200000,
    principal_balance: -1000,
    last_updated_at: "2018-10-01T17:32:28Z",
    property_id: "6380ab4faaf03-4e74-b7dm3-0eb92f612d9e",
    is_current: true,
    subservicer: {
      title: "Mr. Cooper",
    },
    subservicer_loan_number: "e5acbdg85-4a83-4848-881a-cc7fdc9f7e53",
    next_payment: {
      due_date: "2023-02-01T00:00:00",
      due_amount: 22132,
      breakdown: [
        {
          key: "principal_value",
          amount: 81990,
        },
        {
          key: "escrow_value",
          amount: 51390,
        },
        {
          key: "mortgage_value",
          amount: 28915,
        },
      ],
    },
    is_nsl: false,
    loan_type_code: "CONV",
    hazard_monthly_amount: 40413,
    tax_monthly_amount: 65198,
  },

  {
    id: "b8c627c2-dea0-4028-8asdc70-252d8e04f28k",
    type: "mortgage",
    interest_rate: 5350,
    original_principal: 61200000,
    principal_balance: 123100000,
    last_updated_at: "2022-05-15T17:32:28Z",
    property_id: "6380ab4faaf0gh3-4e74-b7d3-0eb92f612d9e",
    is_current: true,
    subservicer: {
      title: "LoanCare",
    },
    subservicer_loan_number: "e5zacb85-4a83-4848-881a-cc7fdc9f7e53",
    next_payment: {
      due_date: "2023-02-01T00:00:00",
      due_amount: 120132,
      breakdown: [
        {
          key: "principal_value",
          amount: 81990,
        },
        {
          key: "escrow_value",
          amount: 51390,
        },
        {
          key: "mortgage_value",
          amount: 28915,
        },
      ],
    },
    is_nsl: false,
    loan_type_code: "CONV",
    hazard_monthly_amount: 40413,
    tax_monthly_amount: 65198,
  },
] as Account[];

export default MOCKED_ACCOUNTS;
