// Include our external dependencies.
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../../stores";
import {
  hideToast,
  Status,
  Toast as ToastType,
} from "../../stores/toast-slice";
import { ReactComponent as ErrorOutlineSVG } from "purple-rain/icons/error-outline.svg";
import { ReactComponent as InfoOutlineSVG } from "purple-rain/icons/info-outline.svg";
import { ReactComponent as CheckCircleSVG } from "purple-rain/icons/check-circle.svg";
import { ReactComponent as HomeInsuranceSVG } from "purple-rain/icons/home-insurance.svg";
import { ReactComponent as CloseOutlineSVG } from "purple-rain/icons/close-outline.svg";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { translate as t } from "../../helpers/i18n";

interface Timer {
  id: string;
  timer: ReturnType<typeof setTimeout>;
}

const TIME_OUT = 5000; // in ms

const Toasts: React.FC = () => {
  const [timers, setTimers] = useState<Timer[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const toasts = useSelector((state: RootState) => state.toast.toasts);

  const handleHideToast = (toast: ToastType) => dispatch(hideToast(toast.id!));

  const handleSVG = (status: Status | undefined) => {
    switch (status) {
      case "alert":
        return (
          <ErrorOutlineSVG
            className="text-notification-alert-bold"
            height={32}
            width={32}
          />
        );
      case "error":
        return (
          <ErrorOutlineSVG
            className="text-notification-error-bold"
            height={32}
            width={32}
          />
        );
      case "info":
        return (
          <InfoOutlineSVG
            className="text-notification-info-bold"
            height={32}
            width={32}
          />
        );
      case "success":
        return (
          <CheckCircleSVG
            className="text-notification-success-bold"
            height={32}
            width={32}
          />
        );
      default:
        return (
          <HomeInsuranceSVG className="text-primary" height={32} width={32} />
        );
    }
  };

  useEffect(() => {
    toasts.forEach((selectedToast) => {
      if (!timers.find((elem) => elem.id === selectedToast.id)) {
        setTimers((prev) => {
          const newTimersArray = [
            ...prev,
            {
              id: selectedToast.id,
              timer: setTimeout(() => {
                dispatch(hideToast(selectedToast.id!));
              }, TIME_OUT),
            },
          ];
          return newTimersArray as Timer[];
        });
      }
    });

    return () => timers.forEach(({ timer }) => clearTimeout(timer));
  }, [timers, toasts, dispatch]);

  return (
    <div aria-live="polite" aria-atomic="true" className="sticky-bottom">
      <ToastContainer className="bottom-0 end-0 p-3">
        {toasts.map((toast) => (
          <Toast
            className={`${toast.status} fade show`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            key={toast.id}
          >
            <Toast.Body className="d-flex flex-row align-items-center justify-content-between h-100">
              <span>{handleSVG(toast.status)}</span>
              <div className="toast-content">{toast.content}</div>
              <button
                type="button"
                data-bs-dismiss="toast"
                className="btn p-0 rounded-0 d-flex align-items-center"
                aria-label={t("accessibility.close")}
                onClick={() => handleHideToast(toast)}
              >
                <CloseOutlineSVG
                  className="text-primary"
                  height={14}
                  width={14}
                />
              </button>
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </div>
  );
};

export default Toasts;
