import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { LiquidationType } from "../types";
import { useMultipleLoanAccounts } from "./use-multi-loan-accounts";

export const useMortgageValues = (accountId?: string) => {
  const { account: accountState } = useSelector((state: RootState) => state);
  const accounts = useMultipleLoanAccounts();
  const selectedAccount =
    accounts.find((acc) => acc.id === accountId) || undefined;
  const firstAccount = accounts?.[0] || undefined;

  const isLoading = accountState.status === "loading";
  const isIdle = accountState.status === "idle";

  // ACCOUNT SECTION
  const account = accountId ? selectedAccount : firstAccount;
  const principalBalance = account?.principal_balance || 0;
  const originalPrincipal = account?.original_principal || 0;
  const isNonSolicit = selectedAccount
    ? !!account?.is_nsl
    : accounts.some((acc) => acc.is_nsl);
  /**
   *  Given that LVDMS return the following values as strings:
   *  - Paid in Full
   *  - Paid In Full
   *  - PAID IN FULL
   *
   *  a case-insensitive comparison should be performed for this value
   */
  const paidInFullValue: LiquidationType = "PAID IN FULL";

  const isPaidOff =
    paidInFullValue === account?.liquidation_type?.toUpperCase();

  // FORMULAS
  /**
   * Paid principal balance
   */
  const paidPrincipalBalance: number = originalPrincipal - principalBalance;

  /**
   * Assumable mortgage are those with FHA or VA mortgage types
   * and that have a positive balance in their mortgage
   */
  const isAssumable = useMemo(() => {
    if (!account) return false;
    return (
      (account?.loan_type_code === "FHA" || account?.loan_type_code === "VA") &&
      principalBalance > 0.01 &&
      account?.interest_rate &&
      account?.interest_rate <= 8000
    );
  }, [account, principalBalance]);

  /**
   * Checks if the user has a remaining principal balance on their mortgage.
   */
  const hasPrincipalBalance =
    !!account?.principal_balance && account?.principal_balance > 0;

  /**
   * Indicates if a loan is current, or non-current (i.e. has a
   * delinquency, forbearance, foreclosure, bankruptcy, loss
   * mitigation)
   *
   * Note: "current" / "non-current" is Lakeview terminology.
   * At Fastlane, we often refer to this as "active" / "non-active".
   */
  const isCurrent = selectedAccount
    ? !!account?.is_current
    : accounts.every((acc) => acc.is_current);

  return {
    account,
    isLoading,
    isIdle,
    paidPrincipalBalance,
    isNonSolicit,
    isCurrent,
    isAssumable,
    hasPrincipalBalance,
    isPaidOff,
  };
};
