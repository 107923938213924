import LPbF from "../../atoms/lpbf";

const ConsentToElectronicRecords = () => (
  <>
    <p className="mb-s24">
      <b>
        <LPbF /> Digital Account Disclosure and Consent to the Use of Electronic
        Communications and Signatures
      </b>
    </p>
    <p className="mb-s24">
      <b>Consent to Electronic Records.</b> As part of your relationship with
      Lakeview Loan Servicing, LLC, and its respective affiliates, subsidiaries,
      and service providers, including Fastlane Technology Solutions, LLC,
      (collectively, “we,” “us,” and “our”), we are required by law to give you
      certain information in writing on paper. With your consent, we can provide
      this information to you by electronic delivery instead. By providing your
      consent, you agree to receive electronically any disclosure, notice,
      document, or communication (collectively, “Record”).
    </p>
    <p className="mb-s24">
      <b>Scope of Consent.</b> Your consent applies to any Record related to any
      of the Products or Services (as described in the <LPbF /> Digital Account
      or other applicable Terms of Use) we may make accessible or available, or
      offer to you, whether through a website, software application, email,
      messaging services (including text messages, as permissible by law), or
      otherwise. Your consent includes, but is not limited to, your <LPbF />{" "}
      Digital Account. This consent means that, as applicable, each time you use
      this Digital Account and submit information to us, you agree to online
      access and acceptance of documents, disclosures, and forms. This agreement
      to conduct transactions by electronic means continues in effect until you
      complete the application regardless of the number of sessions you utilize.
    </p>
    <p className="mb-s24">
      <b>Delivery of Records.</b> In our sole discretion, the Records we provide
      to you, or that you sign or agree to at our request, may be in electronic
      form. We may also use electronic signatures and obtain them from you as
      part of our transactions with you. Records may be delivered to you by: (i)
      posting on a website, (ii) emailing to you at the email address you
      provide to us, (iii) mobile application, (iv) accessing an online location
      that we designate in an e-mail, or other electronic notice we send to you
      at the time the Records is available, or (v) other means we make available
      to you. Records may contain important information or disclosures
      concerning your financial accounts or my use of the Products or Services,
      and you agree to review such Records promptly after we provide them to
      you.
    </p>
    <p className="mb-s24">
      We may always, in our sole discretion, provide you with any Record in
      writing or send it to you via the U.S. mail or other means of delivery,
      even if you have chosen to receive it electronically. We may require any
      information you provide to us, or any document you sign, to be delivered
      to us in paper form. You should print or download a copy of any Records
      for your own records, including this Disclosure and Consent.
    </p>
    <p className="mb-s24">
      <b>Paper Copies.</b> If we provide an electronic Record to you, and you
      would like a paper copy, you may contact customer service at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>{" "}
      and request a paper version. You will not be charged a fee for a paper
      copy. We may not retain copies of Records for longer than is required by
      law.
    </p>
    <p className="mb-s24">
      <b>Hardware and Software Requirements.</b> In order to view and retain
      your electronic Records, you will need:
    </p>
    <ul className="mb-s24">
      <li>A secure access to the internet;</li>
      <li>
        A secure email account and related software capable of receiving email
        through the internet;
      </li>
      <li>
        Web browsing software (such as a version of Google Chrome, Mozilla
        Firefox, Microsoft Edge, or Apple Safari that is currently being
        supported by its publisher);
      </li>
      <li>
        Software that allows you to view and print or save PDF documents (such
        as Adobe Reader or similar software);
      </li>
      <li>
        An operating system and hardware capable of supporting the above
        software requirements; and
      </li>
      <li>
        Sufficient storage space or a printer that allows you to save or print
        electronic Records.
      </li>
    </ul>
    <p className="mb-s24">
      If our hardware or software requirements change, and that change would
      create a material risk that you would not be able to access or retain
      electronic Records, we will notify you as required by law.
    </p>
    <p className="mb-s24">
      <b>Updating Your Contact Information.</b> You agree that you will promptly
      inform us of any changes to your email address, phone number or mailing
      address by updating your <LPbF /> Digital Account details or by contacting
      us at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      <b>Withdrawing Consent.</b> You may withdraw your consent to receive
      Records electronically by contacting us at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      . Note, if you do so, we may terminate your <LPbF /> Digital Account.
      Withdrawing your consent to receive <LPbF /> Digital Account documents
      electronically will not affect any other consent you may have provided to
      us or a third party to provide Records to you electronically, such as in
      connection with a loan, insurance application, or account. Please refer to
      those appropriate Terms and Conditions for directions on how to withdraw
      consent.
    </p>
    <p className="mb-s24">
      If, after withdrawing your consent to receive Records electronically for
      your <LPbF /> Digital Account, you wish to continue with an application
      for credit, please follow the instructions below:
    </p>
    <ul className="mb-s24">
      <li>
        For a <b>Home Equity Loan application</b>, you will not be able to
        proceed with Lakeview’s Digital HELOAN application process. Please reach
        out to a loan officer at{" "}
        <span className="text-nowrap">(855) 713-2644</span> for instructions on
        how to resume your application via the Lakeview application portal
        instead.
      </li>
      <li>
        For <b>all other loan applications</b>, please visit the Lakeview
        application portal directly at{" "}
        <a href="https://apply.lakeview.com" target="_blank" rel="noreferrer">
          https://apply.lakeview.com
        </a>
        , or reach out to a Lakeview loan officer{" "}
        <span className="text-nowrap">(855) 294-8564</span> for instructions.
      </li>
    </ul>
    <p className="mb-s24">
      <b>
        By providing your consent, you are consenting to the use of electronic
        records and signatures in connection with your relationship with us, and
        also confirming that:
      </b>
    </p>
    <ul className="mb-s24">
      <li>
        <b>
          You have reviewed and agree to the terms of this Disclosure and
          Consent,
        </b>
      </li>
      <li>
        <b>You have the hardware and software described above,</b>
      </li>
      <li>
        <b>You are able to receive and review electronic Records, and</b>
      </li>
      <li>
        <b>
          You have an active email account and have provided the correct email
          address to us.
        </b>
      </li>
    </ul>
    <p className="mb-s24"></p>
  </>
);

export default ConsentToElectronicRecords;
