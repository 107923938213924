import { useDispatch, useSelector } from "react-redux";
import { translate as t } from "../../../helpers/i18n";
import { AppDispatch, RootState } from "../../../stores";
import { Trans } from "react-i18next";
import { closeCreatedAccountModal } from "../../../stores/sso-user-slice";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import GenericBaseModal from "../modal/generic-base-modal";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";
import { Button } from "../../atoms/button";

interface AccountCreatedModalProps {
  show: boolean;
  module?: string | undefined;
  onClose?: () => void;
}

const AccountCreatedModal = ({
  show,
  module = "sso-account-creation-success",
  onClose,
}: AccountCreatedModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    user: { user },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (show) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: module,
      });
    }
  }, [show, module]);

  const handleClose = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: module,
      button: "close",
    });
    onClose && onClose();
    dispatch(closeCreatedAccountModal());
  };

  const handleGoToDashboard = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: module,
      button: "continue-to-dashboard",
    });

    onClose && onClose();
    dispatch(closeCreatedAccountModal());
  };

  return (
    <GenericBaseModal
      show={show}
      onClose={handleClose}
      onCloseButtonClick={handleClose}
      title={t("account_created_modal.title")}
      buttons={
        <ModalButtonsWrapper>
          <Button onClick={handleGoToDashboard}>
            {t("account_created_modal.button")}
          </Button>
        </ModalButtonsWrapper>
      }
    >
      <p className="m-0">
        <Trans
          i18nKey="account_created_modal.content_cobranded"
          values={{
            email: user?.email,
          }}
          components={{
            a: (
              <a
                target="_blank"
                rel="noreferrer"
                href={t("account_created_modal.href")}
              >
                {t("account_created_modal.link")}
              </a>
            ),
            bold: <strong />,
          }}
        />
      </p>
    </GenericBaseModal>
  );
};

export default AccountCreatedModal;
