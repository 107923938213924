import { Suspense, useEffect, useState } from "react";
import {
  getTermsVersions,
  isMajorVersionDifferent,
  lazyWithRefresh,
} from "../../../helpers/utils";
// import { AFBA_VERSION } from "../../../helpers/constants";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "../../../stores";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { updateUIState } from "../../../stores/app-slice";

const TermsAndConditionsModal = lazyWithRefresh(
  () => import("../../../atomic/organisms/terms-and-conditions-modal"),
  "terms-and-conditions-modal"
);

const InitialTermsConditions = () => {
  const [showAcceptAfBAModal /* setShowAcceptAfBAModal */] =
    useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.user);
  const { showSSOBanner } = useSelector((state: RootState) => state.ssoUser);
  const { showTavantBanner } = useSelector((state: RootState) => state.tavant);
  const { isPendingFastlaneAccount /* hasDismissedAfBAModal */ } =
    useSSOValues();
  const { userTermsVersion, latestTermsVersion } = getTermsVersions(user);

  const showTermsAndConditionsModal =
    !isPendingFastlaneAccount &&
    user &&
    isMajorVersionDifferent(latestTermsVersion, userTermsVersion);

  /**
   * This piece of code is in charge of ABAD re-acceptance functionality.
   * There are issues with this logic found in https://fastlanefi.atlassian.net/browse/CLF-4230
   * This conditional is very complicated; need to clarify precise business requirements
   * before re-instating
   */
  // useEffect(() => {
  //   if (
  //     user &&
  //     (!user?.disclaimer_acceptances?.AFBA?.version ||
  //       isMajorVersionDifferent(
  //         AFBA_VERSION,
  //         user?.disclaimer_acceptances?.AFBA?.version
  //       )) &&
  //     !hasDismissedAfBAModal &&
  //     !showTermsAndConditionsModal &&
  //     !isPendingFastlaneAccount &&
  //     !showTavantBanner
  //   ) {
  //     setShowAcceptAfBAModal(true);
  //   } else {
  //     setShowAcceptAfBAModal(false);
  //   }
  // }, [
  //   user,
  //   user?.disclaimer_acceptances?.AFBA?.version,
  //   hasDismissedAfBAModal,
  //   showTermsAndConditionsModal,
  //   showTavantBanner,
  //   isPendingFastlaneAccount,
  // ]);

  useEffect(() => {
    dispatch(
      updateUIState({
        isContentBlockOverlay:
          showAcceptAfBAModal ||
          showSSOBanner ||
          showTermsAndConditionsModal ||
          showTavantBanner,
      })
    );
  }, [
    showAcceptAfBAModal,
    showSSOBanner,
    showTavantBanner,
    showTermsAndConditionsModal,
  ]);

  const handleCloseOnlyAfBa = () => {
    dispatch(updateUIState({ isContentBlockOverlay: false }));
  };
  const handleAcceptOnlyAfBa = () => {
    dispatch(updateUIState({ isContentBlockOverlay: false }));
  };

  return (
    <>
      {showTermsAndConditionsModal && (
        <Suspense fallback={null}>
          <TermsAndConditionsModal isUpdatedTermsAndConditions />
        </Suspense>
      )}
      {showAcceptAfBAModal && !showTermsAndConditionsModal && (
        <Suspense fallback={null}>
          <TermsAndConditionsModal
            closeOnlyAfBa={handleCloseOnlyAfBa}
            acceptOnlyAfBa={handleAcceptOnlyAfBa}
            showOnlyAfBA
            shouldClearModalState={false}
          />
        </Suspense>
      )}
    </>
  );
};

export default InitialTermsConditions;
