import { FormEvent } from "react";

/**
 * Helper that uses HTML5's constraint validation API
 * to validate forms.
 *
 * The form needs to have the `novalidate` attribute in order
 * to trigger the validation process, otherwise it won't be valid.
 *
 * @param event - The submit event from the form
 * @returns {boolean} - If the form is invalid or not.
 */
export const handleValidation = function (event: FormEvent<HTMLFormElement>) {
  // ensure this event is from a form that needs validation.
  const target = event!.target as HTMLFormElement;
  if (target.tagName === "FORM" && target.hasAttribute("novalidate")) {
    event.preventDefault();
    const isValid = target.checkValidity();
    if (!isValid) target.classList.add("was-validated");
    return isValid;
  }

  return false;
};

/**
 * Helper function to extract form values from a form submission event.
 *
 * This function uses the FormData API to gather all the values from the form
 * that triggered the submit event. It returns an object with the form field names
 * as keys and their corresponding values.
 *
 * @param event - The form submission event
 * @returns {T} - An object containing the form values
 */
export const getFormValues = <T>(event: FormEvent<HTMLFormElement>): T => {
  const formData = new FormData(event.currentTarget);
  const formValues = {} as T;

  formData.forEach((value, key) => {
    formValues[key] = value;
  });

  return formValues;
};
