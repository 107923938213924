import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AsyncStatus, Subservicer } from "../types";
import dashApi from "../helpers/dash-api";

const getServicerLink = dashApi.path("/servicer-link").method("get").create();

interface SubservicerState {
  subservicer: Subservicer | undefined;
  status: AsyncStatus;
}

const initialState: SubservicerState = {
  subservicer: undefined,
  status: "idle",
};

export type RedirectTargetType =
  | "OVERVIEW"
  | "PAYMENT"
  | "FORM_1098"
  | "MORTGAGE_ASSISTANCE"
  | undefined;

export interface SubservicerParams {
  redirect_target: RedirectTargetType;
}

/**
 * Get Subservicer link
 */
export const getSubservicerLink = createAsyncThunk(
  "/subservicer/link",
  async function (subservicerParams: SubservicerParams | object) {
    const response = await getServicerLink(subservicerParams);

    return response.data;
  }
);

export const subservicerSlice = createSlice({
  name: "subservicer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubservicerLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubservicerLink.fulfilled, (state, action) => {
        state.status = "loaded";
        state.subservicer = action.payload as Subservicer;
      })
      .addCase(getSubservicerLink.rejected, (state) => {
        state.status = "failed";
      });
  },
});
