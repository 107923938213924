import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatus, CreateSSOAccountDetails } from "../types";
import dashApi from "../helpers/dash-api";
import { onSSOUserCreated } from "./user-slice";

const saveSSOUser = dashApi.path("/sso-user").method("post").create();

export type StepType = "introduction" | "password_setup";

interface SSOUserState {
  showSSOBanner: boolean;
  currentStep: StepType;
  isFirstTimeBanner: boolean;
  status: AsyncStatus;
  showCreatedAccountModal: boolean;
  showTCModal: boolean;
}

const initialState: SSOUserState = {
  showSSOBanner: false,
  currentStep: "introduction",
  isFirstTimeBanner: true,
  status: "idle",
  showCreatedAccountModal: false,
  showTCModal: false,
};

/**
 * Create SSO user async thunk
 */
export const createSSOUser = createAsyncThunk(
  "/user/createSSOUser",
  async function (
    createSSOAccountParams: CreateSSOAccountDetails,
    { dispatch }
  ) {
    const response = await saveSSOUser(createSSOAccountParams);
    dispatch(onSSOUserCreated(createSSOAccountParams));

    return response;
  }
);

export const SSOUserSlice = createSlice({
  name: "sso-user",
  initialState,
  reducers: {
    displayFirstTimeBanner(state) {
      state.showSSOBanner = true;
      state.currentStep = "introduction";
      state.isFirstTimeBanner = true;
    },
    displayPasswordSetup(state, action: PayloadAction<boolean>) {
      state.showSSOBanner = true;
      state.currentStep = "password_setup";
      state.isFirstTimeBanner = action.payload;
    },
    hideSSOBanner(state) {
      state.showSSOBanner = false;
    },
    closeCreatedAccountModal: (state) => {
      state.showCreatedAccountModal = false;
    },
    updateTCModal: (state, action: PayloadAction<boolean>) => {
      state.showTCModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSSOUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createSSOUser.fulfilled, (state) => {
        state.status = "loaded";
        state.showTCModal = false;
        state.showCreatedAccountModal = true;
        state.showSSOBanner = false;
      })
      .addCase(createSSOUser.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  displayFirstTimeBanner,
  displayPasswordSetup,
  hideSSOBanner,
  closeCreatedAccountModal,
  updateTCModal,
} = SSOUserSlice.actions;
