import { ReactComponent as AdaAccessibilitySVG } from "../../../images/ada-accessibility.svg";
import { Link } from "react-router-dom";
import { pages } from "../../../helpers/pages";
import { translate as t } from "../../../helpers/i18n";
import { cva } from "class-variance-authority";
import { Theme, useThemeContext } from "../../../contexts/theme-context";

const accessibilityIconVariants = cva("", {
  variants: {
    theme: {
      [Theme.PURPLE_RAIN]: "tw-text-mutedBlue-700",
      [Theme.LAKEVIEW_BLUE]: "text-primary-07",
    },
  },
});

const AccessibilityAdaCompliance = () => {
  const { theme } = useThemeContext();

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <AdaAccessibilitySVG className={accessibilityIconVariants({ theme })} />
      </div>
      <Link className="typeface-body-small" to={pages.adaAccessibility}>
        {t("layout.footer.ada_accessibility.title")}
      </Link>
    </div>
  );
};

export default AccessibilityAdaCompliance;
