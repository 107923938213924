import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { pages } from "../helpers/pages";

const useStandaloneMode = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return (
    location.pathname.startsWith(pages.resourceCenter) &&
    searchParams.get("mode") === "standalone"
  );
};

export default useStandaloneMode;
