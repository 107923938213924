// Include our external dependencies.
import { Fetcher, Middleware } from "openapi-typescript-fetch";
import { paths } from "dash-api/types";
import {
  generateNewSession,
  generateSessionTimestamp,
  getSessionId,
} from "analytics";

const middy: Middleware = async (url, init, next) => {
  // Pre Hook…

  // API call
  const response = await next(url, init);

  // Reset session timestamp after the API call
  generateSessionTimestamp();

  return response;
};

// declare fetcher for paths
const fetcher = Fetcher.for<paths>();

const sessionId = getSessionId();

// global configuration
fetcher.configure({
  baseUrl: process.env.REACT_APP_API_DOMAIN,
  use: [middy],
  init: {
    credentials: "include",
    headers: [
      // if you need to pass custom headers, you have to explicitly
      // declare them; here is an example
      // ["Access-Control-Expose-Headers", "X-Remaining-Attempts"],
      ["X-SESSION-ID", sessionId ? sessionId : generateNewSession()],
    ],
  },
});

export default fetcher;
