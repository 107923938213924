import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { RootState } from "../stores";
import { useSelector } from "react-redux";
import { getFastlaneVersion } from "../helpers/fastlane-info";
import { isObjectEmpty } from "../helpers/utils";
import { useIsUserAuthenticated } from "./use-is-user-authenticated";

export const useMonitoring = () => {
  const isUserAuthenticated = useIsUserAuthenticated();
  const { user: userData } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const currentDatadogConfig = datadogRum.getInitConfiguration() ?? {};

    if (!isObjectEmpty(currentDatadogConfig)) return;

    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || "",
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
      site: "datadoghq.com",
      service: "lls",
      env: process.env.REACT_APP_DATADOG_ENV,
      version: getFastlaneVersion(),
      sessionSampleRate:
        Number(process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE) || 0,
      sessionReplaySampleRate:
        Number(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE) || 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask",
    });
  }, []);

  useEffect(() => {
    if (isUserAuthenticated) {
      datadogRum.setGlobalContextProperty("enduser.id", userData?.id);
    }
  }, [isUserAuthenticated, userData?.id]);
};
