/**
 * Email Slice
 *
 * Takes care of calling the email verification endpoint
 */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import { AsyncStatus } from "../types";

const emailVerification = dashApi
  .path("/email-verification")
  .method("post")
  .create();

interface EmailState {
  status: AsyncStatus;
}

const initialState: EmailState = {
  status: "idle",
};

export const sendEmailVerification = createAsyncThunk(
  "email/sendVerification",
  async (userId: string) => {
    return await emailVerification({ user_account_id: userId });
  }
);

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmailVerification.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendEmailVerification.fulfilled, (state) => {
        state.status = "loaded";
      })
      .addCase(sendEmailVerification.rejected, (state) => {
        state.status = "failed";
      });
  },
});
