import React, { ForwardedRef, forwardRef } from "react";
import { UIButton, UIButtonProps } from "ui";
import { useThemeContext } from "../../contexts/theme-context";

export interface ButtonProps extends UIButtonProps {}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref: ForwardedRef<HTMLButtonElement>) => {
    const { theme } = useThemeContext();
    return <UIButton ref={ref} theme={theme} {...props} />;
  }
);

Button.displayName = "Button";
export { Button };
