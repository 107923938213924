// Include our external dependencies.
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import reduxStore from "./stores";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./components/errors/error-boundary";
import DOMPurify from "dompurify";
import fastlaneInformation from "./helpers/fastlane-info";
import "./helpers/i18n";
import AppInitializer from "./app-initializer";
import SEOComponent from "./components/seo-component";
import ThemeProvider from "./contexts/theme-context";
import "purple-rain/dist/purple-rain.css";
import "./sass/tailwind.scss";
import "./sass/styles.scss";

// Print fastlane version
fastlaneInformation();

// Render our React application
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <ErrorBoundary>
        <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
          <SEOComponent />
          <AppInitializer>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </AppInitializer>
        </BrowserRouter>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
);

// TrustedScriptURL configuration
if (window.trustedTypes && window.trustedTypes.createPolicy) {
  window.trustedTypes.createPolicy("default", {
    createHTML: (string) =>
      DOMPurify.sanitize(string, {
        RETURN_TRUSTED_TYPE: true,
      }) as unknown as string,
    createScriptURL: (string) => string,
    createScript: (string) => string,
  });
}

// Service Worker - Minimal, just enough to allow installability
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(() => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((serviceWorker) => {
        console.log("Service Worker registered: ", serviceWorker);
      })
      .catch((error) => {
        console.error("Error registering the Service Worker: ", error);
      });
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
