// Include our external dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  LAKEVIEW_PRODUCT,
  LAKEVIEW_MODULE,
  LakeviewProductType,
  LakeviewModuleType,
} from "../helpers/constants";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Tavant Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
interface TavantState {
  showTavantBanner: boolean;
  isTavantProcess: boolean;
  lakeviewModule: LakeviewModuleType;
  lakeviewProduct: LakeviewProductType;
  href: string;
}
const initialState: TavantState = {
  showTavantBanner: false,
  isTavantProcess: false,
  lakeviewModule: LAKEVIEW_MODULE.WTQ,
  lakeviewProduct: LAKEVIEW_PRODUCT.CASHOUT_REFINANCE,
  href: "",
};

export const tavantSlice = createSlice({
  name: "tavant",
  initialState,
  reducers: {
    startTavantFlow(state, action) {
      const payload = action.payload as Partial<TavantState>;
      state.showTavantBanner = true;
      state.isTavantProcess = true;
      state.lakeviewModule = payload.lakeviewModule as LakeviewModuleType;
      state.lakeviewProduct = payload.lakeviewProduct as LakeviewProductType;
      state.href = payload.href as string;
    },
    finishTavantFlow(state) {
      state.showTavantBanner = false;
      state.isTavantProcess = false;
    },
    setShowTavantBanner(state, action: PayloadAction<boolean>) {
      state.showTavantBanner = action.payload;
    },
    setIsTavantProcess(state, action: PayloadAction<boolean>) {
      state.isTavantProcess = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startTavantFlow,
  finishTavantFlow,
  setShowTavantBanner,
  setIsTavantProcess,
} = tavantSlice.actions;
