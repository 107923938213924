/**
 * Restore user scroll on navigation
 */
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useScrollRestore = () => {
  const { pathname } = useLocation();
  const previousPathname = useRef<string>();

  // Listen for location changes
  useEffect(() => {
    // Avoid scrolling if the user refreshes the page, it will already load with the scroll restored
    if (!!previousPathname.current && pathname !== previousPathname.current) {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }
    previousPathname.current = pathname;
  }, [pathname]);
};

export default useScrollRestore;
