import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import ConfirmLeaveModal, {
  ConfirmLeaveModalType,
} from "./confirm-leave-modal";

export interface ExternalLinkProps {
  className?: string;
  children?: React.ReactNode | string;
  href: string;
  module?: string;
  button?: string;
  targetBlank?: boolean;
  type?: string;
  eventParams?: object;
  showConfirmLeaveModal?: boolean;
  modalType?: ConfirmLeaveModalType;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  testId?: string;
}

interface AttributesType {
  target?: string;
  rel?: string;
  type?: string;
}

export const ExternalLink = ({
  className = "",
  targetBlank = false,
  showConfirmLeaveModal = false,
  modalType = "subservicer_link",
  type = "",
  children,
  href,
  module = "",
  button,
  eventParams,
  onClick,
  testId = "external-link",
}: ExternalLinkProps) => {
  let attributes: AttributesType = {};
  if (targetBlank) attributes = { target: "_blank", rel: "noreferrer" };
  if (type) attributes.type = type;

  const clickEvent = {
    button,
    module,
    link: href,
    ...eventParams,
  };

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, clickEvent);

    onClick?.(event);
  };

  if (showConfirmLeaveModal) {
    return (
      <ConfirmLeaveModal
        modalType={modalType}
        viewSource={module}
        href={href}
        eventParams={clickEvent}
      >
        <a
          data-testid={`${testId}-modal`}
          href={href}
          className={className}
          {...attributes}
        >
          {children}
        </a>
      </ConfirmLeaveModal>
    );
  }

  return (
    <a
      data-testid={testId}
      href={href}
      onClick={handleClick}
      className={className}
      {...attributes}
    >
      {children}
    </a>
  );
};
