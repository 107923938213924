import { ReactElement, useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import Collapse from "react-bootstrap/Collapse";
import Overlay from "react-bootstrap/Overlay";
import { ReactComponent as CloseIconSVG } from "purple-rain/assets/close-icon.svg";
import { ReactComponent as HamburgerSVG } from "purple-rain/icons/hamburger.svg";
import { ReactComponent as ChevronUpSVG } from "purple-rain/icons/chevron-up.svg";
import { ReactComponent as ChevronUpRebrandingSVG } from "purple-rain/icons/chevron-up-rebranding.svg";
import { ReactComponent as ChevronDownSVG } from "purple-rain/icons/chevron-down.svg";
import { ReactComponent as ChevronDownRebrandingSVG } from "purple-rain/icons/chevron-down-rebranding.svg";
import { translate as t } from "../../../helpers/i18n";
import { pages } from "../../../helpers/pages";
import { RootState } from "../../../stores";
import { AuthenticatedButton, HeaderLoginLink } from "./header-auth";
import {
  HeaderProductMenuItem,
  HOME_EQUITY_LOAN_TRANSLATION_KEY,
  PRODUCT_LIST,
} from "../../../helpers/constants";
import { EXPERIMENTS, useABTest } from "../../../hooks/use-ab-test";
import { experimentManualExposure } from "../../../helpers/experiment-exposure";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useDashboardVersion } from "../../../hooks";
import { cva } from "class-variance-authority";
import {
  Theme,
  ThemeType,
  useThemeContext,
} from "../../../contexts/theme-context";

const MIXPANEL_MODULE_NAME = "header";

const productMenuLinks = cva("me-5 menu-main-link text-nowrap ", {
  variants: {
    theme: {
      [Theme.PURPLE_RAIN]: "typeface-body-regular fw-semibold tw-text-black",
      [Theme.LAKEVIEW_BLUE]: "typeface-body-regular",
    },
  },
});

const productMenuButton = cva(
  "btn btn-link p-0 d-md-inline d-none menu-main-link me-5 text-nowrap",
  {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]:
          "text-capitalize typeface-body-regular tw-text-black fw-semibold",
        [Theme.LAKEVIEW_BLUE]: "typeface-body-regular",
      },
    },
  }
);

const productListItem = cva(
  "col h-100 tw-text-black text-decoration-none menu-link tw-menu-link rounded",
  {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]:
          "tw-p-4 hover:tw-shadow-1 hover:tw-border-transparent",
        [Theme.LAKEVIEW_BLUE]: "p-3",
      },
    },
  }
);

interface iconByThemeInterface
  extends Record<
    ThemeType,
    {
      up: ReactElement;
      down: ReactElement;
    }
  > {}

const iconMenuButton = (theme: ThemeType, show: boolean) => {
  const iconByTheme: iconByThemeInterface = {
    [Theme.PURPLE_RAIN]: {
      up: <ChevronUpSVG />,
      down: <ChevronDownSVG />,
    },
    [Theme.LAKEVIEW_BLUE]: {
      up: <ChevronUpRebrandingSVG />,
      down: <ChevronDownRebrandingSVG />,
    },
  };

  const selectedTheme = iconByTheme[theme] || iconByTheme[Theme.PURPLE_RAIN];

  return show ? selectedTheme.up : selectedTheme.down;
};

const trackProductClick = (
  productMenuItem: HeaderProductMenuItem,
  mixpanelPageName: string
) => {
  const link = `${window.location.origin}${productMenuItem.path}`;

  mixpanel.track(MIXPANEL_EVENTS.CLICK, {
    module: MIXPANEL_MODULE_NAME,
    button: productMenuItem.mixpanelButtonName,
    page: mixpanelPageName,
    link,
  });
};

// Dropdown Menu displayed on the header for desktop resolutions
export const ProductsMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useSelector((state: RootState) => state);
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const { mixpanelPageName } = useDashboardVersion();
  const { theme } = useThemeContext();

  // Ref for the product menu
  const productMenu = useRef(null);

  const handleToggleMenu = useCallback(
    () => setShowMenu(!showMenu),
    [showMenu]
  );
  const handleCloseMenu = useCallback(
    (translationKey?: string) => {
      const experimentConditionToMakeExposure =
        groupName !== "None" &&
        translationKey === HOME_EQUITY_LOAN_TRANSLATION_KEY;
      experimentManualExposure(
        EXPERIMENTS.TAVANT_DIGITAL_HELOAN,
        groupName,
        ["Control1", "Treatment1"],
        experimentConditionToMakeExposure
      );
      setShowMenu(false);
    },
    [groupName]
  );

  const homeUrl = user.user ? pages.home : pages.index;

  const handleProductClick = (productMenuItem: HeaderProductMenuItem) => {
    trackProductClick(productMenuItem, mixpanelPageName);
    handleCloseMenu(productMenuItem.translationKey);
  };

  const handleResourceHubClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MIXPANEL_MODULE_NAME,
      button: "resource-hub",
      page: mixpanelPageName,
    });

    handleCloseMenu();
  };

  return (
    <>
      {/* Menu bar */}
      <div className="d-none d-md-flex px-lg-7 ps-md-5 align-items-center">
        <Link
          to={homeUrl}
          onClick={() => handleCloseMenu()}
          className={productMenuLinks({ theme })}
        >
          <span className="link-hover">{t("layout.header.links.home")}</span>
        </Link>
        <button
          className={productMenuButton({ theme })}
          onClick={handleToggleMenu}
        >
          <span className="link-hover link-focus">
            {t("layout.header.links.products")}
          </span>
          <span className="ms-2">{iconMenuButton(theme, showMenu)}</span>
        </button>
        <Link
          to={pages.resourceCenter}
          onClick={handleResourceHubClick}
          className={productMenuLinks({ theme })}
        >
          <span className="link-hover">
            {t("layout.header.links.resource_hub")}
          </span>
        </Link>
      </div>

      {/* Collapsible menu container */}
      <div
        ref={productMenu}
        className="position-absolute menu-dropdown w-100 start-0"
      />

      {/* Collapsible menu content */}
      <Overlay
        target={productMenu}
        container={productMenu}
        containerPadding={-5}
        show={showMenu}
        onHide={() => handleCloseMenu()}
        placement="bottom"
        rootClose
        rootCloseEvent="click"
        transition={Collapse}
      >
        <div id="products-menu" className="menu-dropdown-content w-100">
          <div className="container">
            <div className="gap-3 py-5 d-none d-md-flex justify-content-center flex-wrap menu-dropdown-link-list">
              {PRODUCT_LIST(isHeloanHomeEquityLoanActive).map((product) => (
                <Link
                  to={product.path}
                  onClick={() => handleProductClick(product)}
                  key={product.translationKey}
                  className={productListItem({ theme })}
                >
                  <div className="menu-title fw-bold">
                    {t(
                      `layout.products_list.${product.translationKey}.secondary_title`
                    )}
                  </div>
                  <div>
                    {t(
                      `layout.products_list.${product.translationKey}.description`
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

// Hamburger menu displayed on the header for mobile resolutions
export const HamburgerMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user: userData } = useSelector((state: RootState) => state);
  const { user } = userData;
  const { isExperimentAvailable: isHeloanHomeEquityLoanActive, groupName } =
    useABTest(EXPERIMENTS.TAVANT_DIGITAL_HELOAN);
  const { mixpanelPageName } = useDashboardVersion();
  const { theme } = useThemeContext();

  const handleCloseMenu = useCallback(
    (translationKey?: string) => {
      experimentManualExposure(
        EXPERIMENTS.TAVANT_DIGITAL_HELOAN,
        groupName,
        ["Control1", "Treatment1"],
        groupName !== "None" &&
          translationKey === HOME_EQUITY_LOAN_TRANSLATION_KEY
      );
      setShowMenu(false);
    },
    [groupName]
  );

  const handleProductClick = (productMenuItem: HeaderProductMenuItem) => {
    trackProductClick(productMenuItem, mixpanelPageName);
    handleCloseMenu(productMenuItem.translationKey);
  };

  const handleResourceHubClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: MIXPANEL_MODULE_NAME,
      button: "resource-hub",
      page: mixpanelPageName,
    });

    handleCloseMenu();
  };

  const handleShow = useCallback(() => setShowMenu(true), []);

  const homeUrl = user ? pages.home : pages.index;

  const offcanvasContainerVariants = cva("offcanvas-md d-md-none", {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "bg-gradient",
        [Theme.LAKEVIEW_BLUE]: "tw-bg-white",
      },
    },
  });

  const principalPagesContainerVariants = cva(
    "d-block menu-link tw-menu-link rounded text-decoration-none",
    {
      variants: {
        theme: {
          [Theme.PURPLE_RAIN]:
            "typeface-body-regular tw-text-white p-3 fw-semibold",
          [Theme.LAKEVIEW_BLUE]: "typeface-body-regular tw-text-black py-12px",
        },
      },
    }
  );

  const productPagesContainerVariants = cva(
    "w-100 d-block text-decoration-none menu-link tw-menu-link rounded",
    {
      variants: {
        theme: {
          [Theme.PURPLE_RAIN]: "tw-text-white p-3 fw-semibold",
          [Theme.LAKEVIEW_BLUE]: "typeface-body-regular tw-text-black py-12px",
        },
      },
    }
  );

  const closeIconContainerVariants = cva("", {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "tw-text-white",
        [Theme.LAKEVIEW_BLUE]: "tw-text-black",
      },
    },
  });

  const hrContainerVariants = cva("opacity-100 m-0", {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "tw-text-white",
        [Theme.LAKEVIEW_BLUE]: "tw-text-tan-300",
      },
    },
  });
  const dividerContainerVariants = cva("py-3", {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "tw-text-white",
        [Theme.LAKEVIEW_BLUE]: "",
      },
    },
  });

  const closeButtonHeaderMobileContainerVariants = cva(
    "justify-content-start py-2 px-4",
    {
      variants: {
        theme: {
          [Theme.PURPLE_RAIN]: "",
          [Theme.LAKEVIEW_BLUE]: "ps-3",
        },
      },
    }
  );

  return (
    <>
      <button
        className="btn btn-link tw-text-black border-0 p-0 me-3 me-md-4 d-inline d-md-none"
        onClick={handleShow}
        aria-label={t("layout.header.hamburger.label")}
      >
        <HamburgerSVG width={24} height={24} />
      </button>
      <Offcanvas
        placement="start"
        show={showMenu}
        onHide={() => handleCloseMenu()}
        className={offcanvasContainerVariants({ theme })}
        backdropClassName="d-md-none"
      >
        <Offcanvas.Header
          className={closeButtonHeaderMobileContainerVariants({ theme })}
        >
          <button
            className="btn btn-link tw-text-black px-1 py-2"
            onClick={() => handleCloseMenu()}
          >
            <CloseIconSVG
              className={closeIconContainerVariants({ theme })}
              width={24}
              height={24}
            />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-md-none px-4 pt-3 pb-5">
          <div className="h-100 d-flex flex-column justify-content-between">
            {/* Links section */}
            <div>
              <div className="mt-0">
                <Link
                  to={homeUrl}
                  onClick={() => handleCloseMenu()}
                  className={principalPagesContainerVariants({ theme })}
                >
                  {t("layout.header.links.home")}
                </Link>
              </div>
              <div className={dividerContainerVariants({ theme })}>
                <hr className={hrContainerVariants({ theme })} />
              </div>
              <div className="d-flex flex-column align-items-start typeface-body-regular gap-3">
                {PRODUCT_LIST(isHeloanHomeEquityLoanActive).map((product) => (
                  <Link
                    to={product.path}
                    onClick={() => handleProductClick(product)}
                    key={product.translationKey}
                    className={productPagesContainerVariants({ theme })}
                  >
                    {t(
                      `layout.products_list.${product.translationKey}.secondary_title`
                    )}
                  </Link>
                ))}
              </div>
              <div className={dividerContainerVariants({ theme })}>
                <hr className={hrContainerVariants({ theme })} />
              </div>
              <div className="mt-0">
                <Link
                  to={pages.resourceCenter}
                  onClick={handleResourceHubClick}
                  className={principalPagesContainerVariants({ theme })}
                >
                  {t("layout.header.links.resource_hub")}
                </Link>
              </div>
            </div>
            {/* Login/Logout section */}
            <div>
              {user ? (
                <AuthenticatedButton className="w-100 tw-text-white" />
              ) : (
                <HeaderLoginLink className="w-100" />
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
