import { Navigate } from "react-router-dom";
import { pages } from "../../helpers/pages";
import Layout from "./index";
import { useIsUserAuthenticated } from "../../hooks/use-is-user-authenticated";

const PublicLayout = () => {
  const isUserAuthenticated = useIsUserAuthenticated();

  /** if logged in and accessing an unprotected route, redirect to home page */
  if (isUserAuthenticated) {
    return <Navigate to={pages.home} />;
  }

  return <Layout displayAuthInfo={false} />;
};

export default PublicLayout;
