import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { DEEP_LINKING_CURRENT_HASH_ID } from "../helpers/constants";

/**
 * Hook to validate if the url has a hash identifier and a valid module in the DOM
 * If that happens, then scroll into the specific module
 */
const useDeepLink = () => {
  const location = useLocation();
  const [selectedModule, setSelectedModule] = useState<
    HTMLElement | undefined
  >();
  const [selectedHash, setSelectedHash] = useState<string>("");
  const [currentPath, setCurrentPath] = useState<string>("");

  // Options for the observer (which mutations to observe)
  const observerConfig = useMemo(() => {
    return {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
    };
  }, []);

  /**
   * Callback function to execute when mutations are observed
   * @param mutations
   * @param observer
   */
  const observerCallback = (mutations: MutationRecord[]) => {
    mutations.some(function (mutation) {
      return Array.from(mutation.addedNodes).some((element) => {
        const elementId = (element as HTMLElement).id;
        if (elementId === selectedHash) {
          setSelectedModule(element as HTMLElement);
          return true;
        }

        return false;
      });
    });
  };

  // Create an observer instance linked to the callback function
  const observer: MutationObserver = new MutationObserver(observerCallback);

  const cleanState = () => {
    setSelectedHash("");
    setCurrentPath("");
  };

  useEffect(() => {
    const locationHash = location.hash;

    if (locationHash) {
      const hashId = locationHash.substring(1);
      setSelectedHash(hashId);
      setCurrentPath(location.pathname);
      sessionStorage.setItem(DEEP_LINKING_CURRENT_HASH_ID, hashId);
    } else {
      cleanState();
    }
  }, [location]);

  useEffect(() => {
    if (selectedHash && currentPath) {
      observer?.observe(document.body, observerConfig);
    } else {
      observer?.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHash, currentPath, observerConfig]);

  useEffect(() => {
    if (selectedModule && selectedHash && currentPath) {
      // If the module and the hash exists, scroll into the module
      const visibleRef = document.getElementById(selectedHash);
      if (visibleRef) visibleRef.scrollIntoView();

      // After scroll, clean the hash and url to avoid send those to analytics
      window.history.replaceState({}, "", currentPath);
      cleanState();
    }
  }, [selectedModule, selectedHash, currentPath]);
};

export default useDeepLink;
