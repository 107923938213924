import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import {
  CASH_OUT_STEPS,
  OCCUPANCY_TYPE,
  PROPERTY_TYPE,
} from "../helpers/constants";
import {
  AsyncStatus,
  CashoutPrequalificationStatus,
  CashOutRefinanceLoanQuote,
  CreateCashOutRefinanceQuoteDetails,
  CreateCashOutRefinanceQuoteResponse,
  CreatePrequalificationProcessResponse,
} from "../types";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Cash Out Loan Quote Slice (Endpoints)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const postCashOutLoanQuote = dashApi
  .path("/cash-out/v1/quote")
  .method("post")
  .create();

export const getCashOutPreQualification = dashApi
  .path("/cash-out/v2/prequalification")
  .method("get")
  .create();

export const postCashOutPreQualification = dashApi
  .path("/cash-out/v2/prequalification")
  .method("post")
  .create();

export const patchCashOutPreQualification = dashApi
  .path("/cash-out/v2/prequalification/{id}")
  .method("patch")
  .create();

export const postFinalCashOutPreQualification = dashApi
  .path("/cash-out/v2/prequalification/{id}")
  .method("post")
  .create({ status: true });

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Cash Out Loan Quote Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|

export interface CashOutQuoteState {
  remaining_balance?: number;
  debt_to_pay_off?: number;
  income?: number;
  expenses?: number;
  credit_score?: number;
  property_type?: PROPERTY_TYPE;
  occupancy_type?: OCCUPANCY_TYPE;
  property_state?: string;
  property_county?: string;
  property_value?: number;
  email?: string;
}

export interface CashOutUpdateQuoteState extends CashOutQuoteState {
  id: string;
}

export interface CashOutQuoteFinalState {
  id: string;
  status: CashoutPrequalificationStatus;
}

export interface CashOutFlowState {
  isPayOffDebt?: boolean;
  isPrefilled?: boolean;
  isPreQualificationPrefilled?: boolean;
  prefilledSteps?: CASH_OUT_STEPS[];
}

export interface CashOutLoanState {
  loan: CreateCashOutRefinanceQuoteResponse | undefined;
  status: AsyncStatus;
  initialized: boolean;
}

export interface CashOutPreQualificationState {
  preQualification: CashOutRefinanceLoanQuote | undefined;
  preQualificationResponse: CreatePrequalificationProcessResponse | undefined;
  preQualificationDetails: CreateCashOutRefinanceQuoteDetails | undefined;
  status: AsyncStatus;
  initialized: boolean;
}

const initialQuoteState: CashOutQuoteState = {};
const initialFlowState: CashOutFlowState = {
  prefilledSteps: [],
};
const initialLoanState: CashOutLoanState = {
  loan: undefined,
  status: "idle",
  initialized: false,
};

const initialPreQualificationState: CashOutPreQualificationState = {
  preQualification: undefined,
  preQualificationResponse: undefined,
  preQualificationDetails: undefined,
  status: "idle",
  initialized: false,
};

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Create Loan
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const createCashOutLoanQuote = createAsyncThunk(
  "cashOutLoanQuote/createLoan",
  async function (props: CashOutQuoteState) {
    const response = await postCashOutLoanQuote(props);
    return response.data;
  }
);

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Get CashOut Pre Qualification
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const loadCashOutPreQualification = createAsyncThunk(
  "cashOutLoanQuote/getPreQualification",
  async function () {
    try {
      const response = await getCashOutPreQualification({});
      return response.data;
    } catch (err) {
      if (err.status === 404) {
        return undefined;
      }
      throw new Error(err);
    }
  }
);

export const createCashOutPreQualification = createAsyncThunk(
  "cashOutLoanQuote/createPreQualification",
  async function (props: CashOutQuoteState) {
    const response = await postCashOutPreQualification(props);
    return response.data;
  }
);

export const updateCashOutPreQualification = createAsyncThunk(
  "cashOutLoanQuote/updatePreQualification",
  async function (props: CashOutUpdateQuoteState) {
    const response = await patchCashOutPreQualification(props);
    return response.data;
  }
);

export const updateFinalCashOutPreQualification = createAsyncThunk(
  "cashOutLoanQuote/updateFinalPreQualification",
  async function (props: CashOutQuoteFinalState) {
    const response = await postFinalCashOutPreQualification(props);
    return response.data;
  }
);

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Slices
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const cashOutQuoteSlice = createSlice({
  name: "cashOutQuote",
  initialState: initialQuoteState,
  reducers: {
    updateCashOutQuote(state, action: PayloadAction<CashOutQuoteState>) {
      const payload = action.payload as Partial<CashOutQuoteState>;
      state.remaining_balance = payload.remaining_balance;
      state.property_value = payload.property_value;
      state.debt_to_pay_off = payload.debt_to_pay_off;
      state.income = payload.income;
      state.expenses = payload.expenses;
      state.credit_score = payload.credit_score;
      state.property_type = payload.property_type;
      state.occupancy_type = payload.occupancy_type;
      state.property_state = payload.property_state;
      state.property_county = payload.property_county;
      state.email = payload.email;
    },
  },
});

export const cashOutFlowSlice = createSlice({
  name: "cashOutFlow",
  initialState: initialFlowState,
  reducers: {
    updateCashOutFlow(state, action: PayloadAction<CashOutFlowState>) {
      const payload = action.payload as Partial<CashOutFlowState>;
      state.isPayOffDebt = payload.isPayOffDebt;
      state.isPrefilled = payload.isPrefilled;
      state.isPreQualificationPrefilled = payload.isPreQualificationPrefilled;
      state.prefilledSteps = payload.prefilledSteps;
    },
  },
});

export const cashOutLoanSlice = createSlice({
  name: "cashOutLoan",
  initialState: initialLoanState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCashOutLoanQuote.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(createCashOutLoanQuote.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        state.loan = action.payload as CreateCashOutRefinanceQuoteResponse;
      })
      .addCase(createCashOutLoanQuote.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.loan = undefined;
        }
      })

      .addCase(updateFinalCashOutPreQualification.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(
        updateFinalCashOutPreQualification.fulfilled,
        (state, action) => {
          state.status = "loaded";
          state.initialized = true;
          state.loan = action.payload as CreateCashOutRefinanceQuoteResponse;
        }
      )
      .addCase(updateFinalCashOutPreQualification.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.loan = undefined;
        }
      });
  },
});

export const cashOutPreQualificationSlice = createSlice({
  name: "cashOutPreQualification",
  initialState: initialPreQualificationState,
  reducers: {
    updateCashOutPreQualificationFlow(
      state,
      action: PayloadAction<CreatePrequalificationProcessResponse>
    ) {
      state.preQualificationResponse =
        action.payload as Partial<CreatePrequalificationProcessResponse>;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCashOutPreQualification.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(loadCashOutPreQualification.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        state.preQualification = action.payload as CashOutRefinanceLoanQuote;
      })
      .addCase(loadCashOutPreQualification.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.preQualification = undefined;
        }
      })

      .addCase(createCashOutPreQualification.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(createCashOutPreQualification.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        state.preQualificationResponse =
          action.payload as CreatePrequalificationProcessResponse;
      })
      .addCase(createCashOutPreQualification.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.preQualificationResponse = undefined;
        }
      })

      .addCase(updateCashOutPreQualification.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(updateCashOutPreQualification.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        state.preQualificationDetails =
          action.payload as CreateCashOutRefinanceQuoteDetails;
      })
      .addCase(updateCashOutPreQualification.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.preQualificationDetails = undefined;
        }
      });
  },
});

export const { updateCashOutQuote } = cashOutQuoteSlice.actions;
export const { updateCashOutFlow } = cashOutFlowSlice.actions;
export const { updateCashOutPreQualificationFlow } =
  cashOutPreQualificationSlice.actions;
