import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getFormValues,
  handleValidation,
} from "../../../helpers/form-validation";
import {
  ACCOUNT_TYPES,
  AFBA_VERSION,
  DIGITAL_ACCOUNT_VERSIONS,
  TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME,
  TERMS_CONDITIONS_VERSION,
  USER_DISCLAIMERS,
} from "../../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../stores";
import { useSearchParams } from "react-router-dom";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { createSSOUser } from "../../../stores/sso-user-slice";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import AccountCreatedModal from "./account-created-modal";
import AcceptTCModal from "./accept-tc-modal";
import DeclinedTCConfirmationModal from "./declined-tc-confirmation-modal";
import AcceptAfBAModal from "./accept-afba-modal";
import { CreateSSOAccountDetails, User } from "../../../types";
import { updateUser } from "../../../stores/user-slice";
import { isMajorVersionDifferent } from "../../../helpers/utils";
import { pages } from "../../../helpers/pages";

interface Props {
  module?: string;
  accountCreatedModule?: string;
  password?: string;
  isUpdatedTermsAndConditions?: boolean;
  isUncompletedProfile?: boolean;
  showOnlyAfBA?: boolean;
  closeOnlyAfBa?: () => void;
  acceptOnlyAfBa?: () => void;
  onCloseTermsModal?: () => void;
  onCloseAccountCreation?: () => void;
  onSubmitAccountCreation?: () => void;
  shouldClearModalState?: boolean;
}

/** @todo we could probably add page property here on click */
const TermsAndConditionsModal = ({
  module = "sso-terms-conditions",
  accountCreatedModule = "sso-account-creation-success",
  password,
  isUpdatedTermsAndConditions,
  isUncompletedProfile,
  showOnlyAfBA = false,
  closeOnlyAfBa,
  acceptOnlyAfBa,
  onCloseTermsModal,
  onCloseAccountCreation,
  onSubmitAccountCreation,
  shouldClearModalState,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const {
    user: userState,
    ssoUser: ssoUserState,
    tavant: { isTavantProcess },
  } = useSelector((state: RootState) => state);
  const { dismissAfBAModal } = useSSOValues();

  const [showAcceptTCModal, setShowAcceptTCModal] = useState<boolean>(
    !!isUpdatedTermsAndConditions
  );
  const [showDeclinedTCConfirmationModal, setShowDeclinedTCConfirmationModal] =
    useState<boolean>(false);
  const [showAcceptAfBAModal, setShowAcceptAfBAModal] =
    useState<boolean>(false);
  // this termsData state is only really here for legacy reasons; not used to save data to the API
  const [termsData, setTermsData] = useState({
    agreement_terms: false,
    credit_reports: false,
    electronic_signatures: false,
    marketing_telephone_communication: false,
  });

  const handleLakeviewTerms = (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: boolean
  ) => {
    setTermsData((prev) => ({
      ...prev,
      [e.target.name]: !currentValue,
    }));
  };

  const { isPendingFastlaneAccount } = useSSOValues();

  const { user, updateState } = userState;
  const ssoUserStatus = ssoUserState.status;
  const isUserAfBAVersionOutdated = isMajorVersionDifferent(
    AFBA_VERSION,
    user?.disclaimer_acceptances?.AFBA?.version
  );

  const isIdling = ssoUserStatus === "idle" || updateState === "idle";
  const hasFailed = ssoUserStatus === "failed" || updateState === "failed";
  const isLoading = ssoUserStatus === "loading" || updateState === "loading";
  const isModalOpen =
    searchParams.get(TERMS_CONDITIONS_OPEN_QUERY_PARAM_NAME) === "true";

  const showTermsModal =
    (isPendingFastlaneAccount && isModalOpen && !!password) ||
    showAcceptTCModal;

  const showCreatedAccountModal = ssoUserState.showCreatedAccountModal;

  const accountType = useMemo(
    () =>
      !!user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS
        ?.version
        ? ACCOUNT_TYPES.DIGITAL_ACCOUNT
        : ACCOUNT_TYPES.FULL_ACCOUNT,

    [user]
  );

  const handleCloseTermsModal = useCallback(() => {
    if (isModalOpen) {
      setSearchParams({});
    }
  }, [setSearchParams, isModalOpen]);

  // after the update or create request is fulfilled we remove the query param
  useEffect(() => {
    if (!ssoUserState.showTCModal) {
      handleCloseTermsModal();
    }
  }, [ssoUserState.showTCModal, handleCloseTermsModal]);

  // if account is created we update the user and close the modal
  useEffect(() => {
    if (showCreatedAccountModal) {
      handleCloseTermsModal();
    }
  }, [showCreatedAccountModal, handleCloseTermsModal]);

  useEffect(() => {
    if (showTermsModal) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: module,
      });
    }
  }, [showTermsModal, module]);

  useEffect(() => {
    setShowAcceptAfBAModal(!!showOnlyAfBA);
    if (showOnlyAfBA) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "afba-conditions",
      });
    }
  }, [showOnlyAfBA]);

  const handleDismissModal = () => {
    setShowAcceptTCModal(false);
    handleCloseTermsModal();
    setShowDeclinedTCConfirmationModal(true);
  };

  /**
   * Considering the account type, build a T&C object that uses the
   * disclaimer_acceptances structure
   * @param {string | undefined} marketingComms
   */
  const constructTermsObject = (marketingComms: string | undefined) => {
    let termsData: Partial<User> = {};

    // pull the optional field from marketingTelephoneComms
    if (accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT) {
      termsData = {
        disclaimer_acceptances: {
          [USER_DISCLAIMERS.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS]: {
            version: DIGITAL_ACCOUNT_VERSIONS.TERMS_AND_CONDITIONS,
          },
          [USER_DISCLAIMERS.DIGITAL_ACCOUNT_USE_ELECTRONIC_RECORDS]: {
            version: DIGITAL_ACCOUNT_VERSIONS.USE_ELECTRONIC_RECORDS,
          },
        },
      };

      if (marketingComms) {
        termsData!.disclaimer_acceptances![
          USER_DISCLAIMERS.DIGITAL_ACCOUNT_TELEPHONE_COMMUNICATIONS
        ] = {
          version: DIGITAL_ACCOUNT_VERSIONS.TELEPHONE_COMMUNICATIONS,
        };
      }
    } else {
      // full account terms
      termsData = {
        disclaimer_acceptances: {
          [USER_DISCLAIMERS.TERMS_AND_CONDITIONS]: {
            version: TERMS_CONDITIONS_VERSION,
          },
          [USER_DISCLAIMERS.USE_ELECTRONIC_SIGNATURES]: {
            version: TERMS_CONDITIONS_VERSION,
          },
          [USER_DISCLAIMERS.OBTAIN_CREDIT_REPORTS]: {
            version: TERMS_CONDITIONS_VERSION,
          },
        },
      };

      if (marketingComms) {
        /**
         * @note this field is currently not part of disclaimer_acceptances
         * structure, and there are no plans to move it */
        termsData.marketing_telephone_communication = true;
      }
    }

    return termsData;
  };

  const handleCreateSSOUser = () => {
    /**
     * @todo CreateSSOAccountDetails does not
     * support disclaimer_acceptances; backend task in BE-1485
     */
    const data: Partial<CreateSSOAccountDetails> = {
      password,
      terms_version: TERMS_CONDITIONS_VERSION,
      marketing_telephone_communication:
        termsData.marketing_telephone_communication,
      marketing_communication: true,
      use_electronic_signatures_version: TERMS_CONDITIONS_VERSION,
      obtain_credit_reports_version: TERMS_CONDITIONS_VERSION,
    };

    /** @todo will need to modify the mixpanel event here, check with Kelsey */
    mixpanel.track(MIXPANEL_EVENTS.FORM_SUBMIT, {
      module: module,
      fields: {
        terms_version: TERMS_CONDITIONS_VERSION,
        marketing_telephone_communication:
          termsData.marketing_telephone_communication,
        use_electronic_signatures_version: TERMS_CONDITIONS_VERSION,
        obtain_credit_reports_version: TERMS_CONDITIONS_VERSION,
      },
    });

    dispatch(
      createSSOUser({
        ...data,
      })
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const isFormValid = handleValidation(e);

    if (!isFormValid) {
      return;
    }

    let marketingComms: string | undefined;

    if (accountType === ACCOUNT_TYPES.DIGITAL_ACCOUNT) {
      const formData = getFormValues<{
        terms_and_conditions: string;
        use_electronic_records: string;
        telephone_communications?: string;
      }>(e);

      marketingComms = formData.telephone_communications;
    } else {
      const formData = getFormValues<{
        agreement_terms: string;
        electronic_signatures: string;
        credit_reports: string;
        marketing_telephone_communication?: string;
      }>(e);

      marketingComms = formData.marketing_telephone_communication;
    }

    setShowAcceptTCModal(false);
    createOrUpdateUser(marketingComms);
    onSubmitAccountCreation?.();
  };

  // Update the user's terms and conditions version
  const createOrUpdateUser = (marketingComms: string | undefined) => {
    // Only proceed if the button hasn't been clicked before (isIdling) or if the previous attempt failed (hasFailed)
    // This prevents unnecessary API calls and ensures we only create/update when needed
    if (!isIdling && !hasFailed) {
      return;
    }

    if (isUncompletedProfile) {
      handleCreateSSOUser();
      return;
    }

    const data: Partial<User> = constructTermsObject(marketingComms);
    dispatch(updateUser(data));
  };

  // Go back confirmation button logic
  const handleGoBackTCConfirmation = () => {
    setShowDeclinedTCConfirmationModal(false);
    setShowAcceptTCModal(true);
  };

  const handleExitProfileSetup = () => {
    setShowDeclinedTCConfirmationModal(false);
    handleCloseTermsModal();
    onCloseTermsModal?.();

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: module,
      button: "close",
    });
  };

  const handleCloseAfBA = () => {
    dismissAfBAModal();
    setShowAcceptAfBAModal(false);
    if (showOnlyAfBA) {
      closeOnlyAfBa && closeOnlyAfBa();
    }
  };

  const handleAcceptAfBA = () => {
    const data: Partial<User> = {
      disclaimer_acceptances: {
        ...user?.disclaimer_acceptances,
        AFBA: { version: AFBA_VERSION },
      },
    };

    dispatch(updateUser(data));
    setShowAcceptAfBAModal(false);
    acceptOnlyAfBa?.();
  };

  const handleShowAfBAModal = () => {
    if (isUserAfBAVersionOutdated && !isTavantProcess) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "afba-conditions",
      });
      setShowAcceptAfBAModal(true);
    }

    onCloseAccountCreation?.();
  };

  return (
    <>
      <AccountCreatedModal
        show={showCreatedAccountModal}
        module={accountCreatedModule}
        onClose={handleShowAfBAModal}
      />
      {!showOnlyAfBA && (
        <AcceptTCModal
          show={showTermsModal}
          onClose={handleDismissModal}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          isFailure={hasFailed}
          termsData={termsData}
          handleChange={handleLakeviewTerms}
          isUpdatedTermsAndConditions={isUpdatedTermsAndConditions}
          accountType={accountType}
        />
      )}
      <AcceptAfBAModal
        show={showAcceptAfBAModal}
        onCancel={handleCloseAfBA}
        onAccept={handleAcceptAfBA}
        isLoading={isLoading}
        shouldClearModalState={shouldClearModalState}
        eventParams={{
          path: pages.home,
          link: pages.home,
          view_source: module,
        }}
      />
      <DeclinedTCConfirmationModal
        show={showDeclinedTCConfirmationModal}
        onGoBack={handleGoBackTCConfirmation}
        isUncompletedProfile={isUncompletedProfile}
        onExitProfileSetup={handleExitProfileSetup}
      />
    </>
  );
};

export default TermsAndConditionsModal;
