// Include our external dependencies.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import { Goal, AsyncStatus } from "../types";

// Shortcut(s) to our API functions
const getGoals = dashApi.path("/goals").method("get").create();
const postGoals = dashApi.path("/goals").method("post").create();
const patchGoals = dashApi.path("/goals").method("patch").create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Personal Goals Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export interface PersonalGoalsState {
  goals: Array<Goal> | undefined;
  status: AsyncStatus | "saving";
}
const initialState: PersonalGoalsState = {
  goals: undefined,
  status: "idle",
};

// Load Goals from the API
export const loadGoals = createAsyncThunk("goals/loadGoals", async function () {
  const response = await getGoals({});
  return response.data.data;
});

// Create Goals
export const createGoal = createAsyncThunk(
  "goals/createGoal",
  async function (props: Goal) {
    const response = await postGoals(props);
    return response.data;
  }
);

// Update Goals
export const updateGoal = createAsyncThunk(
  "goals/updateGoal",
  async function (props: Partial<Goal>) {
    await patchGoals({ ...props }).catch((err) => {
      throw err;
    });

    // Return the content that was used in the patch as part of the payload to the reducer
    return props;
  }
);

export const personalGoalsSlice = createSlice({
  name: "personalGoals",
  initialState,
  reducers: {
    clearGoals(state) {
      state.goals = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // loadGoals
      .addCase(loadGoals.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadGoals.fulfilled, (state, action) => {
        state.status = "loaded";
        state.goals = action.payload;
      })
      .addCase(loadGoals.rejected, (state) => {
        state.status = "failed";
        state.goals = undefined;
      })
      // createGoal
      .addCase(createGoal.pending, (state) => {
        state.status = "saving";
      })
      .addCase(createGoal.fulfilled, (state) => {
        state.status = "loaded";
      })
      .addCase(createGoal.rejected, (state) => {
        state.status = "failed";
      })
      // updateGoal
      .addCase(updateGoal.pending, (state) => {
        state.status = "saving";
      })
      .addCase(updateGoal.fulfilled, (state, action) => {
        state.status = "loaded";

        // in place update of the goal with the information used for the patch
        if (state.goals) {
          const goalIndex = state.goals.findIndex(
            (elem) => elem.id === action.payload.id
          );

          if (goalIndex !== -1) {
            state.goals[goalIndex] = {
              ...state.goals[goalIndex],
              ...action.payload,
            };
          }
        }
      })
      .addCase(updateGoal.rejected, (state) => {
        state.status = "failed";
      });
  },
});
