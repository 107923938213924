import React, { useEffect, useState } from "react";
import { translate as t } from "../../../helpers/i18n";
import { useDispatch } from "react-redux";
import { DISCLOSURES, DisclosureTypeKey } from "./disclosures-content";
import { AppDispatch } from "../../../stores";
import {
  disableDisclosureById,
  enableDisclosure,
} from "../../../stores/disclosure-slice";
import { generateId } from "../../../helpers/utils";
import { cx } from "class-variance-authority";

type Props = {
  disclosureType: DisclosureTypeKey;
  isHeader?: boolean;
  className?: string;
  isModal?: boolean;
  onClick?: () => void;
};

/** Builds the ID used for the footnote backlink */
const buildBackLinkId = (disclosureType: DisclosureTypeKey) =>
  `${disclosureType}_REF`;

const DisclosureLink = ({
  disclosureType,
  isHeader,
  className,
  isModal = false,
  onClick,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [linkId, setLinkId] = useState<string>();
  const disclosure = DISCLOSURES[disclosureType];

  /**
   * When the link is first rendered:
   * * The corresponding disclosure footnote is rendered as well.
   * * Generate an unique reference id to prevent invalid html with repeated links
   * * The disclosure gets removed if the component unmounts
   */
  useEffect(() => {
    if (disclosure) {
      if (!isModal) {
        // Load corresponding disclosure footnote
        dispatch(enableDisclosure(disclosureType));
      }

      // Set unique link Id
      const initialLinkId = buildBackLinkId(disclosureType);
      setLinkId(`${initialLinkId}_${generateId()}`);
    }

    return () => {
      if (disclosure && !isModal) {
        dispatch(disableDisclosureById(disclosureType));
      }
    };
  }, [disclosureType, disclosure, dispatch, isModal]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    const refs = document.querySelectorAll<HTMLElement>(
      `[id=${disclosure?.href}]`
    );
    const visibleRef = Array.from(refs).find(
      (element) => element.offsetParent !== null
    );

    if (visibleRef) visibleRef.scrollIntoView();

    onClick?.();
  };

  return (
    disclosure && (
      <sup>
        <a
          id={linkId}
          aria-describedby="disclosure-content"
          href={`#${disclosure.href}`}
          className={cx("disclosure-link", className)}
          onClick={handleClick}
        >
          {isHeader ? disclosure.symbol : `[${disclosure.symbol}]`}
        </a>
      </sup>
    )
  );
};

/**
 * Backlink to the first visible disclosure link
 */
export const BackLink = ({
  disclosureType,
}: {
  disclosureType: DisclosureTypeKey;
}) => {
  const disclosure = DISCLOSURES[disclosureType];
  const backLinkId = buildBackLinkId(disclosureType);

  const handleClick = (
    event: React.MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault();
    const refs = document.querySelectorAll<HTMLElement>(`[id*=${backLinkId}]`);
    const visibleRef = Array.from(refs).find(
      (element) => element.offsetParent !== null
    );

    if (visibleRef) visibleRef.scrollIntoView();
  };

  return (
    disclosure && (
      <sup>
        <a
          id={`${disclosure.href}`}
          aria-labelledby={t("accessibility.back_to_content")}
          href={`#${backLinkId}`}
          onClick={handleClick}
          className="disclosure-link"
        >
          [{disclosure.symbol}]
        </a>
      </sup>
    )
  );
};

export default DisclosureLink;
