/**
 * Create User Slice
 *
 * Handles the status and response for the create user endpoint
 **/
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import {
  AsyncStatus,
  FullAccountCreationRequest,
  DigitalAccountCreationRequest,
  User,
} from "../types";
import { RootState } from ".";

const saveUser = dashApi.path("/user").method("post").create();

interface CreateUserState {
  user: User | undefined;
  status: AsyncStatus;
}

const initialState: CreateUserState = {
  user: undefined,
  status: "idle",
};

/**
 * Create user async thunk
 */
export const createUser = createAsyncThunk(
  "user/createUser",
  async function (
    createAccountParams:
      | FullAccountCreationRequest
      | DigitalAccountCreationRequest
  ) {
    const response = await saveUser(createAccountParams);

    return response.data.data;
  }
);

/**
 * Create user async thunk
 */
export const createDigitalAccountUser = createAsyncThunk(
  "user/createDigitalAccountUser",
  async function (
    createAccountParams: DigitalAccountCreationRequest,
    thunkAPI
  ) {
    const state = thunkAPI.getState() as RootState;
    const nonce = state.DACUserFlow.nonce as string;
    const response = await saveUser(createAccountParams, {
      headers: { nonce },
    });

    return response.data.data;
  }
);

export const createUserSlice = createSlice({
  name: "createUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = "loaded";
        state.user = action.payload;
      })
      .addCase(createUser.rejected, (state) => {
        state.status = "failed";
        state.user = undefined;
      })
      .addCase(createDigitalAccountUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createDigitalAccountUser.fulfilled, (state, action) => {
        state.status = "loaded";
        state.user = action.payload;
      })
      .addCase(createDigitalAccountUser.rejected, (state) => {
        state.status = "failed";
        state.user = undefined;
      });
  },
});
