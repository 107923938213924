import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../stores";
import { useMultipleLoanAccounts } from "./use-multi-loan-accounts";

export const useEstimatedEquity = () => {
  const { account: accountState, property: propertyState } = useSelector(
    (state: RootState) => state
  );

  const { property } = propertyState;
  const currentPropertyValue = property?.current_value || 0;

  const accounts = useMultipleLoanAccounts();
  const isLoading =
    propertyState.status === "loading" || accountState.status === "loading";

  /**
   * This formula displays the estimated equity value,
   * which is calculated by subtracting the sum of the
   * principal balances of all the loans associated with the property
   * from the current home value
   * */
  const estimatedEquity: number = useMemo(() => {
    if (isLoading) {
      return 0;
    }

    const totalPrincipleBalance =
      accounts?.reduce((totalPrincipal, account) => {
        const principalBalance = account?.principal_balance || 0;
        return totalPrincipal + principalBalance;
      }, 0) || 0;

    return currentPropertyValue - totalPrincipleBalance;
  }, [isLoading, currentPropertyValue, accounts]);

  return {
    estimatedEquity,
  };
};
