import LPbF from "../../atoms/lpbf";

const PrivacyPolicy = () => (
  <>
    <p className="mb-s24">
      <b>
        <LPbF /> Dashboard Privacy Policy
      </b>
    </p>
    <p className="mb-s24">
      <b>Overview</b>
    </p>
    <p className="mb-4">
      This Privacy Policy explains how Lakeview Loan Servicing, LLC
      (“Lakeview”), and its affiliates, subsidiaries, and service providers,
      including Fastlane Technology Solutions, LLC (“Fastlane”), (referred to
      collectively, as “We”, “Us” and “Our”), collects, uses, protects, and
      discloses nonpublic personal identifying information about consumers when
      you use Our <LPbF /> Dashboard, Website and/or Products or Services from
      Us. It also explains any rights you may have about these practices.
    </p>
    <p className="mb-4">
      By creating and/or using your <LPbF /> Dashboard, you consent to this
      Policy, which includes your consent to disclose and use information about
      you in the manner detailed in this Policy. Depending upon your
      relationship with us, you may receive other Privacy Notices providing
      additional details about our privacy practices.
    </p>
    <p className="mb-4">
      Through Our <LPbF /> Dashboard, Website and/or Products or Services, we
      present financial products, financial education, financial information,
      and services, which includes, but not limited to connecting you with other
      service providers. Likewise, this policy applies to any Product or Service
      presented through our <LPbF /> Dashboard or Website that may be provided
      by Us, a third-party originating bank, or another third party. Any
      financial Products or Services are subject to federal and state laws
      regarding privacy. In addition to regulating how information in connection
      with a financial Product or Service is managed, federal and state laws
      require that that we, and other parties that present financial Products
      and Services, disclose to you our privacy practices concerning your
      information. Please see the section titled “Federal Privacy Notice” for
      Our privacy disclosures under federal law and carefully review the notice.
    </p>
    <p className="mb-4">
      We may update this Privacy Policy, including the Federal Privacy Notice,
      from time to time. Any changes We make in the future will be posted on our{" "}
      <LPbF /> Dashboard or Website. We will also notify you of any changes to
      the extent required by applicable law. Please check back frequently to see
      any updates or changes.
    </p>
    <p className="mb-4">
      In the event that you need to contact Lakeview about this Privacy Policy
      or Our privacy practices, please contact Us at
      fastlanesupport@lakeview.com.
    </p>
    <p className="mb-4">
      The effective date of this Privacy Policy is September 2024.
    </p>
    <h6 className="mt-5 pt-2">Information Collection and Sharing Activities</h6>
    <p className="mb-3">
      <span className="fw-bold">
        What are the types of personal information we collect?
      </span>{" "}
      We do not collect personally identifiable information from visitors to our
      Website(s) unless visitors voluntarily and knowingly provide Us with that
      information. We may collect personal information when you create a{" "}
      <LPbF /> Dashboard profile or enter data into an application for Products
      or Services while using our Dashboard or Website. The following are
      examples of the types of personal information and other information we may
      collect about you:
    </p>
    <ul className="mb-4">
      <li>
        Your name and identifying information, such as your date of birth and
        social security number;
      </li>
      <li>
        Your contact information, such as your address, email address, and
        telephone number;
      </li>
      <li>
        Financial and financial-related information, such as your income,
        employer, mortgage history and the amount of your mortgage, credit
        history;
      </li>
      <li>Other information about your mortgage;</li>
      <li>
        Information about your home or property, such as your address or
        location and property value;
      </li>
    </ul>
    <p className="mb-3">
      As part of providing a <LPbF /> Dashboard to you, we may obtain
      information from our customers and Website visitors from the following
      sources:
    </p>
    <ul className="mb-4">
      <li>
        Information We receive from you on applications, emails, or other forms;
      </li>
      <li>Any current Lakeview loan account number(s), as applicable;</li>
      <li>Information about your transactions with Lakeview;</li>
      <li>Information we receive from a consumer-reporting agency; and</li>
      <li>
        Information that is generated electronically when you visit Our Lakeview
        Website or use our <LPbF /> Dashboard.
      </li>
    </ul>
    <p className="mb-3">
      Service Providers in relationship to Lakeview Website <LPbF /> Dashboard
      may collect general information and electronic data on our Website
      visitors and Dashboard users for security and statistical purposes. This
      information may include:
    </p>
    <ul className="mb-4">
      <li>
        The internet address (referral site) which brought you to our Website;
      </li>
      <li>The date and time you access our Website;</li>
      <li>The name and version of your web browser or device identifier;</li>
      <li>Your geolocation;</li>
      <li>
        Technical information in connection with your use of the Lakeview
        Dashboard or Website used to connect your device to the internet
        address, browser type and version, and operating system;
      </li>
      <li>
        The pages visited on our Lakeview Dashboard or Website, and other
        website interactions and/or preferences; and
      </li>
      <li>The duration of your online sessions.</li>
      <li>
        Technical information in connection with your visit to our website or
        use of our mobile application, such as your device ID, the Internet
        Protocol (“IP”) address used to connect your computer to the internet
        address, browser type and version, and operating system; and
      </li>
      <li>
        Information about your visit and your behavior on our website and mobile
        application, such as the pages that you visited and your preferences.
      </li>
    </ul>
    <p className="mb-3">
      <span className="fw-bold">
        What are the sources from which we collect personal information about
        you?
      </span>{" "}
      We collect personal information and other information about you from a
      number of sources, including:
    </p>
    <ul className="mb-4">
      <li>
        Lakeview Loan Servicing, LLC (“Lakeview”), Our affiliates, including
        Lakeview Household Insurance Solutions, LLC (“LHIS”), LKV Realty, LLC or
        Our affiliates’ service providers;
      </li>
      <li>
        When you give Us your personal information in order to receive Products,
        Services, or information, such as when you register for a Dashboard
        profile, or contact us by phone, email or other means;
      </li>
      <li>
        If applicable, any personal information already collected as a result of
        a Lakeview loan already in existence;
      </li>
      <li>
        Third parties, such as credit reporting agencies, identity verification
        service providers; and home valuation companies; and
      </li>
      <li>
        Cookies, web beacons, mobile application plug-ins, or other similar
        technologies.
      </li>
    </ul>
    <p className="mb-4">
      <span className="fw-bold">
        How do we use the information we collect about you?
      </span>{" "}
      We use the information collected about you in a number of ways, including
      but not limited to:
    </p>
    <ul className="mb-4">
      <li>Provide you with information about your mortgage and your home;</li>
      <li>
        Provide you with information about Products and Services that we think
        may be of interest to you that are offered by either of Us, Our
        affiliates, or tools provided by third parties;
      </li>
      <li>Provide with, renew, or modify Products and Services;</li>
      <li>Provide information about online services or required notices;</li>
      <li>
        Provide you with the information and documents you request and for other
        customer service purposes;
      </li>
      <li>Engage in marketing, market research, and business development;</li>
      <li>
        Process your requests for information or another product or service
        offered through Our Website or Dashboard;
      </li>
      <li>
        Service or Products you obtain through Our Website <LPbF /> Dashboard or
        that you otherwise obtain from Us or one of Our affiliates;
      </li>
      <li>Communicate with you;</li>
      <li>To perform research;</li>
      <li>Provide you customer support and respond to your inquiries;</li>
      <li>
        Detect, investigate and prevent potentially prohibited or illegal
        activities, such as fraud;
      </li>
      <li>Engage in risk profiling and other data analytics;</li>
      <li>
        Operate, improve, maintain, and ensure the security of Our Dashboard,
        Website and/or Products or Services;
      </li>
      <li>Comply with laws and regulations;</li>
      <li>
        Exercise or defend our legal rights or the legal rights of others; and
      </li>
      <li>
        Use in connection with any other legitimate business purpose permitted
        by applicable law.
      </li>
    </ul>
    <p className="mb-4">
      We may also anonymize, combine or aggregate any of the information we
      collect through our Website(s) and/or <LPbF /> Dashboard or elsewhere for
      any of these purposes or for analyzing usage statistics and trends.
    </p>
    <p className="mb-4">
      <span className="fw-bold">
        Who do we share information about you with?
      </span>{" "}
      We may share the personal information and other information we collect
      about you with:
    </p>
    <ul>
      <li>Our affiliates;</li>
      <li>Service providers;</li>
      <li>Third parties who provide Products and Services;</li>
      <li>Credit reporting agencies;</li>
      <li>
        Other companies in connection with a merger, acquisition, or the sale of
        assets;
      </li>
      <li>
        Third parties, including governmental authorities and law enforcement,
        to protect or defend our legal rights or the legal rights of others,
        protect against fraud; and comply with applicable law, subpoena, or
        legal process;
      </li>
      <li>Third parties in an anonymized and aggregated manner; and</li>
      <li>
        Other third parties for any business purpose permitted by applicable
        law.
      </li>
    </ul>
    <h6 className="mt-5 pt-2">Cookies and Do Not Track Signals</h6>
    <p className="mb-4">
      <span className="fw-bold">Cookies.</span> Cookies are pieces of
      information stored directly on the computer or mobile application you are
      using. We or our service providers may place cookies, or similar files, on
      your device, including for security purposes, to facilitate navigation,
      and to personalize your experience while using the Lakeview Website and{" "}
      <LPbF /> Dashboard. Cookies allow Us to collect technical and navigational
      information, such as browser type and time spent on its respective site or
      mobile application and pages visited. Cookies allow Us to select which
      advertisements or offers are most likely to appeal to you and display them
      to you. We may also use cookies in online advertising to track responses
      and may use cookies or other files to track your use of other websites.
      Cookies and other technologies provide Us with the capability to monitor
      the use of Our website and the <LPbF /> Dashboard so We can continually
      improve the design and functionality to better serve you.
    </p>
    <p className="mb-4">
      When you click on advertisements on Our Website or advertisements on
      linked third party websites, you may receive another Cookie; however, you
      do not have to accept any Cookies from third party advertisements.
    </p>
    <p className="mb-4">
      If you do not accept these cookies, you may experience some inconvenience
      in your use of some online options. We use cookies to display information
      more effectively to you and to gather data about the usage of Our Website
      and <LPbF /> Dashboard.
    </p>
    <p className="mb-4">
      At times, We may also use other technologies to capture customer specific
      data to help understand how customers use Our Website and <LPbF />{" "}
      Dashboard and to assist Us with resolving customers’ questions regarding
      the use of the Website <LPbF /> Dashboard.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Do-Not-Track Signals.</span> Many web browsers
      possess a do-not-track feature that lets your browser tell websites you
      visit that you do not want to have your online activities tracked. At this
      time, <LPbF /> Dashboard does not respond to browser do-not-track signals.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Security.</span> To protect your personal
      information from unauthorized access, we use security and safeguarding
      measures that comply with federal and state law. These measures include
      but are not limited to computer and electronic safeguards and secured
      files and buildings. It may also include access codes for multi-factor
      authentication. While we undertake these security measures, you
      acknowledge that no security measure or modality of data transmission over
      the Internet can be guaranteed to be 100% secure. You should not expect
      that any personal information provided to us through the Lakeview Website
      or <LPbF /> Dashboard is free from the risk posed by unauthorized access.
    </p>
    <p className="mb-4">
      Secure browsers, such as Google Chrome, Mozilla Firefox, Microsoft Edge,
      or Apple Safari are required to access your information as additional
      protection of your Dashboard information. We utilize Secure Socket Layer
      (SSL) and 256-bit encryption technology to protect your confidential
      information. If you are visiting the Lakeview Website or Dashboard, your
      Internet session should be encrypted if your security-enabled browser is
      connected to a website using the Secure Hypertext Transport Protocol. URL
      strings beginning with “HTTPS://” instead of the usual “HTTP://” indicate
      that the secure protocol is in effect. Your internet browser may also show
      an icon indicating that the website is considered secure.
    </p>
    <p className="mb-4">
      You can protect your Dashboard information by signing off your device when
      you have completed your transaction, changing your password frequently,
      and by not sharing any multi-factor authentication access codes with
      anyone. If you forget to sign off, the <LPbF /> Dashboard will sign off
      for you after there has been no activity for a certain period of time.
      Please be aware that if you provide your User ID and Password to anyone,
      you are authorizing them to access your Dashboard. Any transactions that
      may take place in your Dashboard as a result are considered to be
      authorized by you.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Third Party Links.</span> Our Lakeview Website
      or <LPbF /> Dashboard may include links to other third-party websites as a
      courtesy and a convenience to our customers. When you visit these sites,
      you will leave our Website and will be redirected to another site.
      Lakeview makes no representation concerning and is not responsible for the
      quality, content nature, or reliability of any third party linked site.
      The inclusion of any third-party links does not imply any endorsements,
      investigation, verification or monitoring by Lakeview on any third party
      linked site. In no event shall Lakeview be responsible for your use of a
      third-party site. We suggest that you always verify the information
      obtained from third party websites before acting on this information.
      Also, please be aware that the security and privacy policies on these
      sites may be different from our policies, so please read third party
      privacy and security policies closely.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Consent to Receive Email Communications.</span>{" "}
      You consent to receiving emails at any email address you provide to us,
      including marketing-related messages. You may opt out of receiving
      marketing emails by utilizing the “unsubscribe” functionality preferences
      included in those emails.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Changing Your Information.</span> If you would
      like to review and request changes to the personally identifiable
      information that you have provided to us in your interactions with the{" "}
      <LPbF /> Dashboard, please reach out to fastlanesupport@lakeview.com.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Other Websites.</span> At certain places on this
      site, live “links” to other Internet addresses can be accessed (“Linked
      Sites”). Such Linked Websites contain information created, published,
      maintained, or otherwise posted by independent third parties. We do not
      endorse, approve, certify, or control these Linked Websites and do not
      guarantee the accuracy, completeness, efficacy, timeliness or correct
      sequencing of information that they contain. This Privacy Policy does not
      apply to these Linked Sites. The privacy policies of these Linked Sites
      may differ, and you should review their policies before accessing them
      and/or submitting information. We are not responsible for the privacy or
      security of these sites, including the accuracy, completeness,
      reliability, or suitability of their information. If you are asked to
      provide information on one of these websites, you are strongly urged to
      carefully study their privacy policies before sharing your information.
    </p>
    <p className="mb-4">
      <span className="fw-bold">Minors.</span> You must be 18 years of age or
      older to register as an account user and receive services through our
      website and mobile application. Our Website and <LPbF /> Dashboard are not
      directed at people under 18 years of age, and We do not knowingly collect
      any information about such persons.
    </p>
    <p className="mb-4">
      <span className="fw-bold">State Privacy Disclosures</span> Some states
      provide certain rights concerning your personal information unless an
      exception applies. These exceptions include information subject to federal
      privacy laws, specifically the federal Gramm-Leach-Bliley Act (“GLBA”) and
      Fair Credit Reporting Act (“FCRA”), or entities that are subject to the
      GLBA. In addition to these exceptions, state privacy laws do not consider
      information to be personal information if it cannot be reasonably linked
      to any consumer or household. The information that Lakeview or its
      affiliates collect, uses, and shares may be exempt from state privacy laws
      as it is subject to the GLBA, subject to the FCRA, it is not deemed to be
      personal information, or Lakeview is subject to the GLBA.
    </p>
    <p className="mb-4">
      <span className="fw-bold">
        Additional notice to California Residents.
      </span>{" "}
      The California Consumer Privacy Act (“CCPA”) is a law which applies to the
      State of California, who are not currently covered under the GLBA.
      California defines residency as: (1) every individual who is in the State
      for other than a temporary or transitory purpose, and (2) every individual
      who is domiciled in the State who is outside the State for a temporary or
      transitory purpose. All other individuals are nonresidents. Examples of
      domicile include paying taxable income, maintaining a permanent home,
      paying resident tuition, voting in a California election, or any other
      benefit not ordinarily extended to nonresidents.
    </p>
    <p className="mb-4">
      Note that as part of <LPbF /> Dashboard experiences, as well as part of
      servicing consumer requests or consumer accounts, Lakeview may collect
      certain categories of information as noted in the “California Consumer
      Privacy Notice;” however, not all categories of information are likely to
      be collected from or about each individual consumer. The specific
      categories of information collected are dependent on the Lakeview
      service(s) provided.
    </p>
    <p className="mb-4">
      For further information on the categories of information Lakeview
      collects, please visit: lakeview.com.
    </p>
    <p className="mb-4">Questions? Please contact Lakeview in writing at:</p>
    <p className="mb-4">Lakeview Loan Servicing, LLC,</p>
    <p className="mb-4">Attn: Compliance Department</p>
    <p className="mb-4">4425 Ponce De Leon Blvd., MS. 5-251</p>
    <p className="mb-4">Coral Gables, FL 33146</p>
    <p className="mb-4">
      with any questions about the information included in this <LPbF />{" "}
      Dashboard Privacy Policy or questions about our Website’s services.
    </p>
    <p className="mb-4">
      You may access our full Privacy Notice at:
      https://lakeviewloanservicing.fastlanefi.com/terms-and-conditions.
    </p>
  </>
);

export default PrivacyPolicy;
