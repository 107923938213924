// Include our external dependencies.
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import { AsyncStatus, PropertyValueDetails } from "../types";

// Shortcut(s) to our API functions
const getProperty = dashApi
  .path("/property-value/{property_id}")
  .method("get")
  .create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Property Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
interface PropertyState {
  property: PropertyValueDetails | undefined;
  status: AsyncStatus;
  initialized: boolean;
}
const initialState: PropertyState = {
  property: undefined,
  status: "idle",
  initialized: false,
};

// Load Property Details
export const loadProperty = createAsyncThunk(
  "property/loadProperty",
  async function (property_id: string, { fulfillWithValue }) {
    try {
      const response = await getProperty({ property_id });

      return response.data.data;
    } catch (err) {
      if (err.status === 300 || err.status === 404) {
        return fulfillWithValue({ status: err.status });
      }
      throw new Error(err);
    }
  }
);

export const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    clearProperty(state) {
      state.property = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // loadProperty
      .addCase(loadProperty.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(loadProperty.fulfilled, (state, action) => {
        const payload = action.payload;
        // The presence of 'status' in the payload indicates that an 300 or 404 error has ocurred
        if (payload && "status" in payload) {
          state.status = "failed";
          state.property = undefined;
        } else {
          state.status = "loaded";
          state.property = payload as PropertyValueDetails;
          state.initialized = true;
        }
      })
      .addCase(loadProperty.rejected, (state) => {
        // if already initialized and the endpoint fails, keep using the stored data
        if (!state.initialized) {
          state.status = "failed";
          state.property = undefined;
        }
      });
  },
});
