import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashApi from "../helpers/dash-api";
import {
  AsyncStatus,
  CashOutRefinanceLoanQuote,
  CruiseNextStep,
  HeloanNextStep,
} from "../types";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Next Steps Slice (Endpoints)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const getNextSteps = dashApi
  .path("/loans/next-steps")
  .method("get")
  .create();

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Next Steps Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export interface NextStepsState {
  cashOutPreQualification: CashOutRefinanceLoanQuote | undefined;
  cruiseNextSteps: CruiseNextStep[];
  heloanNextSteps: HeloanNextStep | undefined;
  status: AsyncStatus;
  initialized: boolean;
}

const initialNextStepsState: NextStepsState = {
  cashOutPreQualification: undefined,
  cruiseNextSteps: [],
  heloanNextSteps: undefined,
  status: "idle",
  initialized: false,
};

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Get CashOut Pre Qualification
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export const loadNextSteps = createAsyncThunk(
  "nextSteps/getNextSteps",
  async function () {
    try {
      const response = await getNextSteps({});
      return response.data;
    } catch (err) {
      if (err.status === 404) {
        return undefined;
      }
      throw new Error(err);
    }
  }
);

export const nextStepsSlice = createSlice({
  name: "nextSteps",
  initialState: initialNextStepsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadNextSteps.pending, (state) => {
        if (!state.initialized) {
          state.status = "loading";
        }
      })
      .addCase(loadNextSteps.fulfilled, (state, action) => {
        state.status = "loaded";
        state.initialized = true;
        if (action.payload) {
          state.cashOutPreQualification = action.payload
            ?.cash_out_prequalification as CashOutRefinanceLoanQuote;
          state.cruiseNextSteps = action.payload
            ?.cruise_next_steps as CruiseNextStep[];
          state.heloanNextSteps = action.payload
            ?.heloan_next_steps as HeloanNextStep;
        }
      })
      .addCase(loadNextSteps.rejected, (state) => {
        if (!state.initialized) {
          state.status = "failed";
          state.cashOutPreQualification = undefined;
          state.cruiseNextSteps = [];
          state.heloanNextSteps = undefined;
        }
      });
  },
});
