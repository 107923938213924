// Include our external dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generateId } from "../helpers/utils";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Toasts Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export type Status = "error" | "success" | "alert" | "info";

export type Toast = {
  status?: Status;
  content: string;
  id?: string;
};
interface ToastState {
  toasts: Array<Toast>;
}
const initialState: ToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<Toast>) {
      state.toasts = [{ ...action.payload, id: generateId() }];
    },
    hideToast(state, action: PayloadAction<string>) {
      const id = action.payload;
      state.toasts = [...state.toasts.filter((t) => t.id !== id)];
    },
  },
});

// Action creators are generated for each case reducer function
export const { showToast, hideToast } = toastSlice.actions;
