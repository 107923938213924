// Include our external dependencies
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../stores";
import { showToast } from "../stores/toast-slice";
import { translate as t } from "../helpers/i18n";
import { datadogRum } from "@datadog/browser-rum";

const useGlobalErrors = () => {
  // Unpack our global Redux state
  const dispatch = useDispatch<AppDispatch>();

  // Define the global JS error handler - show a toast!
  const handleJSError = useCallback(
    function (error: ErrorEvent) {
      datadogRum.addError({ error });
      dispatch(
        showToast({
          status: "error",
          content: error.message || t("error.regular_message"),
        })
      );
    },
    [dispatch]
  );

  // Define the global Promise error handler - show a toast!
  const handlePromiseError = useCallback(
    function (event: PromiseRejectionEvent) {
      const eventHasErrors = event?.reason?.data?.errors;
      // Show all error details if provided (from API)…
      if (eventHasErrors) {
        const errorList = event.reason.data.errors;
        datadogRum.addError({ error: errorList });

        let finalMessage = "";
        errorList.forEach((e: { detail: string }) =>
          finalMessage.concat(e.detail || t("error.regular_message"))
        );
        dispatch(
          showToast({
            status: "error",
            content: finalMessage || t("error.regular_message"),
          })
        );
      }
      // …otherwise show a generic error message.
      else {
        datadogRum.addError({ error: t("error.regular_message") });

        dispatch(
          showToast({
            status: "error",
            content: t("error.regular_message"),
          })
        );
      }
    },
    [dispatch]
  );

  // Tap into the browser's default error event
  useEffect(() => {
    window.addEventListener("error", handleJSError);
    window.addEventListener("unhandledrejection", handlePromiseError);
    return () => {
      window.removeEventListener("error", handleJSError);
      window.removeEventListener("unhandledrejection", handlePromiseError);
    };
  }, [handleJSError, handlePromiseError]);
};
export default useGlobalErrors;
