/**
 * Retrieve user information on page load or on page navigation
 */
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../stores";
import { checkForUser, refreshUser } from "../stores/user-slice";

const useFetchUserData = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const previousLocation = useRef<string>();

  const user = useSelector((state: RootState) => state.user.user);

  // Retrieve user information on first load
  useEffect(() => {
    dispatch(checkForUser());
  }, [dispatch]);

  // Listen for location changes to refresh the user information
  useEffect(() => {
    // Avoid the call if the user refreshes the page - the initial fetch will trigger instead
    // only refresh if there's if there's already a user
    if (
      !!previousLocation.current &&
      location.pathname !== previousLocation.current &&
      !!user
    ) {
      dispatch(refreshUser());
    }
    previousLocation.current = location.pathname;
  }, [dispatch, location, user]);
};

export default useFetchUserData;
