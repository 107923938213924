/**
 * Returns the Release version or defaults to development in case of local/dev/sandbox environments.
 */
export const getFastlaneVersion = () => {
  return process.env.REACT_APP_UI_VERSION || "Development";
};

/**
 * Prints Fastlane information inside a console
 */
const fastlaneInformation = () => {
  console.info("Fastlane Dashboard version: ", getFastlaneVersion());
};

export default fastlaneInformation;
