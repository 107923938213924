import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LAKEVIEW_URLS } from "../helpers/constants";
import dashApi from "../helpers/dash-api";
import {
  AsyncStatus,
  InsuranceApplication,
  InsuranceApplicationIdLink,
} from "../types";

export const getInsuranceApplication = dashApi
  .path("/insurance/v0/applications")
  .method("get")
  .create();

export const postInsuranceApplication = dashApi
  .path("/insurance/v0/applications")
  .method("post")
  .create();

export const getInsuranceApplicationLink = dashApi
  .path("/insurance/v0/applications/{application-id}/link")
  .method("get")
  .create();

export interface InsuranceApplicationState {
  insuranceApplications: InsuranceApplication[] | undefined;
  insuranceLink: string | undefined;
  status: AsyncStatus;
}

const initialState: InsuranceApplicationState = {
  insuranceApplications: undefined,
  insuranceLink: undefined,
  status: "idle",
};

export const loadInsuranceApplication = createAsyncThunk(
  "insuranceApplication/getInsuranceApplication",
  async function (_, { fulfillWithValue }) {
    try {
      const response = await getInsuranceApplication({});

      return response.data || [];
    } catch (err) {
      //  When a user has never had an insurance application, the backend has established that this error is expected.
      // Therefore, when a 404 error occurs, the frontend should interpret it as an empty array of insurance applications.
      if (err.status === 404) {
        return fulfillWithValue([]);
      }
      throw new Error(err);
    }
  }
);

export const createInsuranceApplication = createAsyncThunk(
  "insuranceApplication/createInsuranceApplication",
  async function (_, { fulfillWithValue }) {
    try {
      const response = await postInsuranceApplication({});
      return response.data;
    } catch (err) {
      // As requested by the BE team, if the status code is 502,
      // the bolt insurance lhis url should be returned
      if (err.status === 502) {
        return fulfillWithValue(LAKEVIEW_URLS.bolt_insurance_lhis);
      }
      throw new Error(err);
    }
  }
);

export const loadInsuranceApplicationLink = createAsyncThunk(
  "insuranceApplication/getInsuranceApplicationLink",
  async function (
    applicationId: InsuranceApplicationIdLink,
    { rejectWithValue }
  ) {
    try {
      const id = applicationId as InsuranceApplicationIdLink;
      const response = await getInsuranceApplicationLink({
        "application-id": id,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);

export const insuranceApplicationSlice = createSlice({
  name: "insuranceApplication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadInsuranceApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadInsuranceApplication.fulfilled, (state, action) => {
        state.status = "loaded";
        state.insuranceApplications = action.payload as InsuranceApplication[];
      })
      .addCase(loadInsuranceApplication.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createInsuranceApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createInsuranceApplication.fulfilled, (state, action) => {
        state.status = "loaded";
        state.insuranceLink = action.payload as string;
      })
      .addCase(createInsuranceApplication.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(loadInsuranceApplicationLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadInsuranceApplicationLink.fulfilled, (state, action) => {
        state.status = "loaded";
        state.insuranceLink = action.payload as string;
      })
      .addCase(loadInsuranceApplicationLink.rejected, (state) => {
        state.status = "failed";
      });
  },
});
