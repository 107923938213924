import React from "react";
import { translate as t } from "../../../helpers/i18n";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { useDashboardVersion } from "../../../hooks";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import PasswordForm from "../../molecules/password-form/password-form";

interface PasswordBoxProps {
  openTermsModal: (password: string) => void;
  onDismiss: () => void;
}

const PasswordBox = ({ openTermsModal, onDismiss }: PasswordBoxProps) => {
  const { user } = useSelector((state: RootState) => state.user);
  const { mixpanelPageName } = useDashboardVersion();

  const handleSubmit = (isFormValid: boolean, password: string) => {
    if (isFormValid) {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: "sso-hero",
        step: 2,
        button: "set-password",
        page: mixpanelPageName,
      });

      openTermsModal(password);
    }
  };

  return (
    <PasswordForm
      email={user?.email as string}
      eyebrow={t("sso_banner.password_box.create_your_password")}
      saveButtonText={t("sso_banner.password_box.save")}
      cancelButtonText={t("sso_banner.password_box.cancel")}
      onSubmit={handleSubmit}
      onDismiss={onDismiss}
    />
  );
};

export default PasswordBox;
