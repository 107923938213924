// Include our external dependencies.
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Banners Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
export type Banner = {
  hed: string;
};
interface BannerState {
  banners: Array<Banner>;
}
const initialState: BannerState = {
  banners: [],
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    showBanner(state, action: PayloadAction<Banner>) {
      state.banners = [...state.banners, action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { showBanner } = bannerSlice.actions;
