/**
 * Session timer
 *
 * Compares the session timestamp present in localstorage against the current time
 * If more than the set session time limit has elapsed since the last timestamp,
 * it triggers a logout.
 */
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { handleLogOut } from "../helpers/auth";
import { getSessionTimestamp, MIXPANEL_EVENTS } from "analytics";
import { RootState } from "../stores";
import mixpanel from "mixpanel-browser";

// How frequently this check has to be done
const SESSION_CHECK_INTERVAL = 1000; // 1 second expressed in ms

type TimerType = ReturnType<typeof setInterval>;

const useSessionTimer = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const timerRef = useRef<TimerType>();

  // Only trigger the timer interval check if the user is logged in
  useEffect(() => {
    if (user && !timerRef.current) {
      const sessionTimerId: TimerType = setInterval(() => {
        const sessionTimestamp = getSessionTimestamp();
        const hasSessionTimeSet =
          !!process.env.REACT_APP_SESSION_TIME_MS &&
          !isNaN(Number(process.env.REACT_APP_SESSION_TIME_MS));
        const hasSessionTimestamp =
          !!sessionTimestamp && !isNaN(Number(sessionTimestamp));

        if (hasSessionTimeSet && hasSessionTimestamp) {
          const timeDifference = Date.now() - Number(sessionTimestamp);

          if (timeDifference >= Number(process.env.REACT_APP_SESSION_TIME_MS)) {
            mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
              module: "session-timeout",
            });
            mixpanel.track(MIXPANEL_EVENTS.SESSION_END);

            // Log out the user if more than the allowed session time passed without any API calls
            handleLogOut();
          }
        } else {
          clearInterval(timerRef.current);
        }
      }, SESSION_CHECK_INTERVAL);
      timerRef.current = sessionTimerId;
    }
  }, [user]);
};

export default useSessionTimer;
