import { Trans } from "react-i18next";
import { Stack } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { ExternalLink } from "../../../components/external-link";
import { translate as t } from "../../../helpers/i18n";
import { pages } from "../../../helpers/pages";
import { useIsUserAuthenticated } from "../../../hooks/use-is-user-authenticated";
import AccessibilityAdaCompliance from "./accessibility-ada-compliance";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useDashboardVersion } from "../../../hooks";
import NMLSFooter from "./nmls-footer";

const LegalDisclaimer = () => {
  const { pathname } = useLocation();
  const isUserAuthenticated = useIsUserAuthenticated();
  const { user: userState } = useSelector((state: RootState) => state);
  const { user } = userState;
  const { mixpanelPageName } = useDashboardVersion();

  const isDigitalAccountCreationFlow =
    pathname === pages.digitalAccountCreation;
  const isDigitalAccount =
    user?.disclaimer_acceptances?.DIGITAL_ACCOUNT_TERMS_AND_CONDITIONS?.version;
  const showDigitalAccountDisclaimers =
    isDigitalAccountCreationFlow || isDigitalAccount;

  const disclosure = showDigitalAccountDisclaimers
    ? "digital_account"
    : isUserAuthenticated
      ? "authenticated"
      : "unauthenticated";

  useEffect(() => {
    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      disclosure,
      module: "footer",
    });
  }, [disclosure]);

  const handleLinkClick = (button: string) => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      disclosure,
      button,
      module: "footer",
      page: mixpanelPageName,
    });
  };

  return (
    <Stack gap={3}>
      <div className="mb-0 typeface-body-small">
        <Trans
          i18nKey={`layout.footer.legal_disclaimer_cobranded.${disclosure}.content`}
          values={{
            lakeviewTel: t(
              "layout.footer.legal_disclaimer_cobranded.lakeviewTel"
            ),
            nmlsLink: t("layout.footer.legal_disclaimer_cobranded.nmlsHref"),
          }}
          components={{
            lakeviewLink: (
              <ExternalLink
                module="legal-disclaimer"
                button="lakeview"
                href={t(
                  "layout.footer.legal_disclaimer_cobranded.lakeviewHref"
                )}
                targetBlank
              />
            ),
            nmlsLinkTag: (
              <ExternalLink
                className="text-break"
                module="legal-disclaimer"
                button="nmls"
                href={t("layout.footer.legal_disclaimer_cobranded.nmlsHref")}
                targetBlank
              />
            ),
            llsLink: (
              <Link
                to={pages.index}
                onClick={() => handleLinkClick("lls-index")}
              />
            ),
            termsLink: (
              <Link
                to={pages.termsConditions}
                onClick={() => handleLinkClick("terms-and-conditions")}
              />
            ),
          }}
          shouldUnescape
        />
      </div>
      <div className="border-top tw-border-tan-300 pt-3 pt-md-4 d-flex flex-column flex-md-row justify-content-md-between">
        <AccessibilityAdaCompliance />
        <NMLSFooter />
      </div>
    </Stack>
  );
};

export default LegalDisclaimer;
