import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";

interface ContentBlockOverlayProps {
  children: ReactNode;
  className?: string;
}
const ContentBlockOverlay = ({
  children,
  className,
}: ContentBlockOverlayProps) => {
  const { isContentBlockOverlay } = useSelector(
    (state: RootState) => state.app
  );

  return (
    <div
      className={`position-relative ${className} ${
        isContentBlockOverlay ? "pe-none user-select-none" : ""
      }`}
    >
      {/* Overlay */}
      {isContentBlockOverlay && (
        <div className="content-block-overlay bg-typography-surface-disabled w-100 h-100 position-absolute z-3" />
      )}

      {/* Blocked content */}
      {children}
    </div>
  );
};

export default ContentBlockOverlay;
