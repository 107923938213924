import LPbF from "../../atoms/lpbf";

const TermsOfUse = () => (
  <>
    <p className="mb-s24">
      <b>
        <LPbF /> Digital Account Terms of Use
      </b>
    </p>
    <p className="mb-s24">
      <b>
        What is a <LPbF /> Digital Account?
      </b>
    </p>
    <p className="mb-s24">
      The <LPbF /> Digital Account allows users to create a secure, digital
      account to manage your digital applications and digital experience.
    </p>
    <p className="mb-s24">
      <b>Our Services</b>
    </p>
    <p className="mb-s24">
      These <LPbF /> Digital Account Terms of Use (“Terms of Use”) govern the
      relationship between you, on the one hand, and Lakeview Loan Servicing,
      LLC (“Lakeview”), and its affiliates, subsidiaries, and service providers
      (collectively, “we,” “us,” and “our”), which includes Fastlane Technology
      Solutions, LLC (“Fastlane”), and their affiliates, subsidiaries, and
      service providers, on the other hand, with respect of your use of the{" "}
      <LPbF /> Digital Account (the “Digital Account”), associated Lakeview
      website (the “Website”), and any Products or Services as defined below or
      in any other applicable Terms of Use. Fastlane is the technology service
      provider for the <LPbF /> Digital Account experience for Lakeview.
    </p>
    <p className="mb-s24">
      Please carefully read these Terms of Use. For users of the Lakeview
      website (“Website”) and <LPbF /> Dashboard, please refer to those
      applicable Terms of Use as found here:{" "}
      <a
        href="https://lakeviewloanservicing.fastlanefi.com/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
      >
        https://lakeviewloanservicing.fastlanefi.com/terms-and-conditions
      </a>
      .
    </p>
    <p className="mb-s24">
      By accessing or using the <LPbF /> Digital Account, Website, or Products
      or Services, you are considered to have accepted and agree to be bound to
      these Terms of Use. It is important to understand that these Terms of Use
      are a legally binding agreement between you and us that will be
      enforceable against you. If you do not agree with or otherwise do not wish
      to accept these Terms of Use, do not complete the Digital Account creation
      process, access, or use the <LPbF /> Digital Account, or Website. Lakeview
      at{" "}
      <a href="https://lakeview.com/" target="_blank" rel="noreferrer">
        https://lakeview.com/
      </a>{" "}
      provides additional information on other ways to access Lakeview Products
      and Services.
    </p>
    <p className="mb-s24">
      The effective date of these Terms of Use is September 2024.
    </p>
    <p className="mb-s24">
      <b>The Products or Services.</b> Once you have established your Digital
      Account, you will be able to use it to access a growing range of digital
      services provided by Lakeview and its providers. Your access to, creation
      and use of the Digital Account is subject to these Terms of Use,
      including, as applicable, the Lakeview website Terms of Use (available at:{" "}
      <a
        href="https://lakeviewloanservicing.fastlanefi.com/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
      >
        https://lakeviewloanservicing.fastlanefi.com/terms-and-conditions
      </a>
      ), which are expressly incorporated into these Terms. For the avoidance of
      doubt, the Digital Account is considered a “Service” under the preceding
      Terms of Use. By agreeing to these Terms of Use, you acknowledge that you
      have read and agreed to any applicable Terms of Use referenced above.
    </p>
    <p className="mb-s24">
      Subject to availability, Products or Services available through the
      Digital Account may include, but are not limited to:
    </p>
    <ul className="mb-s24">
      <li>
        Registering and maintaining a <LPbF /> Digital Account with login
        credentials;
      </li>
      <li>
        Access to a Digital Account portal homepage with Single Sign-On (“SSO”)
        functionality that allows you to, for example:
      </li>
      <ul>
        <li>
          Pre-qualify and/or start a Home Equity Loan (“HELOAN”) application;
        </li>
        <li>
          Begin and receive a loan quote on various loan products offered by
          Lakeview; or
        </li>
        <li>
          The option to upgrade to a <LPbF /> Dashboard and access additional
          Products and Services, as made available by Lakeview that may redirect
          you to another website that is not owned or managed by us;
        </li>
      </ul>
      <li>
        Accessing information about your other Lakeview mortgage loan(s), as
        applicable, and;
      </li>
      <li>
        Receiving information about other Products and Services that we think
        may be of interest to you, that are offered by either us or third
        parties, including but not limited to credit products, consumer loan
        products, insurance products, rental management services, and real
        estate brokerage services.
      </li>
    </ul>
    <p className="mb-s24">
      <b>Single Sign-On (“SSO”)</b>. Single Sign-On (“SSO”) is user
      authentication and an access control mechanism that allows users, like
      you, to access multiple products or services with just one set of login
      credentials, such as username and password.
    </p>
    <p className="mb-s24">
      <b>Referral Fees and Other Compensation.</b> Lakeview may receive referral
      fees or other compensation for Products or Services offered by our
      affiliates or third parties through the <LPbF /> Digital Account, Website,
      or Products or Services, except as otherwise prohibited by applicable law
      or regulations. You agree that any marketing or promotion of a Product or
      Service through the <LPbF /> Digital Account, Website, or Products or
      Services is not a recommendation, is not independent advice, and that you
      are solely responsible for researching such Product or Service, including
      how its terms meet or do not meet your particular facts and circumstances
      and the terms of any competitor products and services.
    </p>
    <p className="mb-s24">
      <b>Prequalification Not Guaranteed.</b> Through the <LPbF /> Digital
      Account, Website, and/or Products or Services, it may be communicated to
      you that you are “prequalified,” “preapproved,” “eligible,” or otherwise
      suggested that you are approved, eligible or likely approved for a Product
      or Service. These statements are provided for informational purposes only
      and are not promises of your ability to obtain Products or Services.
      Approval is not guaranteed. An application for such Products or Services
      may be required and approval is subject to the issuer or provider of the
      Product or Service’s additional underwriting, terms, conditions, or other
      criteria.
    </p>
    <p className="mb-s24">
      <b>Credit Decisions.</b> For final approval, you must submit a fully
      completed credit application in connection with your credit request. You
      do not need to disclose alimony, child support, or separate maintenance
      income if you do not wish to have it considered as a basis for repayment.
      No later than thirty (30) days after your fully completed application is
      received, we will let you know whether your credit application is approved
      or denied, or if we are making you a counteroffer.
    </p>
    <p className="mb-s24">
      <b>Information Verification.</b> You agree and acknowledge that we may
      verify any information you submit either by asking for true and complete
      copies of necessary documentation, by information provided through a third
      party, or by other proof acceptable to us. Additionally, by proceeding
      with the application, you consent to our use of any information provided
      by you or provided through any third party, for any lawful purpose,
      including but not limited to identity verification, fraud prevention and
      credit underwriting. Failure to provide information timely can result in
      any credit application(s) being incomplete and closed by us. You
      understand that if we are unable to verify your bank account or any other
      information related to your credit request for any reason, we may cancel
      your request in accordance with our cancellation rights, as set out in the
      applicable Terms of Use “Cancellation of Credit Requests” section.
    </p>
    <p className="mb-s24">
      <b>Not Professional or Legal Advice.</b> We are not financial, tax, real
      estate, or legal advisors. The <LPbF /> Digital Account, Website, and/or
      Products or Services are not a replacement for personal, professional
      advice or assistance regarding your finances, taxes, real estate, or legal
      matters. You agree that you are responsible for consulting with a
      financial, tax, real estate or legal professional and you will not rely on
      the <LPbF /> Digital Account, Website, and/or Products or Services as the
      basis for making any financial, legal, or economical decisions.
    </p>
    <p className="mb-s24">
      <b>USA PATRIOT Act Notice.</b>
    </p>
    <p className="mb-s24">
      <b>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT.</b>
    </p>
    <p className="mb-s24">
      To help the government fight the funding of terrorism and money laundering
      activities, Federal law requires all financial institutions to obtain,
      verify, and record information that identifies each person who opens an
      account.
    </p>
    <p className="mb-s24">
      What this means for you: When you open an account, we will ask for your
      name, address, date of birth, and other information that will allow us to
      identify you. We may also ask to see your driver’s license or other
      identifying documents.
    </p>
    <p className="mb-s24">
      <b>No Securities Solicitation.</b> The information contained on the{" "}
      <LPbF /> Digital Account or Website, or through the Products or Services
      is not a solicitation or offer to sell securities, nor should it be used
      by others in connection with any sale, offer for sale or solicitation of
      an offer to purchase securities.
    </p>
    <p className="mb-s24">
      <b>Modification or Termination of Products or Services.</b> The <LPbF />{" "}
      Digital Account, Website, or Products or Services may be modified or
      terminated, in whole or in part, at any time without prior notice to you.
    </p>
    <p className="mb-s24">
      <b>Termination and Throttling.</b> We may terminate, suspend, or throttle
      your access to the <LPbF /> Digital Account, Website, or Products or
      Services, in whole or in part, and with or without notice for any reason,
      in our sole discretion.
    </p>
    <p className="mb-s24">
      Termination, suspension, or throttling pursuant to this section will not
      affect our ability to pursue any other rights or remedies under these
      Terms of Use or applicable law. In our sole discretion, we may, but are
      not required to, notify you of the breach or reason giving rise to the
      right to terminate, suspend, or throttle and request that you rectify the
      breach or reason within the period specified in the notice.
    </p>
    <p className="mb-s24">
      <b>
        Your Use of the <LPbF /> Digital Account and Website
      </b>
    </p>
    <p className="mb-s24">
      <b>
        Access to <LPbF /> Digital Account.
      </b>
      Lakeview Loan Servicing reserves the right at all times, in its sole
      discretion, and without notice to you, to deny your access to and use of
      this <LPbF /> Digital Account.
    </p>
    <p className="mb-s24">
      <b>
        Use of <LPbF /> Digital Account.
      </b>{" "}
      You agree and acknowledge that you have the sole responsibility for your
      use of this <LPbF /> Digital Account and for providing or obtaining, and
      for maintaining, all of the hardware, software, electrical power,
      telecommunications, Internet services, and other products or services
      necessary or desirable for you to access and use this <LPbF /> Digital
      Account.
    </p>
    <p className="mb-s24">
      <b>
        {" "}
        <LPbF /> Digital Account Registration.
      </b>{" "}
      To be eligible to use Lakeview’s Website and/or create a Digital Account,
      you must be a U.S. resident and be at least 18 years of age. To create a
      Digital Account, you may be required to provide us with certain contact,
      identifying, and other information. We may also request additional
      information from you at any time.
    </p>
    <p className="mb-s24">
      <b>Transaction Authorizations.</b> If you conduct transactions or conduct
      account activity through the Products or Services, you authorize us and
      any third-party to act on any instructions received via the <LPbF />{" "}
      Digital Account, Website, or otherwise through your <LPbF /> Digital
      Account. You are responsible for the transactions or account conduct of
      anyone you let use or have access to your account even if you did not
      authorize the transaction or conduct.
    </p>
    <p className="mb-s24">
      If you believe that an unauthorized activity has taken place on or from
      your Digital Account, contact us immediately. Contacting us right away
      will help reduce possible fraud and/or losses. You can contact our support
      team at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      <b>
        Updating <LPbF /> Digital Account Information.
      </b>{" "}
      You must promptly update your account details if any of your contact or
      profile details change. In the event that you need assistance with your{" "}
      <LPbF /> Digital Account, please contact us via email at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      <b>Your Conduct.</b> You agree that you will comply and be bound by these
      Terms of Use as they appear on <LPbF /> Digital Account each time you
      access and use this account. You agree not to:
    </p>
    <ul className="mb-s24">
      <li>
        Submit, transmit, or post any Submission (defined below), materials, or
        emails through the <LPbF /> Digital Account, Website, or otherwise to us
        or others that:
        <ul>
          <li>
            (i) are inaccurate, offensive, obscene, indecent, objectionable,
            threating, harassing, abusive, or defamatory;
          </li>
          <li>
            (ii) contain software viruses or any other computer code, files, or
            programs designed to interrupt, destroy, disrupt, or limit the
            operation or functionality of the <LPbF /> Digital Account, Website,
            or any of our systems or any server connected to <LPbF /> Digital
            Account, and/or Website;
          </li>
          <li>
            (iii) that may infringe, or does infringe, on our intellectual
            property or the intellectual property of others; or
          </li>
          <li>
            (iv) that otherwise violate any applicable laws or regulations.
          </li>
        </ul>
      </li>
    </ul>

    <p className="mb-s24">
      We shall not be subject to any obligations of confidentiality regarding
      any information or materials that you submit online except as specified in
      these Terms of Use, or as set forth in any additional terms and conditions
      relating to specific products or services, or as otherwise specifically
      agreed or required by law.
    </p>
    <p className="mb-s24">
      <b>Representations and Warranties.</b> By using the <LPbF /> Digital
      Account, Website, or Products or Services, you agree that you will not use
      the <LPbF /> Digital Account for any purpose other than conducting
      mortgage banking related business with us as a bona fide client of
      Lakeview Loan Servicing. You represent and warrant:
    </p>
    <ul className="mb-s24">
      <li>You are a U.S. resident, and you are at least 18 years of age;</li>
      <li>
        You agree to and will abide by all of the terms and conditions of these
        Terms of Use, and you agree that these Terms of Use are a legally
        binding written contract;
      </li>
      <li>
        All information you provide in connection with the Digital Account,
        Website, and/or Products or Services will be truthful and accurate;
      </li>
      <li>You will not, in connecting with your Digital Account:</li>
      <ul>
        <li>
          Make any false, misleading, or deceptive statements or omissions of
          fact, including but not limited to the purpose of Digital Account
          creation and/or request for any Products or Services, and for the
          existence of any other outstanding request of credit you may have;
        </li>
        <li>
          Misrepresent your identity, or describe, present, or portray yourself
          as a person other than yourself;
        </li>
        <li>
          Give to or receive from (or offer or agree to give to or receive from)
          us or another person, a fee, bonus, additional interest, kickback, or
          thing of value of any kind except in accordance with the terms of your
          Digital Account;
        </li>
        <li>
          Represent yourself to any person as a representative, employee, or
          agent of ours, or purport to speak to any person on our behalf;
        </li>
      </ul>
      <li>You will provide any information we reasonably request from you;</li>
      <li>
        You acknowledge and agree that we may rely without independent
        verification on the accuracy and completeness of all information you
        provide to us and all representations you make to us;
      </li>
      <li>
        You will not access the <LPbF /> Digital Account, Website, or Products
        or Services from a country that is subject to sanctions issued by the
        government of the United States;
      </li>
      <li>
        You are not included on any list of Specially Designated Nationals
        (“SDNs”), blocked, prohibited, or restricted persons by the government
        of the United States;
      </li>
      <li>
        You will not attempt to disrupt the normal operation of the <LPbF />{" "}
        Digital Account, Website, and/or Products or Services, or any
        infrastructure operated by us or any of our other business activities;
      </li>
      <li>
        You will only use the <LPbF /> Digital Account, Website, and/or Products
        or Services for your own personal, non-commercial use and not on behalf
        of or for the benefit of any third party;
      </li>
      <li>
        You will not interfere or induct a breach of the contractual
        relationships between Lakeview Loan Servicing, LLC and its employees;
      </li>
      <li>
        You will not use the <LPbF /> Digital Account, Website, and/or Products
        or Services, or any part thereof, to build or otherwise act in
        furtherance of any software, product, service, technology, or offering
        that is competitive, in any respect, with the Products or Services or
        any products or services available through the <LPbF /> Digital Account,
        or Website;
      </li>
      <li>
        You will not harvest email addresses from or transmit unsolicited email
        to or from this Digital Account, Website, and/or Products or Services;
      </li>
      <li>
        You will not engage in the practices of “screen scraping,” “database
        scraping” or any other activity with the purpose of obtaining content or
        other information;
      </li>
      <li>
        You will not attempt to gain unauthorized access to the <LPbF /> Digital
        Account, Website, and/or the Products or Services or otherwise
        jeopardize the security of the <LPbF /> Digital Account, Website, or the
        Products or Services, your account, the account of any other user, any
        computer network, or any security encryption code;
      </li>
      <li>You will not post or submit any Submission (defined below) that:</li>
      <ul>
        <li>
          (i) is inaccurate, offensive, obscene, indecent, objectionable,
          threating, harassing, abusive, or defamatory;
        </li>
        <li>
          (ii) contains sensitive personal information, such as Social Security
          Numbers, credit card numbers, or financial accounts numbers;
        </li>
        <li>
          (iii) promotes products, services, or forums of third parties; or
        </li>
        <li>
          (iv) that may infringe, or does infringe, on our intellectual property
          or the intellectual property of others;
        </li>
      </ul>
      <li>
        You will not suggest an express or implied affiliation with us or a
        broker relationship with us (without the express permission of Lakeview
        Loan Servicing, LLC);
      </li>
      <li>
        You will not unlawfully impersonate or otherwise misrepresent your
        affiliation with any person or entity;
      </li>
      <li>
        You will not dilute or depreciate the name and reputation of us; and
      </li>
      <li>
        You will not use the <LPbF /> Digital Account, Website, or the Products
        Services in connection with the actual or attempted contravention of any
        applicable laws or regulations.
      </li>
    </ul>
    <p className="mb-s24">
      <b>Account Password.</b> You are responsible for protecting the
      confidentiality of your <LPbF /> Digital Account password and your
      personal information. If you suspect that there has been unauthorized
      access to your <LPbF /> Digital Account or the security of your <LPbF />{" "}
      Digital Account has been compromised, you must notify Lakeview immediately
      and change your <LPbF /> Digital Account password. You also acknowledge
      and agree that you are solely responsible for all damages or claims that
      may arise from any access to or use of this <LPbF /> Digital Account by
      any person to whom you have provided your user identification, password or
      other identifying information, or by any person who has obtained such
      information from you, including, but not limited to, any access to or use
      of this <LPbF /> Digital Account that may occur after you have notified us
      that your user identification, password or other identifying information
      has been lost, stolen or otherwise compromised. In the event that you need
      assistance with your account password, please contact us via email at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      <b>Digital Account Updates.</b> We may from time to time in our sole
      discretion develop and provide <LPbF /> Digital Account updates, which may
      include upgrades, bug fixes, patches, other error corrections, and new
      features (“Updates”). Updates may also modify or delete in their entirety
      certain features and functionality. We do not publish software that
      requires you to download or install any software. You agree that We have
      no obligation to provide any Updates or to continue to provide or enable
      any particular features or functionality.
    </p>
    <p className="mb-s24">
      <b>Information Sharing</b>
    </p>
    <p className="mb-s24">
      <b>Consent to Information Sharing.</b> You authorize Us to share any
      information amongst Ourselves about you, including any products or
      services you have with any one of Us or that We service or facilitate.
      This includes but is not limited to Lakeview’s sharing information about
      your mortgage, pre-qualifications, loan applications, contact information,
      and personal details. You also authorize Us to share any information we
      have about you with third-party originating banks and other third parties
      that offer Products and Services through our platform and authorize such
      parties to share with Us any information about you, including with respect
      to any Product or Service offered through the platform or any other
      product or service.
    </p>
    <p className="mb-s24">
      <b>Withdrawing Consent.</b> In order to withdraw your consent, you must
      terminate your <LPbF /> Digital Account. You may do so by contacting us at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      . To withdraw your consent of any other Products or Services, including
      website, and/or other digital experiences, please refer to those
      applicable Terms of Use. Please note that withdrawing your consent will
      not affect any other consent you may have provided to Us.
    </p>
    <p className="mb-s24">
      <b>Privacy Policy.</b> Our Privacy Policy and Privacy Notice, available
      below, are incorporated herein by reference. Please read our Privacy
      Policy and Privacy Notice carefully. You acknowledge, agree and consent to
      the terms of our Privacy Policy and Notice by your use of the <LPbF />{" "}
      Digital Account, Website, and/or Products or Services. Additional Privacy
      information is available on{" "}
      <a
        href="https://lakeview.com/privacy-policy/"
        target="_blank"
        rel="noreferrer"
      >
        https://lakeview.com/privacy-policy/
      </a>
      .
    </p>
    <p className="mb-s24">
      <b>Your California Privacy Rights.</b> California Civil Code § 1798.83
      permits our visitors who are California residents to request certain
      information regarding their disclosure of personal information to third
      parties for their direct marketing purposes. To make such a request,
      please write Us:
    </p>
    <p>Lakeview Loan Servicing, LLC</p>
    <p>Attn: Compliance Department</p>
    <p>4425 Ponce De Leon Blvd., MS. 5-251</p>
    <p className="mb-s24">Coral Gables, FL 33146</p>
    <p className="mb-s24">
      <b>Intellectual Property</b>
    </p>
    <p className="mb-s24">
      <b>Limited License.</b> We grant you a limited, non-exclusive, revocable,
      non-transferable, and non-assignable license to use the <LPbF /> Digital
      Account, Website, and/or Products or Services, including to download,
      install, and use the <LPbF /> Digital Account or any available through the{" "}
      <LPbF /> Digital Account or Website for your own use on a mobile device or
      computer owned or otherwise controlled by you strictly in accordance with
      these Terms of Use and any other agreement you have with us. You
      acknowledge and agree that <LPbF /> Digital Account, Website, or any
      application available through the <LPbF /> Digital Account or Website is
      provided under license, and not sold, to you. You do not acquire any
      ownership interest in the <LPbF /> Digital Account, Website, or any
      application available through the <LPbF /> Digital Account or Website
      under these Terms of Use, or any other rights other than to use the{" "}
      <LPbF /> Digital Account, Website, or any available through the <LPbF />{" "}
      Digital Account or Website in accordance with the license granted, and
      subject to all terms, conditions, and restrictions, under these Terms of
      Use.
    </p>
    <p className="mb-s24">
      <b>Ownership.</b> We own and retain all rights, title, and interest in and
      to <LPbF /> Digital Account, Website and their features, functionality and
      entire contents, including without limitation, text, data, articles,
      design, source code, software, photos, images, graphics, user interfaces,
      trademarks, logos, sound, video, general “look and feel,” and other
      information as well as any part thereof or any updates and the design,
      structure, selection, coordination, expression and arrangement of any of
      the foregoing, and any and all present and future intellectual and
      industrial property rights anywhere in the world including copyright,
      know-how, designs, trade secrets, patents and trademarks, and any
      application or right to apply for registration of those rights
      (“Intellectual Property Rights”) therein and thereto (collectively, the
      “Content”). You acknowledge that the <LPbF /> Digital Account, Website,
      Product or Services, and Content are protected by United States and
      international trademark, copyright, patent, trade secret and other
      intellectual property or proprietary rights laws. You also acknowledge
      that Lakeview controls and operates the Digital Account, Website, and/or
      Product or Services from within the United States of America and that
      these are only offering information regarding products and services that
      are made available in the United States. Lakeview makes no representation
      that the products or services referenced in the Digital Account and/or
      Website will be available: (a) anywhere outside of the United States, or
      (b) in every state within the United States.
    </p>
    <p className="mb-s24">
      You will not at any time, including after any termination or expiration of
      these Terms of use, undertake or permit a third party to undertake any act
      which infringes, misappropriate, or attempts to infringe or misappropriate
      those Intellectual Property Rights and, without limiting the generality of
      the foregoing, you specifically acknowledge that you must not copy the{" "}
      <LPbF /> Digital Account, Website, or Content, in whole or in part, except
      as otherwise expressly authorized in these Terms of Use. Except as
      explicitly provided herein, nothing in these Terms of Use shall be
      construed as conferring any transfer, conveyance, or license to any
      Intellectual Property Rights of us or our licensors, whether by estoppel,
      implication or otherwise. All rights not expressly granted in these Terms
      of use are reserved by us.
    </p>
    <p className="mb-s24">
      <b>Limited Rights.</b> You are only authorized to view, use, copy for your
      records and download small portions of the Content on the <LPbF /> Digital
      Account, Website, Product or Services, or output thereof for your
      informational, non-commercial, individual use, provided that you leave all
      copyright notices and any other proprietary notices intact.
    </p>
    <p className="mb-s24">
      <b>Restrictions.</b> Your access to and use of the <LPbF /> Digital
      Account, Website and/or Product or Services are subject to the following
      restrictions:
    </p>
    <ul className="mb-s24">
      <li>
        Other than as expressly provided in these Terms of Use or otherwise
        permitted by law, you must not use, copy, display, download, store,
        reproduce, republish, publicly display, distribute, post, transmit,
        decompile, reverse-engineer, disassemble, attempt to derive the source
        code of, modify, or create derivative works based on the <LPbF />{" "}
        Digital Account, Website, Service, or the Content, or any part thereof,
        without the advance written permission of Lakeview.
      </li>
      <li>
        You may not access, retrieve any data from, or otherwise perform any
        other activities on or through the <LPbF /> Digital Account, Website or
        Service using any type of software or other automated process or
        artificial intelligence (including without limitation scripts, robots,
        scrapers, crawlers, or spiders).
      </li>
      <li>
        The commercial use, reproduction, transmission, or distribution of any
        Content, information, software, or other material available through the{" "}
        <LPbF /> Digital Account, Website or without the prior written consent
        of Lakeview is strictly prohibited.
      </li>
      <li>
        Any use of the <LPbF /> Digital Account, Website, or Content other than
        as specifically authorized herein, without the prior written permission
        of Lakeview, is strictly prohibited, and any such use will immediately
        terminate all rights granted to you herein.
      </li>
      <li>
        The limited rights made available by us to you are revocable by us at
        any time without notice and with or without cause.
      </li>
    </ul>
    <p className="mb-s24">
      <b>Trademarks.</b> All product names, our company names, our logos and all
      related names, logos, product, and service names, whether or not appearing
      in large print or with the trademark symbol (the “Marks”), are our
      trademarks or those of our licensors, unless otherwise noted. The use or
      misuse of the Marks, except as expressly permitted herein, is expressly
      prohibited and may be in violation of trademark law, copyright law and
      other proprietary and intellectual property rights. You must not use such
      Marks without the prior written permission of Lakeview. All other names,
      logos, product and service names, designs and slogans on the <LPbF />{" "}
      Digital Account, Website and/or Products or Services are the trademarks of
      their respective owners.
    </p>
    <p className="mb-s24">
      <b>Your Content.</b> Any comments, questions, suggestions, reviews, ideas,
      concepts, know-how, techniques, discussions, photos, video, images, data,
      or the like (“Submissions”) in any communications or other material that
      you send us through the internet, post on the <LPbF /> Digital Account or
      Website, post on any other website provided by us, provide through the,
      send to us by electronic mail, or otherwise make available to us will be
      deemed non-confidential and we shall have no obligation of any kind with
      respect to such Submissions.
    </p>
    <p className="mb-s24">
      By contributing Submissions, you represent and warrant that you own all
      intellectual property in and to those Submissions. You must not upload or
      contribute any Submissions not either originally created by you or
      properly licensed to you by someone else for uploading or contributing.
    </p>
    <p className="mb-s24">
      We will be free to use any Submissions for any purpose whatsoever,
      including but not limited to, developing and marketing products and
      services. You grant us and other users of our Products or Services a
      non-exclusive, unrestricted, perpetual, irrevocable, transferable,
      assignable, sub-licensable, royalty-free license to publish, host, store,
      transfer, distribute, modify, create derivative and collective works from,
      reproduce, display, perform, transmit, process, or otherwise use, in any
      manner and for any purpose, and in all forms or distribution methods now
      known or later developed, your Submissions, in whole or in part, without
      any obligation to you. This license includes the right to use your name,
      persona, username, and likeness without compensating you. Nothing
      contained herein shall be construed as limiting our responsibilities and
      obligations under our Privacy Policy and Privacy Notice.
    </p>
    <p className="mb-s24">
      We may, but are not obligated to, review, monitor, edit, control,
      distribute, refuse to distribute, block access, re-format, alter, delete,
      or remove Submissions from our <LPbF /> Digital Account, Website and/or
      Products or Services for any reason. We also reserve the right to block or
      otherwise prohibit any individual from the ability to provide or post
      Submissions for any reason.
    </p>
    <p className="mb-s24">
      <b>Feedback.</b> You grant us a royalty-free, worldwide, irrevocable,
      perpetual license to use and incorporate into the <LPbF /> Digital
      Account, Website, and/or Products or Services any suggestions, enhancement
      requests, recommendations or other feedback provided by you.
    </p>
    <p className="mb-s24">
      <b>Content from Other Users.</b> We do not endorse, represent, or
      guarantee the completeness, accuracy, reliability, or usefulness of any
      Submissions on the <LPbF /> Digital Account, Website and/or Products or
      Services. You may see Submissions that may be inaccurate, offensive,
      indecent, or objectionable.
    </p>
    <p className="mb-s24">
      <b>U.S. Government Rights.</b> The <LPbF /> Digital Account and any
      Products or Services accessed through the Website are commercial computer
      software, as such term is defined in 48 C.F.R. §2.101. Accordingly, if you
      are an agency or employee of the U.S. Government or any contractor
      therefore, you receive only those rights with respect to the <LPbF />{" "}
      Digital Account as are granted to all other end users under license, in
      accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with
      respect to the Department of Defense and their contractors, or (b) 48
      C.F.R. §12.212, with respect to all other U.S. Government licensees and
      their contractors.
    </p>
    <p className="mb-s24">
      <b>Export Controls.</b> The <LPbF /> Digital Account may be subject to
      United States export control laws, including the Export Control Reform Act
      and its associated regulations. You shall not, directly, or indirectly,
      export, re-export, or release the <LPbF /> Digital Account to, or make the{" "}
      <LPbF /> Digital Account accessible from, any jurisdiction or country
      other than the United States.
    </p>
    <p className="mb-s24">
      <b>Copyright Complaints.</b> If you believe that any material on the{" "}
      <LPbF /> e Digital Account, Website, and/or Products or Services infringes
      upon any copyright which you own or control, you may send a written
      notification of such infringement to{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      To meet the notice requirements under the Digital Millennium Copyright
      Act, the notification must be a written communication that includes the
      following:
    </p>
    <ol className="mb-s24">
      <li>
        A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
      </li>
      <li>
        Identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works at a single online site are covered by
        a single notification, a representative list of such works at that site;
      </li>
      <li>
        Identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and that is to be removed or access
        to which is to be disabled, and information reasonably sufficient to
        permit us to locate the material;
      </li>
      <li>
        Information reasonably sufficient to permit us to contact the
        complaining party, such as an address, telephone number and, if
        available, an electronic mail address at which the complaining party may
        be contacted;
      </li>
      <li>
        A statement that the complaining party has a good-faith belief that use
        of the material in the manner complained of is not authorized by the
        copyright owner, its agent or the law; and
      </li>
      <li>
        A statement that the information in the notification is accurate, and
        under penalty of perjury, that the complaining party is authorized to
        act on behalf of the owner of an exclusive right that is allegedly
        infringed.
      </li>
    </ol>
    <p className="mb-s24">
      <b>Liability and Disclaimers</b>
    </p>
    <p className="mb-s24">
      <b>No Warranties.</b> THE <LPbF /> DIGITAL ACCOUNT, WEBSITE, PRODUCTS OR
      SERVICES, AND ALL OF THEIR CONTENT ARE PROVIDED “AS IS” WITHOUT WARRANTY
      OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE
      IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
      TITLE, OR NON-INFRINGEMENT. We, our parent companies, affiliates,
      subsidiaries, business partners, providers or their respective officers,
      directors, employees, agents, partners, independent contractors, or
      licensors (the “Lakeview Parties”) assume no responsibility for
      consequences from the use of the information herein, or in any respect for
      the content of such information, including, but not limited to, delays,
      errors or omissions, the accuracy or reasonableness of information, the
      defamatory nature of statements, ownership of copyright or other
      intellectual property rights, and the violation of property, privacy or
      personal rights of others. WE ARE NOT RESPONSIBLE FOR AND EXPRESSLY
      DISCLAIM ALL LIABILITY FOR, DAMAGES OF ANY KIND ARISING OUT OF USE,
      REFERENCE, OR RELIANCE ON SUCH INFORMATION. Additionally, there are no
      warranties as to the results of your use of the Content. The Lakeview
      Parties do not warrant that the <LPbF /> Digital Account, Website and/or
      Products or Services are free of viruses, malware, or other harmful
      components. This does not affect those warranties which are incapable of
      exclusion, restriction, or modification under the laws applicable to these
      Terms of Use.
    </p>
    <p className="mb-s24">
      <b>Limitation of Liability.</b> TO THE EXTENT PERMITTED BY APPLICABLE LAW
      OR REGULATIONS, IN NO EVENT WILL THE LAKEVIEW PARTIES BE LIABLE TO YOU OR
      ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
      SPECIAL OR PUNITIVE DAMAGES, ANY LOST PROFITS, OR ANY LOST DATA ARISING
      FROM YOUR USE OF THE <LPbF /> DIGITAL ACCOUNT, WEBSITE, PRODUCTS OR
      SERVICES, OR SOFTWARE, EVEN IF THE LAKEVIEW PARTIES ARE AWARE OR HAVE BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    </p>
    <p className="mb-s24">
      To the extent permitted by applicable law and regulations, and without
      limitation, the Lakeview Parties are not liable for any claims or losses
      arising directly or indirectly from:
    </p>
    <ul className="mb-s24">
      <li>
        a failure to provide the <LPbF /> Digital Account, Website, and/or
        Products or Services, or any part thereof;
      </li>
      <li>
        corruptions to or loss of data, errors or interruptions occurring in the
        course of using, or as part of, the <LPbF /> Digital Account, Website,
        and/or any Products or Services.
      </li>
      <li>
        any suspension the <LPbF /> Digital Account, Website, and/or Products or
        Services, or any part thereof; or
      </li>
      <li>
        any use of the <LPbF /> Digital Account, Website, or by other users,
        including any use of the <LPbF /> Digital Account, Website, and/or
        Products or Services by other users in manner which contravenes these
        Terms of Use.
      </li>
    </ul>
    <p className="mb-s24">
      <b>Indemnification.</b> You agree to indemnify and hold the Lakeview
      Parties harmless from and against any loss, liability, claim, demand,
      damages, costs and expenses, including reasonable attorney’s fees, arising
      out of or in connection with your use of the <LPbF /> Digital Account,
      Website, and/or Products or Services (including any Submissions), any
      violation of these Terms of Use, any violation of law, or violation of the
      rights of any third party.
    </p>
    <p className="mb-s24">
      <b>Online Security is Limited.</b> Unfortunately, no data transmission
      over the internet can be guaranteed as totally secure. While we strive to
      protect such information and keep your information confidential, we do not
      warrant and cannot ensure the security of any information transmitted to
      it by you. Accordingly, any information transmitted to us via the internet
      or email is transmitted at the risk of the sender.
    </p>
    <p className="mb-s24">
      Users must take their own precautions to ensure that the process they
      employ for accessing the <LPbF /> Digital Account, Website, and/or
      Products or Services does not expose them to the risk of viruses,
      malicious computer code or other forms of interference which may damage
      their computer or mobile device. We are not responsible for any viruses,
      malicious computer code or other forms of interference experienced by you
      when accessing the <LPbF /> Digital Account, Website, and/or Products or
      Services.
    </p>
    <p className="mb-s24">
      <b>Third Party Services.</b> You may be required to use certain
      third-party services (in order to obtain, access, or use the <LPbF />{" "}
      Digital Account, Website, and/or Products or Services. Your use of
      third-party services may be subject to fees and separate terms and
      conditions, and you acknowledge that We are not liable for the activities
      of any such third parties. You must comply with any applicable third-party
      terms of agreement when using the <LPbF /> Digital Account, Website,
      and/or Products or Services. You are responsible for ensuring that your
      use of the <LPbF /> Digital Account, Website, and/or Products or Services
      does not cause you to exceed any data usage quotas or other limitations
      that may apply to your internet service or other services acquired from
      third parties.
    </p>
    <p className="mb-s24">
      <b>Links to Third Party Websites.</b> The <LPbF /> Digital Account,
      Website and/or Products or Services may contain links (including via
      advertisements) to third party websites or other third-party content or
      services. Those links are provided for convenience only and may not remain
      current or be maintained. You acknowledge that such links should not be
      construed as an endorsement, approval, or recommendation by us of the
      third parties, or of any content or services provided by them, and that
      your use of any third-party content or services may be subject to separate
      terms and conditions.
    </p>
    <p className="mb-s24">
      At certain places the <LPbF /> Digital Account, Website and/or Products or
      Services may contain live “links” to Internet addresses that can be
      accessed (“Linked Websites”). Such Linked Websites contain information
      created, published, maintained, or otherwise posted by independent third
      parties. We do not endorse, approve, certify, or control these Linked
      Websites and do not guarantee the accuracy, completeness, efficacy,
      timeliness, or correct sequencing of information that they contain. Use of
      Linked Websites is voluntary and should only be undertaken after an
      independent review of the accuracy, completeness, efficacy, and timeliness
      of information contained therein. In addition, it is your responsibility
      to take precautions to ensure that material selected from such Linked
      Websites is free of such items as viruses, worms, Trojan horses, and other
      items of a destructive nature. We are not responsible for, and expressly
      disclaim all liability for, damages of any kind arising out of the use of
      such Linked Websites, or reference to or reliance on information contained
      therein.
    </p>
    <p className="mb-s24">
      <b>No Liability for Submissions.</b> We are not liable for Submissions
      posted on our <LPbF /> Digital Account, Website and/or Products or
      Services and you waive any legal or equitable right or remedy you have
      against Us with respect to Submissions.
    </p>
    <p className="mb-s24">
      <b>Other Terms</b>
    </p>
    <p className="mb-s24">
      <b>Other Agreements.</b> Your use of the <LPbF /> Digital Account,
      Website, and/or Products or Services may be subject to other agreements or
      authorizations with Us, a third-party originating bank, or another third
      party that is the issuer or provider of a product or service through our
      platform. To the extent of any inconsistency between these Terms of Use
      and other agreements or authorizations with Us, a relevant third-party
      originating bank, or a relevant third-party provider, the other agreements
      or authorizations shall prevail.
    </p>
    <p className="mb-s24">
      <b>Amendments.</b> Lakeview reserves the right to amend these Terms of Use
      from time to time. We will notify you of the amendments through any
      reasonable means at our discretion, such as publication on the <LPbF />{" "}
      Digital Account or Website, notification through Products and/or Services
      (such as banner ads), or via email to you. The amendments will be
      effective immediately, unless we state otherwise in our notice to you.
      Your continued access and use of the <LPbF /> Digital Account, Website,
      and/or Products or Services constitutes your agreement to be bound by the
      updated Terms of Use.
    </p>
    <p className="mb-s24">
      <b>Survival.</b> The following provisions of these Terms of Use shall
      survive termination of your ability to access the <LPbF /> Digital
      Account, Website, and/or Products or Services: the sections concerning
      Intellectual Property, Representations and Warranties, Disclaimer of
      Warranties, Limitation of Liability, Indemnification, Waiver, Governing
      Law, Arbitration, this provision, and any other provision that by its
      terms survives termination of your use or access to the Site.
    </p>
    <p className="mb-s24">
      <b>Assignment.</b> You may not assign, novate, or otherwise transfer any
      of your rights or obligations under these Terms of Use without the prior
      written consent of Lakeview, which shall be at its sole discretion.
      Lakeview may assign, novate, or otherwise transfer any of its rights or
      obligations under these Terms of Use at its sole discretion without
      written notice to you.
    </p>
    <p className="mb-s24">
      <b>Waiver.</b> Our failure or delay in enforcing any of your obligations,
      or exercising a right or remedy, does not amount to a waiver of that
      obligation, right or remedy. Additionally, if We waive a particular
      obligation in one circumstance, it does not prevent us from subsequently
      requiring compliance with the obligation on other occasions.
    </p>
    <p className="mb-s24">
      <b>Severability.</b> Except as otherwise provided in the Arbitration
      Provision, if any part of these Terms of Use is determined to be invalid
      or unenforceable pursuant to applicable law then the invalid or
      unenforceable provisions will be deemed superseded by a valid, enforceable
      provision that most closely matches the intent of the original provision
      and the remainder of these Terms of Use shall continue in full force and
      effect.
    </p>
    <p className="mb-s24">
      <b>Governing Law.</b> By accessing or using the <LPbF /> Digital Account,
      Website, and/or Products or Services, you have directed your system to a
      computer located in the State of Florida. You agree that to the degree not
      preempted by federal law, including the FAA, the laws of the State of
      Florida will govern these Terms of Use without regard to any principles of
      conflict of laws that would require or permit the application of the laws
      of any other jurisdiction. If you receive or enter into a contract for a
      Product or Service, the terms of that contract may be governed by the laws
      of a different state, pursuant to the terms of that contract.
    </p>
    <p className="mb-s24">
      <b>Further Assurances.</b> You agree that you will, at your own expense,
      do all things and execute all further documents necessary to give full
      effect to these Terms of Use.
    </p>
    <p className="mb-s24">
      <b>Entire Agreement and Reservation of Rights.</b> These Terms of Use
      constitutes the entire agreement between you and us with respect to the{" "}
      <LPbF /> Digital Account, Website, and/or Products or Services and it
      supersedes all prior or contemporaneous communications and proposals,
      whether electronic, oral or written, between you and us with respect to
      the <LPbF /> Digital Account, Website, and/or Products or Services. Any
      rights not expressly granted herein are reserved.
    </p>
    <p className="mb-s24">
      <b>Contacting Us.</b> In the event that you need to contact Lakeview about
      these Terms of Use, please contact us via email at{" "}
      <a href="mailto:fastlanesupport@lakeview.com">
        fastlanesupport@lakeview.com
      </a>
      .
    </p>
    <p className="mb-s24">
      <b>Communications with Company.</b>
    </p>
    <p className="mb-s24">
      <b>Time Sensitive Instructions:</b> When communicating with us through the{" "}
      <LPbF /> Digital Account or via email, do not use it to communicate any
      time-sensitive instructions that are in any way related to or affect your
      loan, loan application, or closing (such as interest rate locks,
      cancellation of a closing, rescissions, or the like). Such instructions
      may not be honored. All transactions conducted on this <LPbF /> Digital
      Account or via email must be confirmed in writing by us to be accepted by
      and binding upon us.
    </p>
    <p className="mb-s24">
      <b>Loan Approvals.</b> All loan approvals, pre-qualifications, rate locks,
      deposit and refund agreements, and the like, are only made by Lakeview
      Loan Servicing, LLC in writing. Approvals and qualifications are
      conditional in accordance with their terms except as specifically provided
      for in writing signed by Lakeview Loan Servicing, LLC.
    </p>
    <p className="mb-s24">
      <b>Credit Reports.</b> When applying for credit, you are authorizing
      Lakeview Loan Servicing, LLC to obtain a copy of your credit report for a
      lawful and permissible purpose. As a result, a “hard” inquiry may appear
      on your credit report. A hard inquiry may negatively affect your credit
      score. However, your acceptance of Lakeview’s Consent to Obtain Credit
      Reports is binding upon you.
    </p>
    <p className="mb-s24">
      <b>E-Signature</b>. General communications through this <LPbF /> Digital
      Account or via email are not intended by us to constitute either an
      electronic record or an electronic signature, or to constitute any
      agreement by the sender to conduct a transaction by electronic means,
      unless a specific statement to the contrary is included in the message and
      specific e-signature procedures are employed. However, your acceptance of
      Lakeview’s Consent to Electronic records is binding upon you.
    </p>
    <p className="mb-s24">
      <b>Recording &amp; Monitoring of Communications</b>. Note that by
      communicating with us regarding your <LPbF /> Digital Account, email or
      telephone communications may be recorded or monitored. By using such
      communication methods, you are consenting to the recording or monitoring
      of the same.
    </p>
  </>
);

export default TermsOfUse;
